import { Box, BoxProps, Divider, ListItem, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DialogHeader } from '../../../theme/components/dialog-header';
import { PentairHeading } from '../../../theme/components/pentair-heading';
import { PentairBulletList } from '../../../theme/components/unordered-list';

import sterilization from './assets/sterilization.png';

export const AboutSterilization: React.FC<BoxProps> = (props) => {
  const { t } = useTranslation();

  return (
    <Box {...props}>
      <DialogHeader
        title={t('about', { defaultValue: 'About' })}
        subtitle={t('sterilization', { defaultValue: 'Sterilization' })}
      />
      <Divider sx={{ borderBottomWidth: 3 }} />
      <Box padding={2} display="flex" alignItems="flex-start">
        <Image src={sterilization} width="22%" alt="Butterfly valve" marginRight={2} />
        <Box flex={1} marginBottom={1}>
          <PentairHeading variant="body2" gutterBottom>
            {t('support:aboutSterilization.about', {
              defaultValue: 'Sterilisation or flushing, via external connections.',
            })}
          </PentairHeading>
          <PentairBulletList>
            <ListItem>
              {t('support:aboutSterilization.descriptionBullet1', {
                defaultValue: 'Sterilization or flushing of lower and upper shaft area',
              })}
            </ListItem>
            <ListItem>
              {t('support:aboutSterilization.descriptionBullet2', {
                defaultValue: 'Seat lift function',
              })}
            </ListItem>
            <ListItem>
              {t('support:aboutSterilization.descriptionBullet3', {
                defaultValue: 'External flushing port',
              })}
            </ListItem>
          </PentairBulletList>
        </Box>
      </Box>
    </Box>
  );
};

const Image = styled(Box)<BoxProps<'img'>>({});
Image.defaultProps = {
  component: 'img',
};
