import { AboutAsepticValves } from './about-aseptic-valves';
import { AboutHygienicValves } from './about-hygienic-valves';
import { ComparisonGrid } from './comparison-grid';

export const InfoHygienicAseptic = () => {
  return (
    <ComparisonGrid>
      <AboutHygienicValves />
      <AboutAsepticValves />
    </ComparisonGrid>
  );
};
