import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, CircularProgress } from '@mui/material';
import { useCallback } from 'react';
import { useAuth } from '../auth-provider';
import { useTranslation } from 'react-i18next';
import { PentairTextField } from '../../../theme/components/pentair-text-field';
import { FormOptions, useFormData } from '../../form/use-formdata';
import { requiredEmail, requiredString } from '../../form/validators';

interface FormData {
  username: string;
  password: string;
  newPassword: string;
}

const formOptions: FormOptions<FormData> = {
  validateOnSubmit: true,
  validations: {
    username: requiredEmail,
    password: requiredString,
    newPassword: requiredString,
  },
};

const initialRecord: FormData = {
  username: '',
  password: '',
  newPassword: '',
};

export const ForceResetPasswordForm = () => {
  const { t } = useTranslation();
  const { loading, cognitoUser, completeNewPasswordChallenge } = useAuth();

  formOptions.onSubmit = useCallback(
    (record: FormData) => {
      completeNewPasswordChallenge(record.newPassword);
    },
    [completeNewPasswordChallenge]
  );

  const { change, record, validationErrors, submitForm } = useFormData<FormData>(initialRecord, formOptions);

  return (
    <form onSubmit={submitForm} style={{ display: 'flex', flexFlow: 'column' }}>
      <PentairTextField
        label={t('iam:username.label', { defaultValue: 'Username' })}
        placeholder={t('iam:username.placeholder', { defaultValue: 'E-mail address' })}
        description={t('iam:username.description', {
          defaultValue: 'This is username of your account. By default this is your email address',
        })}
        id="username"
        name="username"
        value={(cognitoUser as any)?.challengeParam?.userAttributes?.email}
        type="text"
        disabled
        error={!!validationErrors['username']}
        helperText={validationErrors['username']}
      />

      <PentairTextField
        label={t('iam:currentPassword.label', { defaultValue: 'Current password' })}
        placeholder={t('iam:currentPassword.placeholder', { defaultValue: 'Enter current password' })}
        description={t('iam:currentPassword.description', {
          defaultValue: 'The password you used to login to the app',
        })}
        id="password"
        name="password"
        type="password"
        value={record.password}
        onChange={(e) => change('password', e.target.value)}
        disabled={loading}
        error={!!validationErrors['password']}
        helperText={validationErrors['password']}
      />

      <PentairTextField
        label={t('iam:newPassword.label', { defaultValue: 'New password' })}
        placeholder={t('iam:newPassword.placeholder', { defaultValue: 'Enter new password' })}
        description={t('iam:newPassword.description', {
          defaultValue: 'The new password you want to use to login to the app',
        })}
        id="new-password"
        name="new-password"
        type="password"
        value={record.newPassword}
        onChange={(e) => change('newPassword', e.target.value)}
        disabled={loading}
        error={!!validationErrors['newPassword']}
        helperText={validationErrors['newPassword']}
      />

      <Button
        type="submit"
        disabled={loading}
        sx={{ marginTop: 1, marginLeft: 'auto' }}
        endIcon={loading ? <CircularProgress size={20} /> : <FontAwesomeIcon icon={faArrowRight} />}
      >
        {t('iam:buttonLabel.updatePassword', { defaultValue: 'Update password' })}
      </Button>
    </form>
  );
};
