import { AuthTemplate } from './auth-template';
import { RegistrationForm } from './forms/registration-form';

export const AuthStateRegistering = () => {
  return (
    <AuthTemplate title="Create account">
      <RegistrationForm />
    </AuthTemplate>
  );
};
