import { uniqueId } from 'lodash';
import { useCallback, useRef, useState } from 'react';

export const createApiState = <TData>(defaultData: TData): FetchState<TData> => ({
  response: defaultData,
  loading: false,
  isCompleted: false,
  error: undefined,
});

export interface FetchState<TData> {
  response: TData;
  loading: boolean;
  isCompleted: boolean;
  error: Error | undefined;
}

type APICallback<TData = any> = (...args: any) => Promise<TData>;

export const useApiCallback = <TCallback extends APICallback>(
  promise: TCallback,
  defaultState: Awaited<ReturnType<typeof promise>>
): [
  FetchState<Awaited<ReturnType<TCallback>>>,
  TCallback,
  React.Dispatch<React.SetStateAction<FetchState<Awaited<ReturnType<TCallback>>>>>,
] => {
  const [state, setState] = useState(createApiState(defaultState));
  const ref = useRef('');

  const callback = useCallback(
    async (...args: any[]) => {
      setState((state) => ({ ...state, loading: true }));

      try {
        const callbackId = uniqueId('api-callback-');
        ref.current = callbackId;
        const response = await promise(...args);
        if (ref.current !== callbackId) throw new Error('Callback is outdated');
        setState((state) => ({ ...state, loading: false, isCompleted: true, response }));
        return response;
      } catch (err: any) {
        if (err.message === 'Callback is outdated') return err;
        if (err instanceof Error)
          setState((state) => ({ ...state, loading: false, isCompleted: true, response: err, error: err }));
        else
          setState((state) => ({
            ...state,
            loading: false,
            isCompleted: true,
            response: err,
            error: new Error(err.toString()),
          }));

        return err;
      }
    },
    [promise]
  );

  return [state, callback as TCallback, setState];
};

export const makeApiHook =
  <TCallback extends APICallback>(promise: TCallback, defaultState: Awaited<ReturnType<typeof promise>>) =>
  () => {
    return useApiCallback(promise, defaultState);
  };
