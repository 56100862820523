import { Color } from '@mui/material';

export const PentairBlue: Color = {
  50: '#e2e7ee',
  100: '#b6c2d4',
  200: '#869ab8',
  300: '#55719c',
  400: '#305286',
  500: '#0c3471',
  600: '#0a2f69',
  700: '#08275e',
  800: '#062154',
  900: '#031542',
  A100: '#7792ff',
  A200: '#4469ff',
  A400: '#1141ff',
  A700: '#0031f6',
};

export const PentairBrightBlue: Color = {
  50: '#e0f1f7',
  100: '#b3dceb',
  200: '#80c5de',
  300: '#4dadd0',
  400: '#269cc6',
  500: '#008abc',
  600: '#0082b6',
  700: '#0077ad',
  800: '#006da5',
  900: '#005a97',
  A100: '#c2e3ff',
  A200: '#8fcbff',
  A400: '#5cb3ff',
  A700: '#42a7ff',
};

export const PentairSilverGray: Color = {
  50: '#e6e8e9',
  100: '#c0c5c8',
  200: '#969ea4',
  300: '#6c777f',
  400: '#4d5a63',
  500: '#2d3d48',
  600: '#283741',
  700: '#222f38',
  800: '#1c2730',
  900: '#111a21',
  A100: '#63b7ff',
  A200: '#30a0ff',
  A400: '#0088fc',
  A700: '#007ae3',
};

export const PentairLightGray: Color = {
  50: '#fcfdfd',
  100: '#f8f9fb',
  200: '#f4f6f9',
  300: '#f0f2f6',
  400: '#eceff4',
  500: '#e9ecf2',
  600: '#e6eaf0',
  700: '#e3e7ee',
  800: '#dfe4ec',
  900: '#d9dfe8',
  A100: '#ffffff',
  A200: '#ffffff',
  A400: '#ffffff',
  A700: '#ffffff',
};

export const PentairSoftGray: Color = {
  50: '#f8f7f6',
  100: '#edece9',
  200: '#e2dfdb',
  300: '#d6d2cc',
  400: '#cdc9c1',
  500: '#c4bfb6',
  600: '#beb9af',
  700: '#b6b1a6',
  800: '#afa99e',
  900: '#a29b8e',
  A100: '#ffffff',
  A200: '#ffffff',
  A400: '#fff1d4',
  A700: '#ffe8ba',
};

export const PentairDarkGray: Color = {
  50: '#ebebeb',
  100: '#cdcdce',
  200: '#acacad',
  300: '#8a8b8c',
  400: '#717274',
  500: '#58595b',
  600: '#505153',
  700: '#474849',
  800: '#3d3e40',
  900: '#2d2e2f',
  A100: '#8cc0f4',
  A200: '#5da7f0',
  A400: '#1b8dff',
  A700: '#0280ff',
};

export const PentairGreen: Color = {
  50: '#ecf4e2',
  100: '#d1e5b6',
  200: '#b2d385',
  300: '#93c154',
  400: '#7bb430',
  500: '#64a70b',
  600: '#5c9f0a',
  700: '#529608',
  800: '#488c06',
  900: '#367c03',
  A100: '#c8ffa9',
  A200: '#a7ff76',
  A400: '#86ff43',
  A700: '#76ff2a',
};

export const PentairImpactGreen: Color = {
  50: '#e9f0e1',
  100: '#c8d9b3',
  200: '#a4c080',
  300: '#80a64d',
  400: '#649327',
  500: '#D0DF00',
  600: '#427801',
  700: '#396d01',
  800: '#316301',
  900: '#215000',
  A100: '#aaff83',
  A200: '#87ff50',
  A400: '#64ff1d',
  A700: '#52ff03',
};

export const PentairRed: Color = {
  50: '#fae4e4',
  100: '#f2bcbc',
  200: '#e98f8f',
  300: '#e06262',
  400: '#da4040',
  500: '#d31e1e',
  600: '#ce1a1a',
  700: '#c81616',
  800: '#c21212',
  900: '#b70a0a',
  A100: '#ffe3e3',
  A200: '#ffb0b0',
  A400: '#ff7d7d',
  A700: '#ff6363',
};

export const PentairBackground: Color = {
  50: '#fdfdfd',
  100: '#fbfbfb',
  200: '#f9f9f9',
  300: '#f6f6f6',
  400: '#f4f4f4',
  500: '#f2f2f2',
  600: '#f0f0f0',
  700: '#eeeeee',
  800: '#ececec',
  900: '#e8e8e8',
  A100: '#ffffff',
  A200: '#ffffff',
  A400: '#ffffff',
  A700: '#ffffff',
};

export const PentairOffWhite = {
  50: '#fefefe',
  100: '#fdfdfd',
  200: '#fcfcfc',
  300: '#fbfafa',
  400: '#faf9f9',
  500: '#f9f8f8',
  600: '#f8f7f7',
  700: '#f7f6f6',
  800: '#f6f5f5',
  900: '#f5f3f3',
  A100: '#ffffff',
  A200: '#ffffff',
  A400: '#ffffff',
  A700: '#ffffff',
};

export const PentairWhite = {
  500: '#ffffff',
};

export const PentairLightGreen = {
  50: '#eef6e2',
  100: '#d4e9b6',
  200: '#b7db85',
  300: '#9acd54',
  400: '#84c230',
  500: '#6eb70b',
  600: '#66b00a',
  700: '#5ba708',
  800: '#519f06',
  900: '#3f9003',
  A100: '#d4ffbc',
  A200: '#b3ff89',
  A400: '#93ff56',
  A700: '#82ff3c',
};

export const PentairDarkGold = {
  50: '#faf3e3',
  100: '#f2e2b8',
  200: '#e9cf89',
  300: '#e0bc5a',
  400: '#d9ad36',
  500: '#d29f13',
  600: '#cd9711',
  700: '#c78d0e',
  800: '#c1830b',
  900: '#b67206',
  A100: '#fff2e0',
  A200: '#ffdcad',
  A400: '#ffc67a',
  A700: '#ffbb60',
};

export const PentairKhaki = {
  50: '#faf9f3',
  100: '#f2f0e2',
  200: '#e9e7cf',
  300: '#e0ddbb',
  400: '#d9d5ad',
  500: '#d2ce9e',
  600: '#cdc996',
  700: '#c7c28c',
  800: '#c1bc82',
  900: '#b6b070',
  A100: '#ffffff',
  A200: '#fffffe',
  A400: '#fffbcb',
  A700: '#fff9b2',
};

export const PentairSeafoamGreen = {
  50: '#f5f9f6',
  100: '#e6efea',
  200: '#d6e5dc',
  300: '#c6dacd',
  400: '#b9d2c3',
  500: '#adcab8',
  600: '#a6c5b1',
  700: '#9cbda8',
  800: '#93b7a0',
  900: '#83ab91',
  A100: '#ffffff',
  A200: '#ffffff',
  A400: '#d2ffe2',
  A700: '#b8ffd1',
};
