import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography, IconButton, Box, BoxProps, SxProps, Theme } from '@mui/material';

export interface DialogHeaderProps {
  title: string;
  subtitle?: string;
  onClose?: () => void;
  disableCloseButton?: boolean;
  subTitleExtraStyles?: SxProps<Theme>;
}

export const DialogHeader: React.FC<DialogHeaderProps & BoxProps> = ({
  title,
  subtitle,
  onClose,
  disableCloseButton,
  subTitleExtraStyles,
  ...boxProps
}) => {
  return (
    <Box paddingTop={2} paddingX={2} {...boxProps}>
      <Typography variant="h1" color="primary">
        {title}
      </Typography>

      <Typography variant="h1" color="secondary" fontWeight={400} paragraph sx={subTitleExtraStyles}>
        {subtitle}
      </Typography>

      {!disableCloseButton && onClose && (
        <IconButton aria-label="close" onClick={onClose} sx={{ position: 'absolute', right: 0, top: 0 }}>
          <FontAwesomeIcon icon={faTimes} />
        </IconButton>
      )}
    </Box>
  );
};
