import { Box, Grid, MenuItem } from '@mui/material';
import { useState } from 'react';
import { PentairTextField } from '../components/pentair-text-field';
import { SectionTitle } from '../components/section-title';

export const ThemeDemoPageForms = () => {
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [textValue, setTextValue] = useState<string>('');

  return (
    <Box component="form">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <SectionTitle title="Input - Text" />
          <PentairTextField
            label="First Name"
            description="This is a description"
            name="firstName"
            placeholder="First Name"
            required
            value={textValue}
            onChange={(e) => setTextValue(e.target.value)}
          />
          <PentairTextField
            label="First Name"
            name="firstName"
            placeholder="First Name"
            description="This is a description"
            required
            margin="dense"
            error
            helperText="This is an error"
            value={textValue}
            onChange={(e) => setTextValue(e.target.value)}
          />
          <PentairTextField
            disabled
            label="First Name"
            description="This is a description"
            name="firstName"
            placeholder="First Name"
            required
            value={textValue}
            onChange={(e) => setTextValue(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <SectionTitle title="Input - Select" />
          <PentairTextField
            select
            label="Select option"
            description="This is a description"
            name="selectoption"
            required
            value={selectedOption}
            onChange={(e) => setSelectedOption(e.target.value)}
          >
            <MenuItem value={'1'}>Option 1</MenuItem>
            <MenuItem value={'2'}>Option 2</MenuItem>
            <MenuItem value={'3'}>Option 3</MenuItem>
          </PentairTextField>
          <PentairTextField
            select
            label="Select option"
            description="This is a description"
            name="selectoption"
            required
            margin="dense"
            error
            helperText="This is an error"
            value={selectedOption}
            onChange={(e) => setSelectedOption(e.target.value)}
          >
            <MenuItem value={'1'}>Option 1</MenuItem>
            <MenuItem value={'2'}>Option 2</MenuItem>
            <MenuItem value={'3'}>Option 3</MenuItem>
          </PentairTextField>
          <PentairTextField
            select
            disabled
            label="Select option"
            description="This is a description"
            name="selectoption"
            required
            margin="dense"
            value={selectedOption}
            onChange={(e) => setSelectedOption(e.target.value)}
            error
            helperText="This is an error"
          >
            <MenuItem value={'1'}>Option 1</MenuItem>
            <MenuItem value={'2'}>Option 2</MenuItem>
            <MenuItem value={'3'}>Option 3</MenuItem>
          </PentairTextField>
        </Grid>
      </Grid>
    </Box>
  );
};
