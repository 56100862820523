import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CartItem, Product } from '../../../../types/operations';
import { useProductContent } from '../../products/hooks/use-product-attributes';

export const CartItemDescription: React.FC<{ cartItem: CartItem | null | undefined; title?: string }> = ({
  cartItem,
  title,
}) => {
  const { t } = useTranslation();
  const product = cartItem?.product;

  if (!product) {
    return null;
  }

  return (
    <>
      <Typography fontSize={'inherit'}>{title}</Typography>
      <small>
        {cartItem.details?.map((detail) => (
          <Typography component="span" key={detail.key} fontSize={'inherit'}>
            <strong>{t(`ecommerce:product.${detail.key}.label`)}:</strong> {detail.value}
            <br />
          </Typography>
        ))}{' '}
      </small>
    </>
  );
};

export const ProductDescriptionBundledProduct: React.FC<{ label: string; product: Product | null | undefined }> = ({
  label,
  product,
}) => {
  if (!product) return null;

  return (
    <Typography fontSize={'inherit'}>
      <small>
        <strong>{label}:</strong> {product.id} - {product.series}
      </small>
    </Typography>
  );
};

export const ProductConfiguration: React.FC<{ product: Product | null | undefined }> = ({ product }) => {
  const { attributeInfo } = useProductContent();

  if (!product || product.id !== 'QU01') return null;

  const keys: (keyof Product)[] = [
    'sizeUpper',
    'sealMaterial',
    'sealMaterialORing',
    'operationPressureLabelLaser',
    'materialInsert',
    'actuation',
    'apzLb',
    'controlUnitSeries',
  ];

  if (product.controlUnitSeries && product.controlUnitSeries !== 'MISSING') {
    keys.push('amountOfSolenoids', 'controlUnitCommunicationProtocol', 'controlUnitConnection');
  }

  return (
    <Typography fontSize={'inherit'}>
      {keys.map((key) =>
        product[key] ? (
          <small key={key}>
            <strong>{attributeInfo(key).label}:</strong>{' '}
            {product[key] === 'MISSING' ? 'None' : product[key] || 'Not Selected'}
            <br />
          </small>
        ) : null
      )}
    </Typography>
  );
};
