import { Product, ProductCollectionItem, ProductFilterOptions } from '../../../../types/operations';

type CacheInsertOptions = {
  cacheKey?: string;
  partialObject?: boolean;
};

type CacheState = {
  isPartial: boolean;
};

type CacheOptions = {
  ignoreKeys?: string[];
};

type CachedObject<TRecord> = { key: string; data: TRecord; __cacheState__?: CacheState };

export class Cache<TRecord extends Record<string, any> & { id?: string | number }> {
  private _cache: Record<string, CachedObject<TRecord>> = {};
  private _cachedLists: Record<string, string[]> = {};
  private _ignoreKeys: string[];

  constructor(private _options: CacheOptions = {}) {
    this._ignoreKeys = ['undefined', 'null', '', ...(this._options.ignoreKeys || [])];
  }

  get(key?: string): CachedObject<TRecord> | undefined {
    if (!key) return;
    return this._cache[key];
  }

  insert(record: TRecord | undefined | null, insertOptions: CacheInsertOptions = {}) {
    let key = insertOptions.cacheKey || String(record?.id);
    const ignoredKey = this._ignoreKeys.includes(key);
    key = ignoredKey ? JSON.stringify(record) : key;

    if (!record || this._ignoreKeys.includes(key) || !key) return;

    const existingRecord = this.get(key) || {};
    const isPartial = this._cache[key]?.__cacheState__?.isPartial || insertOptions?.partialObject || false;

    const newRecord = { ...existingRecord, ...record };
    const cachedObject = { key, data: newRecord, __cacheState__: { isPartial } };

    this._cache[key] = cachedObject;
    return this._cache[key];
  }

  public getList = (variables: Record<string, any>): TRecord[] | undefined => {
    return (
      (this._cachedLists[JSON.stringify(variables)]
        ?.map((id) => this.get(id)?.data)
        .filter((record) => record) as TRecord[]) || undefined
    );
  };

  public insertList = (
    list: TRecord[] | null | undefined,
    variables: Record<string, any>,
    insertOptions: CacheInsertOptions = {}
  ) => {
    insertOptions.partialObject = insertOptions.partialObject || true;

    const cachedList = list
      ?.map((record) => {
        return this.insert(record, insertOptions);
      })
      .filter(Boolean);

    const toCache = cachedList?.map((cachedObject) => String(cachedObject?.key)) || [];

    if (toCache.length === 0) return;

    this._cachedLists[JSON.stringify(variables)] = toCache;
  };
}

export const productCache = new Cache<Product | ProductCollectionItem>({ ignoreKeys: ['QU01'] });
export const productOptionsCache = new Cache<ProductFilterOptions>();
