import { Dialog, DialogContent, DialogProps, Divider, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RequestQuotation } from '../quotations/request-quotation';

import { useMediaQuery } from '@mui/material';
import { PentairBackground } from '../../../theme/colors';
import { DialogHeader } from '../../../theme/components/dialog-header';
import { useShoppingCart } from './hook/useShoppingcart';
import { ShoppingCartItems } from './shopping-cart-items';

export const ShoppingCart: React.FC<DialogProps> = (props) => {
  const { t } = useTranslation();
  const { setShoppingCartOpen, cart } = useShoppingCart();

  const theme = useTheme();
  const isFullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      fullWidth
      fullScreen={isFullScreen}
      PaperProps={{
        sx: {
          maxWidth: 1500,
          borderTop: ['3px solid', '3px solid', 'none'],
          borderTopColor: [PentairBackground['500'], PentairBackground['500'], 'none'],
        },
      }}
      hideBackdrop={isFullScreen}
      {...props}
      sx={{
        marginTop: 4,
      }}
    >
      <DialogHeader
        title={t('ecommerce:dialog.cart.title', { defaultValue: 'Pentair Südmo' })}
        subtitle={t('ecommerce:dialog.cart.subtitle', { defaultValue: 'Shopping cart' })}
        onClose={() => setShoppingCartOpen(false)}
        subTitleExtraStyles={{
          marginBottom: [3, 3, 'initial'],
        }}
      />

      <DialogContent>
        <Box display="flex" flexDirection={['column', 'column', 'row']} paddingBottom={[6, 6, 0]}>
          <Box flex={1} marginBottom={['10px', '10px', 0]}>
            <ShoppingCartItems />
          </Box>
          {(cart?.items?.length && (
            <>
              <Divider flexItem orientation="vertical" sx={{ paddingLeft: 2, display: ['none', 'none', 'block'] }} />
              <RequestQuotation />
            </>
          )) ||
            null}
        </Box>
      </DialogContent>
    </Dialog>
  );
};
