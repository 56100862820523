import { Box, BoxProps, Divider, Grid, ListItem, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { DialogHeader } from '../../../theme/components/dialog-header';
import { PentairHeading } from '../../../theme/components/pentair-heading';
import { PentairBulletList, PentairOrderedList } from '../../../theme/components/unordered-list';

import asepticClosed from './assets/aseptic-closed.png';
import asepticClosing from './assets/aseptic-closing.png';
import asepticOpening from './assets/aseptic-opening.png';

export const AboutAsepticValves: React.FC<BoxProps> = (props) => {
  const { t } = useTranslation();

  return (
    <Box {...props}>
      <DialogHeader
        title={t('about', { defaultValue: 'About' })}
        subtitle={t('asepticValves', { defaultValue: 'Aseptic valves' })}
      />

      <Divider sx={{ borderBottomWidth: 3 }} />

      <Box padding={2}>
        <Trans i18nKey={'support:assistant.isHygienicOrAseptic.infoDialog.aseptic.about'}>
          <Typography variant="body2" paragraph>
            'Aseptic' means to be free from contamination caused by harmful bacteria, microorganisms, or viruses.
            Aseptic valves have material and a surface finish optimized for exceptional cleanability.
          </Typography>
          <Typography variant="body2" paragraph>
            Being an aseptic valve also means each component of the valve that comes into contact with the process is
            impermeably sealed from the environment and atmosphere. A PTFE diaphragm attached to the stem prevents
            harmful bacteria from getting into the process.
          </Typography>
          <Typography variant="body2">
            Aseptic valves are often found in industries requiring high levels of purity. These include manufacturers of
            medicinal drugs, cosmetics, microelectronics, and the like. Aseptic valves are used to control processes and
            minimize contamination.
          </Typography>
        </Trans>
      </Box>

      <PentairHeading contained spacing="dense">
        {t('typicalUseCases', {
          defaultValue: 'Typical use cases',
        })}
      </PentairHeading>

      <Box padding={2} display="flex" flexWrap="wrap">
        <Box flex={1} minWidth={150} marginBottom={1}>
          <PentairHeading variant="body2" gutterBottom>
            {t('dairyAndFood', { defaultValue: ' Dairy & Food' })}
          </PentairHeading>
          <PentairBulletList>
            <ListItem>{t('fruitPurees', { defaultValue: 'Fruit purees' })}</ListItem>
            <ListItem>{t('vegetablePurees', { defaultValue: 'Vegetable purees' })}</ListItem>
            <ListItem>{t('tomatoKetchup', { defaultValue: 'Tomato ketchup' })}</ListItem>
            <ListItem>{t('mayonaise', { defaultValue: 'Mayonaise' })}</ListItem>
            <ListItem>{t('yoghurtWithFruit', { defaultValue: 'Yoghurt with fruit' })}</ListItem>
            <ListItem>{t('cream', { defaultValue: 'Cream' })}</ListItem>
            <ListItem>{t('dessert', { defaultValue: 'Dessert' })}</ListItem>
          </PentairBulletList>
        </Box>
        <Box flex={1} minWidth={150} marginBottom={1}>
          <PentairHeading variant="body2" gutterBottom>
            {t('abrasive', { defaultValue: 'Abrasive' })}
          </PentairHeading>
          <PentairBulletList>
            <ListItem>{t('lactose', { defaultValue: 'Lactose' })}</ListItem>
            <ListItem>{t('instantCoffee', { defaultValue: 'Instant coffee' })}</ListItem>
          </PentairBulletList>
        </Box>
        <Box flex={1} minWidth={150}>
          <PentairHeading variant="body2" gutterBottom>
            {t('pharmaceutical', { defaultValue: 'Pharmaceutical' })}
          </PentairHeading>
          <PentairBulletList>
            <ListItem>{t('waterForInjection', { defaultValue: 'Water for injection (WFI)' })}</ListItem>
            <ListItem>{t('coughSyrup', { defaultValue: 'Cough syrup' })}</ListItem>
            <ListItem>{t('gelForCachets', { defaultValue: 'Gel for cachets' })}</ListItem>
          </PentairBulletList>
        </Box>
      </Box>

      <PentairHeading contained spacing="dense">
        {t('openingClosingTheValve', { defaultValue: 'Opening/closing the valve' })}
      </PentairHeading>

      <Box padding={2}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Image src={asepticClosed} marginBottom={1} />
            <Typography variant="body2" align="center" fontWeight={600}>
              1. {t('closed', { defaultValue: 'Closed' })}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Image src={asepticOpening} marginBottom={1} />
            <Typography variant="body2" align="center" fontWeight={600}>
              2. {t('opening', { defaultValue: 'Opening' })}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Image src={asepticClosing} marginBottom={1} />
            <Typography variant="body2" align="center" fontWeight={600}>
              3. {t('closing', { defaultValue: 'Closing' })}
            </Typography>
          </Grid>
          <Grid item>
            <PentairOrderedList>
              <ListItem>
                {t('support:assistant.isHygienicOrAseptic.infoDialog.aseptic.descriptionValveClosed', {
                  defaultValue: 'P3 diaphragm provides an aseptic barrier between the product area and atmosphere',
                })}
              </ListItem>

              <ListItem>
                {t('support:assistant.isHygienicOrAseptic.infoDialog.aseptic.descriptionValveOpening', {
                  defaultValue: 'The aseptic barrier prevents the elevator effect',
                })}
              </ListItem>

              <ListItem>
                {t('support:assistant.isHygienicOrAseptic.infoDialog.aseptic.descriptionValveClosing', {
                  defaultValue: 'The aseptic barrier prevents the shuttle effect',
                })}
              </ListItem>
            </PentairOrderedList>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

const Image: React.FC<{ src: string } & BoxProps> = ({ src, ...props }) => {
  return (
    <Box
      padding={'50%'}
      {...props}
      sx={{
        backgroundImage: `url('${src}')`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        ...(props.sx || {}),
      }}
    />
  );
};
