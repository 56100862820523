import { AuthTemplate } from './auth-template';
import { ForceResetPasswordForm } from './forms/force-reset-password';

export const AuthStatePasswordResetRequired = () => {
  return (
    <AuthTemplate title="Password Reset Required">
      <ForceResetPasswordForm />
    </AuthTemplate>
  );
};
