import { AboutSolidBodyHousing } from './about-solid-body-housing';
import { AboutSplitBodyHousing } from './about-split-body-housing';
import { ComparisonGrid } from './comparison-grid';

export const InfoHousingBodyExec = () => {
  return (
    <ComparisonGrid>
      <AboutSolidBodyHousing />
      <AboutSplitBodyHousing />
    </ComparisonGrid>
  );
};
