import { cloneDeep, isArray, isObject, mergeWith } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Subset } from '../../types/objects';
import { useChangeManager } from './use-change-manager';
import { Validators, makeRecordValidator } from './use-validator';

export type FormData<TRecord extends Record<string, any> = Record<string, any>> = Subset<TRecord>;

export type FormOptions<TRecord extends Record<string, any>> = {
  disabled?: boolean;
  validations?: Validators<FormData<TRecord>>;
  onSubmit?: (record: TRecord) => Promise<void> | void;
  onChange?: (record: FormData<TRecord>) => void;
  validateOnSubmit?: boolean;
};

const defaultOptions = {
  disabled: false,
  validations: {},
  onSubmit: undefined,
  onChange: undefined,
};

/**
 * The useFormData hook is used to handle form options including submitting a form
 * @param {FormData} initialRecord
 * @param {FormOptions} formOptions
 * @returns {Object}
 */
export const useFormData = <TRecord extends Record<string, any>>(
  initialRecord: FormData<TRecord> | undefined | null,
  { validations, onSubmit, onChange, disabled, validateOnSubmit }: FormOptions<TRecord> = defaultOptions
) => {
  const { change, changes, resetChanges } = useChangeManager<FormData<TRecord>>();
  const useValidator = useMemo(() => makeRecordValidator<FormData<TRecord>>(validations), [validations]);
  const { errors, validate, validateRecord, setErrors } = useValidator();
  const record: FormData<TRecord> = useMemo(
    () => mergeWith({}, cloneDeep(initialRecord), changes, mergeCusomizer),
    [changes, initialRecord]
  );
  const [submissionError, setSubmisisonError] = useState<Error | null>(null);
  const mounted = useRef(false);
  const [isSubmitted, setIsSubmitted] = useState(!validateOnSubmit);

  useEffect(() => {
    if (isSubmitted && mounted.current && !Boolean(disabled)) {
      validateRecord(record);
    } else {
      setErrors({});
    }

    mounted.current = true;
  }, [validateRecord, record, changes, disabled, setErrors, isSubmitted]);

  const submitForm = useCallback(
    async (ev?: React.FormEvent) => {
      ev?.preventDefault();
      setIsSubmitted(true);

      try {
        const _errors = validateRecord(record, true);
        const hasErrors = Object.keys(_errors).length > 0;

        if (!disabled && !hasErrors && onSubmit) {
          await onSubmit(record as TRecord);
        }
      } catch (e: any) {
        console.error(e);
        setSubmisisonError(e);
      }
    },
    [validateRecord, record, disabled, onSubmit]
  );

  useEffect(() => {
    if (onChange) {
      onChange(record);
    }
  }, [record, onChange]);

  return {
    record,
    changes,
    validationErrors: errors,
    change,
    resetChanges,
    validate,
    validateRecord,
    hasValidationErrors: Object.keys(errors).length > 0,
    submitForm,
    disabled,
    submissionError,
  };
};

/**
 * Customizer for lodash mergeWith
 * This ensures that arrays are replaced instead of merged
 *
 * @param objValue
 * @param srcValue
 * @returns srcValue | mergeWith
 * @see https://lodash.com/docs/4.17.15#mergeWith
 */

function mergeCusomizer(objValue: any, srcValue: any): any {
  if (isArray(objValue)) {
    return srcValue;
  }

  if (isObject(objValue)) {
    return mergeWith(objValue, srcValue, mergeCusomizer);
  }

  return srcValue;
}
