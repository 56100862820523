import { Box, BoxProps } from '@mui/material';
import { Children, PropsWithChildren, cloneElement, useMemo } from 'react';

export const ComparisonGrid: React.FC<PropsWithChildren & { sx?: BoxProps }> = ({ children, sx }) => {
  // Apply styles to children
  const styledChildren = useMemo(() => {
    return Children.map(children, (child, index) => {
      if (child && typeof child === 'object' && 'props' in child) {
        const estimatedChildren = Array.from(Array(10).keys());
        const clone = cloneElement(child, {
          sx: {
            // take the existing styles and add our own
            ...child.props.sx,

            // ensure subchildren can make use of the grid.
            display: 'contents',

            // generate styles for each subchild and merge them into the existing styles
            ...estimatedChildren.reduce(
              (styles, subIndex) => ({
                ...styles,
                [`& > :nth-child(${subIndex + 1})`]: {
                  borderLeft: index > 0 && '3px solid #eee',
                  // put each subchild into a grid cell with the correct column and row
                  gridColumn: `${index + 1} / span 1`, // use index to determine column
                  gridRow: `${subIndex + 1} / span 1`, // use subIndex to determine row
                },
              }),
              {}
            ),
          },
        });

        return clone;
      }

      return child;
    });
  }, [children]);

  return (
    <Box
      sx={{
        display: 'grid',
        gridAutoColumns: ['80vw', '80vw', '540px'],
        ...sx,
      }}
    >
      {styledChildren}
    </Box>
  );
};
