import gql from 'graphql-tag';
import { cartFragment } from '../../shopping-cart/api/fragments';

const contactInfoFragment = gql`
  fragment ContactInfoFragment on ContactInfo {
    organisationName
    firstName
    lastName
    phone1
    phone2
    email
    country
    state
    postal
    city
    addressLine1
    addressLine2
    addressLine3
  }
`;

export const quotationFragment = gql`
  ${contactInfoFragment}
  ${cartFragment}

  fragment QuotationFragment on Quotation {
    id
    projectName
    projectDescription
    customerId
    shippingInfo {
      ...ContactInfoFragment
    }

    billToShippingAddress
    billingInfo {
      ...ContactInfoFragment
    }
    cart {
      ...CartFragment
    }
    subscribeToNewsAndUpdates
    termsAccepted
    status
    createdAt
    createdBy
    updatedAt
    updatedBy
  }
`;

export const listQuotationsFragment = gql`
  ${contactInfoFragment}
  fragment QuotationCollectionFragment on QuotationCollection {
    quotations {
      id
      projectName
      projectDescription
      shippingInfo {
        ...ContactInfoFragment
      }
      billToShippingAddress
      billingInfo {
        ...ContactInfoFragment
      }
      cart {
        pricing {
          total
          discountPrice
        }
      }
      status
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
    pagination {
      limit
      offset
    }
  }
`;
