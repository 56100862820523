import { faArrowUpRightFromSquare, faHeadset } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Container, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { PentairBrightBlue, PentairIcon, PentairIconInfo } from '@pentair/react-mui-5';
import { camelCase } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import { useNavigate, useParams } from 'react-router';
import { useFetchQRInfo } from '../feature/ecommerce/products/api/fetch-qr';
import { useFetchProductList } from '../feature/ecommerce/products/api/list';
import { useProductContent } from '../feature/ecommerce/products/hooks/use-product-attributes';
import { PageHeader } from '../feature/page-layout/header/page-header';
import { Page } from '../feature/page-layout/page';
import { useSupport } from '../feature/support/support-provider';
import { PentairHeading } from '../theme/components/pentair-heading';
import { InputFilterAndOrOperators, ProductCollectionItem, QrInfo } from '../types/operations';
import PageNotFound from './404';
import { PageLoading } from './loading';

const PageContainer = styled(Container)(({ theme }) => ({
  maxWidth: 'calc(1920px + 10vw) !important',
  paddingLeft: '5vw !important',
  paddingRight: '5vw !important',
  paddingBottom: theme.spacing(6),
  paddingTop: 'calc(3vw)',
}));

export const PageQRInfo: React.FC = () => {
  const { t } = useTranslation();
  const [{ response, isCompleted, loading: qrDataFetching }, fetchQRInfo] = useFetchQRInfo();
  const [{ response: productsResponse, loading: productsFetching }, fetchProducts] = useFetchProductList();
  const { setSupportDialogOpen } = useSupport();
  const navigate = useNavigate();
  const { locale } = useParams();

  const loading = qrDataFetching || productsFetching;

  const product = response.data?.productQRInfo;
  const products = useMemo(
    () =>
      productsResponse.data?.listProducts?.products.reduce((acc: Record<string, ProductCollectionItem>, product) => {
        acc[product.id] = product;
        return acc;
      }, {}),
    [productsResponse.data?.listProducts?.products]
  );

  const productExistsInSudmo = Boolean(product?.productId && products?.[product.productId]);

  const params = useParams<{ serialNumber?: string }>();
  const serialNumber = params.serialNumber?.split('-')[0];

  useEffect(() => {
    if (serialNumber) {
      fetchQRInfo({ id: serialNumber }).then(({ data }) => {
        fetchProducts({
          filters: [
            {
              expression: {
                key: 'id',
                operator: '=',
                value: 'ABC',
              },
              expressionGroup: (
                [
                  data?.productQRInfo?.sealKitActuatorSide1,
                  data?.productQRInfo?.sealKitActuatorSide2,
                  data?.productQRInfo?.sealKitActuatorSide3,
                  data?.productQRInfo?.sealKitProductSide1,
                  data?.productQRInfo?.sealKitProductSide2,
                  data?.productQRInfo?.sealKitProductSide3,
                  data?.productQRInfo?.sealKitProductSide4,
                  data?.productQRInfo?.productId,
                ].filter(Boolean) as string[]
              ).map((value) => ({
                operator: InputFilterAndOrOperators.Or,
                expression: {
                  key: 'id',
                  operator: '=',
                  value,
                },
              })),
            },
          ],
        });
      });
    }
  }, [fetchProducts, fetchQRInfo, serialNumber]);

  if (!isCompleted || loading) {
    return <PageLoading />;
  }

  if (isCompleted && !product) {
    return <PageNotFound />;
  }

  return (
    <Page title={'QR Code Results - ' + serialNumber} description={''}>
      <PageContainer maxWidth={'lg'}>
        <Box display="flex" alignItems="center" gap={2} marginBottom={4} marginTop={[3, 2, 1, 0]}>
          <QRCode value={'https://www.sudmo.com/qr/' + serialNumber} size={120} fgColor="#2D3D48" />
          <PageHeader primaryText={'QR Code Results'} secondaryText={serialNumber} sx={{ marginBottom: -2 }} />
        </Box>

        <Box display={'flex'} flexDirection={'column'}>
          <Typography paddingBottom={3}>{t('app:pageQrInfo.introText')}</Typography>

          <Box display="flex" gap={[2, 2, 2, 2, 4]} flexDirection={['column', 'column', 'row']}>
            <Box
              display={'flex'}
              gap={[2, 2, 2, 2, 4]}
              width={'100%'}
              flex="2"
              flexDirection={['column', 'column', 'column', 'row']}
            >
              <Box flex={'1'}>
                <Box>
                  <ProductAttributes
                    title={t('app:pageQrInfo.heading.manufacturingInformation', {
                      defaultValue: 'Manufacturing information',
                    })}
                    include={['serialNumber', 'dateOfManufacture', 'salesOrderNumber']}
                    product={product}
                    productList={products}
                  />
                </Box>

                <Box marginTop={2}>
                  <ProductAttributes
                    title={t('app:pageQrInfo.heading.productInformation', {
                      defaultValue: 'Product Information',
                    })}
                    include={['productId', 'housingType', 'productSeries']}
                    product={product}
                    productList={products}
                  />
                </Box>
              </Box>
              <Box flex="1">
                <ProductAttributes
                  title={t('app:pageQrInfo.heading.productDetails', {
                    defaultValue: 'Product Details',
                  })}
                  include={[
                    'portSizes',
                    'actuationType',
                    'sealMaterial1',
                    'sealMaterial2',
                    'sealMaterial3',
                    'material',
                    'surfaceFinish',
                    'settingsRangeOverflowValve',
                    'operatingPressure',
                    'controlAirPressure',
                    'sealKitProductSide1',
                    'sealKitProductSide2',
                    'sealKitProductSide3',
                    'sealKitProductSide4',
                    'sealKitActuatorSide1',
                    'sealKitActuatorSide2',
                    'sealKitActuatorSide3',
                    'exArea',
                  ]}
                  product={product}
                  productList={products}
                />
              </Box>
            </Box>

            <Box flex={'1'} display="flex" gap={2} flexDirection={'column'}>
              {productExistsInSudmo && (
                <Box sx={{ backgroundColor: '#F2F2F2', padding: 2 }}>
                  <Typography color="primary" variant={'h3_condensed'} fontWeight={600}>
                    {t('app:pageQrInfo.findValve.title')}
                  </Typography>
                  <Typography color="secondary" variant={'h3_condensed'} paragraph>
                    {t('app:pageQrInfo.findValve.subTitle')}
                  </Typography>
                  <Button
                    startIcon={<PentairIconInfo />}
                    onClick={() => navigate('/' + locale + '/valve/' + product?.productId)}
                  >
                    {t('app:pageQrInfo.findValve.cta')}
                  </Button>
                </Box>
              )}
              <Box sx={{ backgroundColor: '#F2F2F2', padding: 2 }}>
                <Typography color="primary" variant={'h3_condensed'} fontWeight={600}>
                  {t('app:pageQrInfo.support.title')}
                </Typography>
                <Typography color="secondary" variant={'h3_condensed'} paragraph>
                  {t('app:pageQrInfo.support.subTitle')}
                </Typography>
                <Button
                  startIcon={<FontAwesomeIcon icon={faHeadset} fontSize={18} />}
                  onClick={() => setSupportDialogOpen(true)}
                >
                  {t('app:pageQrInfo.support.cta')}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </PageContainer>
    </Page>
  );
};

export const ProductAttributes: React.FC<{
  include?: Array<keyof QrInfo>;
  product: Record<string, any> | undefined | null;
  title?: string;
  productList?: Record<string, ProductCollectionItem>;
}> = ({ product, include, title, productList }) => {
  const { t } = useTranslation();
  const { attributeInfo } = useProductContent();
  const navigate = useNavigate();
  const { locale } = useParams();

  if (!product) {
    return null;
  }

  return (
    <Box>
      <PentairHeading contained align="left" variant="h5" paddingX={'16px'}>
        {title}
      </PentairHeading>

      <Table>
        <TableBody>
          {Object.entries(product)?.map(([key, value]) => {
            let _value = value;
            if (key === 'dateofManufacture') {
              _value = new Date(value).toDateString();
            }

            let values = Array.isArray(_value) ? _value : [_value?.toString()];
            values = values.filter(Boolean).map((v) =>
              t(`ecommerce:productAttributesOptions.${camelCase(value.toString()) || 'missing'}`, {
                defaultValue: v,
              })
            );

            return (
              Boolean(values.length) &&
              (!include || include?.includes(key as keyof QrInfo)) && (
                <TableRow key={key}>
                  <TableCell sx={{ verticalAlign: 'top' }} width={'45%'}>
                    {attributeInfo(key).label}
                  </TableCell>
                  <TableCell sx={{ verticalAlign: 'top' }} width={'45%'}>
                    {values.map((v) => (
                      <span style={{ display: 'block' }}>{v}</span>
                    ))}
                  </TableCell>
                  <TableCell padding="none" align="right">
                    {[
                      'productId',
                      'sealKitActuatorSide1',
                      'sealKitActuatorSide2',
                      'sealKitActuatorSide3',
                      'sealKitProductSide1',
                      'sealKitProductSide2',
                      'sealKitProductSide3',
                      'sealKitProductSide4',
                    ].includes(key) &&
                      values[0] &&
                      productList?.[values[0]] && (
                        <Box
                          sx={{ padding: 1.25, paddingTop: 1.4, cursor: 'pointer' }}
                          onClick={() => navigate('/' + locale + '/valve/' + values[0])}
                        >
                          <PentairIcon
                            size="sm"
                            style={{ color: PentairBrightBlue['500'] }}
                            icon={faArrowUpRightFromSquare}
                          />
                        </Box>
                      )}
                  </TableCell>
                </TableRow>
              )
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
};

export default PageQRInfo;
