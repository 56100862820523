import { Box, BoxProps, Divider, ListItem, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DialogHeader } from '../../../theme/components/dialog-header';
import { PentairHeading } from '../../../theme/components/pentair-heading';
import { PentairBulletList, PentairMinusIconList, PentairPlusIconList } from '../../../theme/components/unordered-list';

import solidBodyHousing from './assets/solid-body-housing-letters.png';
export const AboutSolidBodyHousing: React.FC<BoxProps> = ({ ...props }) => {
  const { t } = useTranslation();

  return (
    <Box maxWidth={'100px'} {...props}>
      <DialogHeader
        title={t('about', { defaultValue: 'About' })}
        subtitle={t('solidBodyHousing', { defaultValue: 'Solid body housing' })}
      />
      <Divider sx={{ borderBottomWidth: 3 }} />
      <Box paddingX={2} paddingTop={2}>
        <Box display="flex" gap={2}>
          <Image src={solidBodyHousing} width="36%" alt="Solid body housing" />
          <Box>
            <Typography variant="body2" paragraph>
              {t('support:aboutSolidBodyHousing.about.paragraph1', {
                defaultValue: 'Housing made from solid stainless-steel bar',
              })}
            </Typography>

            <PentairBulletList>
              <ListItem>
                {t('support:aboutSolidBodyHousing.about.bullet1', {
                  defaultValue: 'no clamp in between upper and lower housing part',
                })}
              </ListItem>
              <ListItem>
                {t('support:aboutSolidBodyHousing.about.bullet2', {
                  defaultValue: 'recommended Südmo standard',
                })}
              </ListItem>
            </PentairBulletList>
          </Box>
        </Box>
      </Box>

      <Box paddingX={2} paddingTop={2}>
        <PentairHeading variant="body2" paragraph>
          {t('prosAndCons', { defaultValue: "Pro's and con's" })}
        </PentairHeading>

        <PentairPlusIconList>
          <ListItem>
            {t('support:aboutSolidBodyHousing.pros.bullet1', {
              defaultValue: 'No sealings in between the two housing body parts required (maintenance free).',
            })}
          </ListItem>
          <ListItem>
            {t('support:aboutSolidBodyHousing.pros.bullet2', {
              defaultValue:
                'No additional and removable housing port connection for maintenance purposes required, welding end as standard connection.',
            })}
          </ListItem>
        </PentairPlusIconList>
        <PentairMinusIconList>
          <ListItem>
            {t('support:aboutSolidBodyHousing.cons.bullet1', {
              defaultValue:
                'No flexibility for installation, other port configurations than A-D-U must be considered to order.',
            })}
          </ListItem>
        </PentairMinusIconList>
      </Box>
    </Box>
  );
};

const Image = styled(Box)<BoxProps<'img'>>({});
Image.defaultProps = {
  component: 'img',
};
