import React from 'react';
import { faUserCircle, faUserPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface OwnProps {
  onLoginClick: () => void;
  onGuestClick: () => void;
}

export const LoginOptions: React.FC<OwnProps> = ({ onLoginClick, onGuestClick }) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Typography variant="body2" paragraph>
        {t('iam:loginOptions.text', {
          defaultValue:
            'Please select one of the following options to continue. If you already have an account, please login. If you do not have an account, please continue as a guest.',
        })}
      </Typography>

      <Button
        variant="contained"
        color="secondary"
        sx={{ marginBottom: 1, justifyContent: 'flex-start' }}
        startIcon={<FontAwesomeIcon icon={faUserCircle} style={{ fontSize: 18 }}></FontAwesomeIcon>}
        onClick={onLoginClick}
      >
        {t('iam:loginOptions.button.signIn', { defaultValue: " I'd like to login" })}
      </Button>

      <Button
        variant="contained"
        sx={{ justifyContent: 'flex-start' }}
        startIcon={<FontAwesomeIcon icon={faUserPlus} style={{ fontSize: 18 }}></FontAwesomeIcon>}
        onClick={onGuestClick}
      >
        {t('iam:loginOptions.button.continueAsGuest', { defaultValue: 'Continue as guest' })}
      </Button>
    </React.Fragment>
  );
};
