/**
 * Inserts a script into the document.
 * @param src The source of the script.
 * @param id The id of the script.
 * @param parentElement The parent element of the script.
 * @returns The script element.
 */
export const insertScript = (src: string, id: string, parentElement: HTMLElement) => {
  if (typeof window === 'undefined') return;
  const exists = window.document.getElementById(id);
  if (exists) return exists;

  const script = window.document.createElement('script');
  script.async = true;
  script.src = src;
  script.id = id;
  parentElement.appendChild(script);
  return script;
};

/**
 * Removes a script from the document.
 * @param id The id of the script to remove.
 * @param parentElement The parent element of the script.
 */
export const removeScript = (id: string, parentElement: HTMLElement) => {
  if (typeof window === 'undefined') return;
  const script = window.document.getElementById(id);
  if (script) {
    parentElement.removeChild(script);
  }
};
