import { Alert, Box, Dialog, DialogContent, DialogProps, Typography } from '@mui/material';
import { FormProvider, PentairTextField, useForm } from '@pentair/react-mui-5';
import { useTranslation } from 'react-i18next';
import { DialogHeader } from '../../../../theme/components/dialog-header';
import { AddToCartButton } from '../../shopping-cart/add-to-cart-btn';

interface ProductRequestDialogProps extends DialogProps {
  onClose?: () => void;
}

type FormData = {
  articleNumber: string;
  productDescription: string;
};

export const ProductRequestDialog = (props: ProductRequestDialogProps) => {
  const { t } = useTranslation();
  return (
    <Dialog {...props} maxWidth="xs">
      <DialogHeader
        title={t('ecommerce:dialog.missingProduct.title')}
        subtitle={t('ecommerce:dialog.missingProduct.subtitle')}
        onClose={() => props.onClose?.()}
      />
      <FormProvider>
        <ProductRequestDialogContent onClose={props.onClose} />
      </FormProvider>
    </Dialog>
  );
};

const ProductRequestDialogContent: React.FC<{ onClose?(): void }> = (props) => {
  const { formData } = useForm<FormData>();
  const hasValue = Object.values(formData).some((value) => value !== '');
  const hasErrors = !hasValue;
  const { t } = useTranslation();

  return (
    <DialogContent>
      <Box display="flex" flexDirection="column" gap={2}>
        <Typography>{t('ecommerce:dialog.missingProduct.text')}</Typography>
        <div>
          <PentairTextField
            required={!Boolean(formData.productDescription)}
            name="articleNumber"
            label={t('ecommerce:dialog.missingProduct.articleNumber.label')}
            placeholder={t('ecommerce:dialog.missingProduct.articleNumber.placeholder')}
            description={t('ecommerce:dialog.missingProduct.articleNumber.description')}
          />
          <PentairTextField
            required={!Boolean(formData.articleNumber)}
            name="productDescription"
            multiline
            minRows={4}
            label={t('ecommerce:dialog.missingProduct.productDescription.label')}
            placeholder={t('ecommerce:dialog.missingProduct.productDescription.placeholder')}
            description={t('ecommerce:dialog.missingProduct.productDescription.description')}
          />
          {hasErrors && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {t('ecommerce:dialog.missingProduct.error')}
            </Alert>
          )}
        </div>
        <Box display="flex" justifyContent={'end'}>
          <AddToCartButton
            disabled={hasErrors}
            isMissingProductRequest
            product={{ id: formData.articleNumber || '000000', name: 'Missing Product Request' }}
            note={formData.productDescription}
            onClick={props.onClose}
          />
        </Box>
      </Box>
    </DialogContent>
  );
};
