import gql from 'graphql-tag';

export const userSettingsFragment = gql`
  fragment UserSettingsFragment on UserSettings {
    addresses {
      id
      addressLine1
      addressLine2
      addressLine3
      city
      state
      postal
      country
      organisationName
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const pricingFragment = gql`
  fragment PricingFragment on Pricing {
    customerId
    pricingStatus
    pricingUpdatedAt
  }
`;

export const emailSettingsFragment = gql`
  fragment EmailSettingsFragment on EmailSettings {
    BccAddresses
    CcAddresses
    fromAddress
    ToAddresses
  }
`;
