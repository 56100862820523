import { Validator } from './use-validator';

/**
 *  Ip address validation
 * Ensures that a value for a field is an IP address
 * @param validate - a function that accepts a value {string}. It is made up of properties such as isEmail, isBoolean. See  'app/src/feature/form/validations.ts'
 * @param value - a string | boolean
 * @returns (boolean | string) []
 */
export const isIPAddress: Validator = (validate, value) => [validate.isIP(value)];

/**
 * Required Ip address validation
 * Ensures that a value for IP address is provided
 * @param validate - a function that accepts a value {string}. It is made up of properties such as isEmail, isBoolean. See  'app/src/feature/form/validations.ts'
 * @param value - a string | boolean
 * @returns (boolean | string) []
 */
export const requiredIPAddress: Validator = (validate, value) => [validate.isRequired(value), validate.isIP(value)];

/**
 * Required string validation
 * Ensures that a value for a field is a string
 * @param validate - a function that accepts a value {string}. It is made up of properties such as isEmail, isBoolean. See  'app/src/feature/form/validations.ts'
 * @param value - a string | boolean
 * @returns (boolean | string) []
 */
export const requiredString: Validator = (validate, value) => [
  validate.isRequired(value),
  validate.maxCharCount(value, 255),
];

/**
 * Required field validation
 * Ensures that a value for a field is provided
 * @param validate - a function that accepts a value {string}. It is made up of properties such as isEmail, isBoolean. See  'app/src/feature/form/validations.ts'
 * @param value - a string | boolean
 * @returns (boolean | string) []
 */
export const required: Validator = (validate, value) => [validate.isRequired(value)];

/**
 * Basic field validations
 * Ensures that a value for a field is a boolean.
 * @param validate - a function that accepts a value {string}. It is made up of properties such as isEmail, isBoolean. See  'app/src/feature/form/validations.ts'
 * @param value - a string | boolean
 * @returns (boolean | string) []
 */
export const isBoolean: Validator = (validate, value) => [validate.isBoolean(value)];

// Password validations

/**
 * Password validations
 * Ensures that a value for password is provided
 * @param validate - a function that accepts a value {string}. It is made up of properties such as isEmail, isBoolean. See  'app/src/feature/form/validations.ts'
 * @param value - a string | boolean
 * @returns (boolean | string) []
 */
export const requiredPassword: Validator = (validate, value) => [
  validate.isRequired(value),
  validate.isSavePassword(value),
];

/**
 * Email validations
 * Ensures that a value for email is provided
 * @param validate - a function that accepts a value {string}. It is made up of properties such as isEmail, isBoolean. See  'app/src/feature/form/validations.ts'
 * @param value - a string | boolean
 * @returns (boolean | string) []
 */
export const requiredEmail: Validator = (validate, value) => [validate.isRequired(value), validate.isEmail(value)];

/**
 * Phone Number validation
 * Ensures that a value for phone number is provided
 * @param validate - a function that accepts a value {string}. It is made up of properties such as isPhoneNumber, isBoolean. See  'app/src/feature/form/validations.ts'
 * @param value - a string | boolean
 * @returns (boolean | string) []
 */
export const requiredPhoneNumber: Validator = (validate, value) => [
  validate.isRequired(value),
  validate.isPhoneNumber(value),
];

/**
 * Email validations
 * Ensures that email does not exceed 255 characters and that a valid email is provided
 * @param validate - a function that accepts a value {string}. It is made up of properties such as isEmail, isBoolean. See  'app/src/feature/form/validations.ts'
 * @param value - a string | boolean
 * @returns (boolean | string) []
 */
export const isEmail: Validator = (validate, value) => [
  (value && validate.isEmail(value)) || false,
  validate.maxCharCount(value, 255),
];

/**
 * URL validations
 * Ensures that a URL is provided
 * @param validate - a function that accepts a value {string}. It is made up of properties such as isURL, isBoolean. See  'app/src/feature/form/validations.ts'
 * @param value - a string | boolean
 * @returns (boolean | string) []
 */
export const isUrl: Validator = (validate, value) => [
  // (value && validate.isEmail(value)) || false,
  validate.isURL(value),
];

/**
 * Phone Number validation
 * Ensures that a value for phone number is valid
 * @param validate - a function that accepts a value {string}. It is made up of properties such as isPhoneNumber, isBoolean. See  'app/src/feature/form/validations.ts'
 * @param value - a string | boolean
 * @returns (boolean | string) []
 */
export const isPhoneNumber: Validator = (validate, value) => [(value && validate.isPhoneNumber(value)) || false];
