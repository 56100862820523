import { AWSLexV2Provider } from '@aws-amplify/interactions';
import { LexRuntimeV2, SessionState } from '@aws-sdk/client-lex-runtime-v2';
import { Credentials } from '@aws-amplify/core';

export class SuedmoAssistantLexBotProvider extends AWSLexV2Provider {
  public async deleteSession(botname: string) {
    // check if bot exists
    if (!this._config[botname]) {
      return Promise.reject('Bot ' + botname + ' does not exist');
    }

    // check if credentials are present
    let credentials;
    try {
      credentials = await Credentials.get();
    } catch (error) {
      return Promise.reject('No credentials');
    }

    const client = new LexRuntimeV2({ region: this._config[botname].region, credentials });

    const response = await client.deleteSession({
      botAliasId: this._config[botname].aliasId,
      botId: this._config[botname].botId,
      localeId: this._config[botname].localeId,
      sessionId: credentials.identityId,
    });

    return response;
  }

  public async getSession(botname: string) {
    // check if bot exists
    if (!this._config[botname]) {
      return Promise.reject('Bot ' + botname + ' does not exist');
    }

    // check if credentials are present
    let credentials;
    try {
      credentials = await Credentials.get();
    } catch (error) {
      return Promise.reject('No credentials');
    }

    const client = new LexRuntimeV2({ region: this._config[botname].region, credentials });

    const response = await client.getSession({
      botAliasId: this._config[botname].aliasId,
      botId: this._config[botname].botId,
      localeId: this._config[botname].localeId,
      sessionId: credentials.identityId,
    });

    return response;
  }

  public async putSession(botname: string, sessionState?: SessionState) {
    // check if bot exists
    if (!this._config[botname]) {
      return Promise.reject('Bot ' + botname + ' does not exist');
    }

    // check if credentials are present
    let credentials;
    try {
      credentials = await Credentials.get();
    } catch (error) {
      return Promise.reject('No credentials');
    }

    const client = new LexRuntimeV2({ region: this._config[botname].region, credentials });

    const response = await client.putSession({
      botAliasId: this._config[botname].aliasId,
      botId: this._config[botname].botId,
      localeId: this._config[botname].localeId,
      sessionId: credentials.identityId,
      sessionState,
      responseContentType: 'text/plain; charset=utf-8',
    });

    return response;
  }
}
