import { faInfoCircle, faPenToSquare, faPencilAlt, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, IconButtonProps, TableCell, TableCellProps } from '@mui/material';
import { Price } from '../../feature/pricing/price';
import { usePricing } from '../../feature/pricing/pricing-provider';

type TableCellIconProps = Omit<TableCellProps, 'onClick' | 'value'> & {
  onClick?: IconButtonProps['onClick'];
  disabled?: IconButtonProps['disabled'];
  /** Simply used for accessibility */
  label?: string;
  value?: string | number;
};

export const TableCellEdit: React.FC<TableCellIconProps> = ({ onClick, disabled, value, ...props }) => {
  return (
    <TableCell padding="checkbox" {...props}>
      <IconButton onClick={onClick} value={value} disabled={disabled}>
        <FontAwesomeIcon icon={faPencilAlt} />
      </IconButton>
    </TableCell>
  );
};
export const TableCellPenToSquareEdit: React.FC<TableCellIconProps> = ({ onClick, disabled, value, ...props }) => {
  return (
    <TableCell padding="checkbox" {...props}>
      <IconButton onClick={onClick} value={value} disabled={disabled}>
        <FontAwesomeIcon icon={faPenToSquare} />
      </IconButton>
    </TableCell>
  );
};

export const TableCellInfo: React.FC<TableCellIconProps> = ({ onClick, disabled, value, label, ...props }) => {
  return (
    <TableCell padding="checkbox" {...props}>
      <IconButton onClick={onClick} value={value} disabled={disabled} aria-label={label} data-testid={label}>
        <FontAwesomeIcon icon={faInfoCircle} />
      </IconButton>
    </TableCell>
  );
};

export const TableCellDelete: React.FC<TableCellIconProps> = ({ onClick, disabled, value, label, ...props }) => {
  return (
    <TableCell padding="checkbox" {...props}>
      <IconButton onClick={onClick} value={value} disabled={disabled} aria-label={label}>
        <FontAwesomeIcon icon={faTrashAlt} />
      </IconButton>
    </TableCell>
  );
};

export const TableCellPrice: React.FC<
  TableCellProps & { value: number | null | undefined; valueDiscount: number | null | undefined }
> = ({ value, valueDiscount, ...props }) => {
  const { isUserAllowedToSeePrices } = usePricing();
  if (!isUserAllowedToSeePrices) return null;

  return (
    <TableCell {...props} sx={{ whiteSpace: 'nowrap', ...props.sx }}>
      <Price price={value} discountPrice={valueDiscount} showTaxingInfo={false} />
    </TableCell>
  );
};

export const TableCellDate: React.FC<TableCellIconProps> = ({ value, onClick, disabled, ...props }) => {
  return (
    <TableCell {...props}>
      {new Date(value || 0).toLocaleDateString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,
      })}
    </TableCell>
  );
};
