import { Components, darken, Theme } from '@mui/material';

export const MuiIconButton: Components<Theme>['MuiIconButton'] = {
  defaultProps: {
    disableRipple: true,
    disableFocusRipple: true,
    disableTouchRipple: true,
  },
  styleOverrides: {
    root: ({ theme, ownerState }) => ({
      borderRadius: 0,
      color: ownerState.color !== 'default' ? ownerState.color : theme.palette.silverGray.main,
    }),
    sizeSmall: {
      width: 36,
      height: 36,
      fontSize: '15px',
    },
    sizeMedium: {
      width: 48,
      height: 48,
      fontSize: '18px',
    },
  },
};

export const MuiButton: Components<Theme>['MuiButton'] = {
  defaultProps: {
    variant: 'contained',
    color: 'cta',
    disableRipple: true,
    disableFocusRipple: true,
    disableTouchRipple: true,
    disableElevation: true,
  },
  variants: [],
  styleOverrides: {
    root: ({ ownerState, theme }) => ({
      zIndex: 1,
      padding: '0px 22px',
      height: 48,
      overflow: 'hidden',

      '&:before': {
        backgroundColor:
          ownerState.color === 'inherit' || ownerState.disabled
            ? 'inherit'
            : darken(theme.palette[ownerState.color || 'primary'].main, 0.15),
        width: '125%',
        height: '100%',
        content: "''",
        display: 'block',
        position: 'absolute',
        transform: 'rotate(-45deg)',
        transformOrigin: 'top right',
        transition: 'all 0.2s',
        top: '60%',
        right: 0,
        zIndex: -1,
      },

      '&:hover': {
        backgroundColor:
          ownerState.color === 'inherit' || ownerState.disabled
            ? 'inherit'
            : theme.palette[ownerState.color || 'primary'].main,
      },

      '&:hover:before': {
        transform: 'rotate(0deg)',
        top: 0,
      },

      '& $label': {
        position: 'relative',
      },
    }),

    outlined: ({ ownerState, theme }) => ({
      border: '1px solid',
      borderColor: ownerState.color,

      '&:hover': {
        backgroundColor: 'transparent',
        color:
          ownerState.color === 'inherit' || ownerState.disabled
            ? 'currentColor'
            : theme.palette[ownerState.color || 'primary'].contrastText,
      },

      '&:before': {
        backgroundColor:
          ownerState.color === 'inherit' || ownerState.disabled
            ? 'currentColor'
            : theme.palette[ownerState.color || 'primary'].main,
        opacity: 1,
        top: '70%',
        zIndex: -1,
      },
    }),

    sizeSmall: {
      padding: '0px 12px',
      height: 36,
      textTransform: 'none',
      fontSize: '0.875rem',
    },

    startIcon: {
      marginRight: '0.75em',
    },

    endIcon: {
      marginLeft: '0.75em',
    },

    iconSizeLarge: {
      '& > *:first-of-type': {
        fontSize: '22px',
      },
    },
    iconSizeMedium: {
      '& > *:first-of-type': {
        fontSize: '18px',
      },
    },
    iconSizeSmall: {
      '& > *:first-of-type': {
        fontSize: '15px',
      },
    },

    text: ({ ownerState, theme }) => ({
      '&:before': {
        content: 'none',
      },

      '&:hover': {
        backgroundColor: theme.palette.grey[50],
      },
    }),
  },
};

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    cta: true;
  }
}
