import { FormControlTypeMap } from '@mui/material';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import { useMemo } from 'react';
import { PentairTextField } from '../../../theme/components/pentair-text-field';

type SelectCountryProps = Omit<
  AutocompleteProps<CountryType, undefined, undefined, undefined>,
  'value' | 'options' | 'renderInput'
> & {
  /**
   * The title of the form dropdown
   * */
  label?: string | null;
  /**
   * The description of form dropdown. Only visible when info icon is clicked on
   * */
  description?: string | null;
  /**
   * Shows that an error has occured on the select input
   */
  error?: boolean;
  /**
   * Specify margin in the label
   */
  margin?: FormControlTypeMap['props']['margin'];
  /**
   * Provide an explanation about the country select
   */
  helperText?: string | boolean | null;
  /**
   * Form value from parent incase default is been set
   */
  value?: CountryType | string | null;
  /**
   * Make the form field compulsory or not.
   * Display an indicator to show that form is required
   */
  required?: boolean;
};

/**
 * Allow users select country using autocomplete
 * @param SelectCountryProps
 * @returns CountryType - code, label,phone
 */
export const SelectCountry = ({
  label,
  error,
  helperText,
  margin,
  description,
  value,
  required,
  ...props
}: SelectCountryProps) => {
  const _value = useMemo(() => {
    return (typeof value === 'string' ? findCountry(value) : value) || null;
  }, [value]);

  return (
    <Autocomplete
      id="country-select"
      options={countries}
      getOptionLabel={(option) => option.l}
      componentsProps={{ paper: { sx: { width: 'fit-content' } } }}
      renderOption={(props, option: CountryType) => (
        <li {...props}>
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${option.c.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.c.toLowerCase()}.png 2x`}
            alt=""
            style={{ marginRight: 8 }}
          />
          {option.l}
        </li>
      )}
      renderInput={(params: any) => (
        <PentairTextField
          {...params}
          required={required}
          label={label}
          description={description}
          error={error}
          helperText={helperText}
          margin={margin}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
      {...props}
      value={_value}
    />
  );
};

interface CountryType {
  c: string;
  l: string;
  p: string;
}

/**
 * Allow fetching of a valid country
 * @param query Country
 * @returns Valid country
 */
export const findCountry = (query: string) => {
  return countries.find((country) => country.l === query || country.c === query || country.p === query);
};

// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
export const countries: readonly CountryType[] = [
  { c: 'AD', l: 'Andorra', p: '376' },
  { c: 'AE', l: 'United Arab Emirates', p: '971' },
  { c: 'AF', l: 'Afghanistan', p: '93' },
  { c: 'AG', l: 'Antigua and Barbuda', p: '1-268' },
  { c: 'AI', l: 'Anguilla', p: '1-264' },
  { c: 'AL', l: 'Albania', p: '355' },
  { c: 'AM', l: 'Armenia', p: '374' },
  { c: 'AO', l: 'Angola', p: '244' },
  { c: 'AQ', l: 'Antarctica', p: '672' },
  { c: 'AR', l: 'Argentina', p: '54' },
  { c: 'AS', l: 'American Samoa', p: '1-684' },
  { c: 'AT', l: 'Austria', p: '43' },
  { c: 'AU', l: 'Australia', p: '61' },
  { c: 'AW', l: 'Aruba', p: '297' },
  { c: 'AX', l: 'Alland Islands', p: '358' },
  { c: 'AZ', l: 'Azerbaijan', p: '994' },
  { c: 'BA', l: 'Bosnia and Herzegovina', p: '387' },
  { c: 'BB', l: 'Barbados', p: '1-246' },
  { c: 'BD', l: 'Bangladesh', p: '880' },
  { c: 'BE', l: 'Belgium', p: '32' },
  { c: 'BF', l: 'Burkina Faso', p: '226' },
  { c: 'BG', l: 'Bulgaria', p: '359' },
  { c: 'BH', l: 'Bahrain', p: '973' },
  { c: 'BI', l: 'Burundi', p: '257' },
  { c: 'BJ', l: 'Benin', p: '229' },
  { c: 'BL', l: 'Saint Barthelemy', p: '590' },
  { c: 'BM', l: 'Bermuda', p: '1-441' },
  { c: 'BN', l: 'Brunei Darussalam', p: '673' },
  { c: 'BO', l: 'Bolivia', p: '591' },
  { c: 'BR', l: 'Brazil', p: '55' },
  { c: 'BS', l: 'Bahamas', p: '1-242' },
  { c: 'BT', l: 'Bhutan', p: '975' },
  { c: 'BV', l: 'Bouvet Island', p: '47' },
  { c: 'BW', l: 'Botswana', p: '267' },
  { c: 'BY', l: 'Belarus', p: '375' },
  { c: 'BZ', l: 'Belize', p: '501' },
  { c: 'CA', l: 'Canada', p: '1' },
  { c: 'CC', l: 'Cocos (Keeling) Islands', p: '61' },
  { c: 'CD', l: 'Congo, Democratic Republic of the', p: '243' },
  { c: 'CF', l: 'Central African Republic', p: '236' },
  { c: 'CG', l: 'Congo, Republic of the', p: '242' },
  { c: 'CH', l: 'Switzerland', p: '41' },
  { c: 'CI', l: "Cote d'Ivoire", p: '225' },
  { c: 'CK', l: 'Cook Islands', p: '682' },
  { c: 'CL', l: 'Chile', p: '56' },
  { c: 'CM', l: 'Cameroon', p: '237' },
  { c: 'CN', l: 'China', p: '86' },
  { c: 'CO', l: 'Colombia', p: '57' },
  { c: 'CR', l: 'Costa Rica', p: '506' },
  { c: 'CU', l: 'Cuba', p: '53' },
  { c: 'CV', l: 'Cape Verde', p: '238' },
  { c: 'CW', l: 'Curacao', p: '599' },
  { c: 'CX', l: 'Christmas Island', p: '61' },
  { c: 'CY', l: 'Cyprus', p: '357' },
  { c: 'CZ', l: 'Czech Republic', p: '420' },
  { c: 'DE', l: 'Germany', p: '49' },
  { c: 'DJ', l: 'Djibouti', p: '253' },
  { c: 'DK', l: 'Denmark', p: '45' },
  { c: 'DM', l: 'Dominica', p: '1-767' },
  { c: 'DO', l: 'Dominican Republic', p: '1-809' },
  { c: 'DZ', l: 'Algeria', p: '213' },
  { c: 'EC', l: 'Ecuador', p: '593' },
  { c: 'EE', l: 'Estonia', p: '372' },
  { c: 'EG', l: 'Egypt', p: '20' },
  { c: 'EH', l: 'Western Sahara', p: '212' },
  { c: 'ER', l: 'Eritrea', p: '291' },
  { c: 'ES', l: 'Spain', p: '34' },
  { c: 'ET', l: 'Ethiopia', p: '251' },
  { c: 'FI', l: 'Finland', p: '358' },
  { c: 'FJ', l: 'Fiji', p: '679' },
  { c: 'FK', l: 'Falkland Islands (Malvinas)', p: '500' },
  { c: 'FM', l: 'Micronesia, Federated States of', p: '691' },
  { c: 'FO', l: 'Faroe Islands', p: '298' },
  { c: 'FR', l: 'France', p: '33' },
  { c: 'GA', l: 'Gabon', p: '241' },
  { c: 'GB', l: 'United Kingdom', p: '44' },
  { c: 'GD', l: 'Grenada', p: '1-473' },
  { c: 'GE', l: 'Georgia', p: '995' },
  { c: 'GF', l: 'French Guiana', p: '594' },
  { c: 'GG', l: 'Guernsey', p: '44' },
  { c: 'GH', l: 'Ghana', p: '233' },
  { c: 'GI', l: 'Gibraltar', p: '350' },
  { c: 'GL', l: 'Greenland', p: '299' },
  { c: 'GM', l: 'Gambia', p: '220' },
  { c: 'GN', l: 'Guinea', p: '224' },
  { c: 'GP', l: 'Guadeloupe', p: '590' },
  { c: 'GQ', l: 'Equatorial Guinea', p: '240' },
  { c: 'GR', l: 'Greece', p: '30' },
  { c: 'GS', l: 'South Georgia and the South Sandwich Islands', p: '500' },
  { c: 'GT', l: 'Guatemala', p: '502' },
  { c: 'GU', l: 'Guam', p: '1-671' },
  { c: 'GW', l: 'Guinea-Bissau', p: '245' },
  { c: 'GY', l: 'Guyana', p: '592' },
  { c: 'HK', l: 'Hong Kong', p: '852' },
  { c: 'HM', l: 'Heard Island and McDonald Islands', p: '672' },
  { c: 'HN', l: 'Honduras', p: '504' },
  { c: 'HR', l: 'Croatia', p: '385' },
  { c: 'HT', l: 'Haiti', p: '509' },
  { c: 'HU', l: 'Hungary', p: '36' },
  { c: 'ID', l: 'Indonesia', p: '62' },
  { c: 'IE', l: 'Ireland', p: '353' },
  { c: 'IL', l: 'Israel', p: '972' },
  { c: 'IM', l: 'Isle of Man', p: '44' },
  { c: 'IN', l: 'India', p: '91' },
  { c: 'IO', l: 'British Indian Ocean Territory', p: '246' },
  { c: 'IQ', l: 'Iraq', p: '964' },
  { c: 'IR', l: 'Iran, Islamic Republic of', p: '98' },
  { c: 'IS', l: 'Iceland', p: '354' },
  { c: 'IT', l: 'Italy', p: '39' },
  { c: 'JE', l: 'Jersey', p: '44' },
  { c: 'JM', l: 'Jamaica', p: '1-876' },
  { c: 'JO', l: 'Jordan', p: '962' },
  { c: 'JP', l: 'Japan', p: '81' },
  { c: 'KE', l: 'Kenya', p: '254' },
  { c: 'KG', l: 'Kyrgyzstan', p: '996' },
  { c: 'KH', l: 'Cambodia', p: '855' },
  { c: 'KI', l: 'Kiribati', p: '686' },
  { c: 'KM', l: 'Comoros', p: '269' },
  { c: 'KN', l: 'Saint Kitts and Nevis', p: '1-869' },
  { c: 'KP', l: "Korea, Democratic People's Republic of", p: '850' },
  { c: 'KR', l: 'Korea, Republic of', p: '82' },
  { c: 'KW', l: 'Kuwait', p: '965' },
  { c: 'KY', l: 'Cayman Islands', p: '1-345' },
  { c: 'KZ', l: 'Kazakhstan', p: '7' },
  { c: 'LA', l: "Lao People's Democratic Republic", p: '856' },
  { c: 'LB', l: 'Lebanon', p: '961' },
  { c: 'LC', l: 'Saint Lucia', p: '1-758' },
  { c: 'LI', l: 'Liechtenstein', p: '423' },
  { c: 'LK', l: 'Sri Lanka', p: '94' },
  { c: 'LR', l: 'Liberia', p: '231' },
  { c: 'LS', l: 'Lesotho', p: '266' },
  { c: 'LT', l: 'Lithuania', p: '370' },
  { c: 'LU', l: 'Luxembourg', p: '352' },
  { c: 'LV', l: 'Latvia', p: '371' },
  { c: 'LY', l: 'Libya', p: '218' },
  { c: 'MA', l: 'Morocco', p: '212' },
  { c: 'MC', l: 'Monaco', p: '377' },
  { c: 'MD', l: 'Moldova, Republic of', p: '373' },
  { c: 'ME', l: 'Montenegro', p: '382' },
  { c: 'MF', l: 'Saint Martin (French part)', p: '590' },
  { c: 'MG', l: 'Madagascar', p: '261' },
  { c: 'MH', l: 'Marshall Islands', p: '692' },
  { c: 'MK', l: 'Macedonia, the Former Yugoslav Republic of', p: '389' },
  { c: 'ML', l: 'Mali', p: '223' },
  { c: 'MM', l: 'Myanmar', p: '95' },
  { c: 'MN', l: 'Mongolia', p: '976' },
  { c: 'MO', l: 'Macao', p: '853' },
  { c: 'MP', l: 'Northern Mariana Islands', p: '1-670' },
  { c: 'MQ', l: 'Martinique', p: '596' },
  { c: 'MR', l: 'Mauritania', p: '222' },
  { c: 'MS', l: 'Montserrat', p: '1-664' },
  { c: 'MT', l: 'Malta', p: '356' },
  { c: 'MU', l: 'Mauritius', p: '230' },
  { c: 'MV', l: 'Maldives', p: '960' },
  { c: 'MW', l: 'Malawi', p: '265' },
  { c: 'MX', l: 'Mexico', p: '52' },
  { c: 'MY', l: 'Malaysia', p: '60' },
  { c: 'MZ', l: 'Mozambique', p: '258' },
  { c: 'NA', l: 'Namibia', p: '264' },
  { c: 'NC', l: 'New Caledonia', p: '687' },
  { c: 'NE', l: 'Niger', p: '227' },
  { c: 'NF', l: 'Norfolk Island', p: '672' },
  { c: 'NG', l: 'Nigeria', p: '234' },
  { c: 'NI', l: 'Nicaragua', p: '505' },
  { c: 'NL', l: 'Netherlands', p: '31' },
  { c: 'NO', l: 'Norway', p: '47' },
  { c: 'NP', l: 'Nepal', p: '977' },
  { c: 'NR', l: 'Nauru', p: '674' },
  { c: 'NU', l: 'Niue', p: '683' },
  { c: 'NZ', l: 'New Zealand', p: '64' },
  { c: 'OM', l: 'Oman', p: '968' },
  { c: 'PA', l: 'Panama', p: '507' },
  { c: 'PE', l: 'Peru', p: '51' },
  { c: 'PF', l: 'French Polynesia', p: '689' },
  { c: 'PG', l: 'Papua New Guinea', p: '675' },
  { c: 'PH', l: 'Philippines', p: '63' },
  { c: 'PK', l: 'Pakistan', p: '92' },
  { c: 'PL', l: 'Poland', p: '48' },
  { c: 'PM', l: 'Saint Pierre and Miquelon', p: '508' },
  { c: 'PN', l: 'Pitcairn', p: '870' },
  { c: 'PR', l: 'Puerto Rico', p: '1' },
  { c: 'PS', l: 'Palestine, State of', p: '970' },
  { c: 'PT', l: 'Portugal', p: '351' },
  { c: 'PW', l: 'Palau', p: '680' },
  { c: 'PY', l: 'Paraguay', p: '595' },
  { c: 'QA', l: 'Qatar', p: '974' },
  { c: 'RE', l: 'Reunion', p: '262' },
  { c: 'RO', l: 'Romania', p: '40' },
  { c: 'RS', l: 'Serbia', p: '381' },
  { c: 'RU', l: 'Russian Federation', p: '7' },
  { c: 'RW', l: 'Rwanda', p: '250' },
  { c: 'SA', l: 'Saudi Arabia', p: '966' },
  { c: 'SB', l: 'Solomon Islands', p: '677' },
  { c: 'SC', l: 'Seychelles', p: '248' },
  { c: 'SD', l: 'Sudan', p: '249' },
  { c: 'SE', l: 'Sweden', p: '46' },
  { c: 'SG', l: 'Singapore', p: '65' },
  { c: 'SH', l: 'Saint Helena', p: '290' },
  { c: 'SI', l: 'Slovenia', p: '386' },
  { c: 'SJ', l: 'Svalbard and Jan Mayen', p: '47' },
  { c: 'SK', l: 'Slovakia', p: '421' },
  { c: 'SL', l: 'Sierra Leone', p: '232' },
  { c: 'SM', l: 'San Marino', p: '378' },
  { c: 'SN', l: 'Senegal', p: '221' },
  { c: 'SO', l: 'Somalia', p: '252' },
  { c: 'SR', l: 'Suriname', p: '597' },
  { c: 'SS', l: 'South Sudan', p: '211' },
  { c: 'ST', l: 'Sao Tome and Principe', p: '239' },
  { c: 'SV', l: 'El Salvador', p: '503' },
  { c: 'SX', l: 'Sint Maarten (Dutch part)', p: '1-721' },
  { c: 'SY', l: 'Syrian Arab Republic', p: '963' },
  { c: 'SZ', l: 'Swaziland', p: '268' },
  { c: 'TC', l: 'Turks and Caicos Islands', p: '1-649' },
  { c: 'TD', l: 'Chad', p: '235' },
  { c: 'TF', l: 'French Southern Territories', p: '262' },
  { c: 'TG', l: 'Togo', p: '228' },
  { c: 'TH', l: 'Thailand', p: '66' },
  { c: 'TJ', l: 'Tajikistan', p: '992' },
  { c: 'TK', l: 'Tokelau', p: '690' },
  { c: 'TL', l: 'Timor-Leste', p: '670' },
  { c: 'TM', l: 'Turkmenistan', p: '993' },
  { c: 'TN', l: 'Tunisia', p: '216' },
  { c: 'TO', l: 'Tonga', p: '676' },
  { c: 'TR', l: 'Turkey', p: '90' },
  { c: 'TT', l: 'Trinidad and Tobago', p: '1-868' },
  { c: 'TV', l: 'Tuvalu', p: '688' },
  { c: 'TW', l: 'Taiwan, Province of China', p: '886' },
  { c: 'TZ', l: 'United Republic of Tanzania', p: '255' },
  { c: 'UA', l: 'Ukraine', p: '380' },
  { c: 'UG', l: 'Uganda', p: '256' },
  { c: 'US', l: 'United States', p: '1' },
  { c: 'UY', l: 'Uruguay', p: '598' },
  { c: 'UZ', l: 'Uzbekistan', p: '998' },
  { c: 'VA', l: 'Holy See (Vatican City State)', p: '379' },
  { c: 'VC', l: 'Saint Vincent and the Grenadines', p: '1-784' },
  { c: 'VE', l: 'Venezuela', p: '58' },
  { c: 'VG', l: 'British Virgin Islands', p: '1-284' },
  { c: 'VI', l: 'US Virgin Islands', p: '1-340' },
  { c: 'VN', l: 'Vietnam', p: '84' },
  { c: 'VU', l: 'Vanuatu', p: '678' },
  { c: 'WF', l: 'Wallis and Futuna', p: '681' },
  { c: 'WS', l: 'Samoa', p: '685' },
  { c: 'XK', l: 'Kosovo', p: '383' },
  { c: 'YE', l: 'Yemen', p: '967' },
  { c: 'YT', l: 'Mayotte', p: '262' },
  { c: 'ZA', l: 'South Africa', p: '27' },
  { c: 'ZM', l: 'Zambia', p: '260' },
  { c: 'ZW', l: 'Zimbabwe', p: '263' },
];

export default SelectCountry;
