import { GetSessionCommandOutput } from '@aws-sdk/client-lex-runtime-v2';
import { Box, Menu, MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useValveAssistant } from '../valve-selector/suedmo-assistant-provider';

interface TopBarOptionsDialogProps {
  active: null | HTMLElement;
  setActive: (value: null | HTMLElement) => void;
}

type PagesURLTypes = 'bottest' | 'valve-selector' | 'valve' | 'theme-demo-page' | 'account/quotations' | 'account' | '';

// Menu bar options... the trigger is in top bar component
export const TopBarOptionsDialog: React.FC<TopBarOptionsDialogProps> = ({ active, setActive }) => {
  // const { t } = useTranslation();
  const navigate = useNavigate();
  const { locale } = useParams();
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);

  const { history, revertAssistantToHistoricalState } = useValveAssistant();

  const [screenName, setScreenName] = useState<PagesURLTypes>('');

  // Handle history for valve questions
  // const [valveQuestionHistory, setValveQuestionHistory] = useState<GetSessionCommandOutput[]>()([]);

  // Coerce the value of active that toggles the top menu bar to a boolean
  const open = Boolean(active);
  const handleClose = () => {
    setActive(null);
  };

  const handleNavigate = (page: PagesURLTypes) => {
    setActive(null);
    navigate(`/${locale || 'en'}/${page}`);
  };
  // For valve selector to go back in histor
  const handleGoBackInHistory = (index: number) => {
    revertAssistantToHistoricalState(index);
  };

  // Handle screen name
  useEffect(() => {
    const links = pathnames.slice(1, pathnames.length).map((value, index, arr) => {
      return { value, index, arr };
    });

    setScreenName(links[0]?.value as PagesURLTypes);
  }, [pathnames]);

  return (
    <Menu
      id="topbar-positioned-menu"
      aria-labelledby="topbar-positioned-menu"
      anchorEl={active}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      sx={{
        display: ['initial', 'initial', 'none'],
      }}
    >
      <MenuItem
        onClick={() => handleNavigate('')}
        sx={{ '&:hover': { color: 'background.default' }, minHeight: '36px' }}
      >
        Home
      </MenuItem>
      {screenName === 'account' ? (
        <Box>
          <MenuItem
            onClick={() => handleNavigate('account')}
            sx={{
              backgroundColor: 'secondary.main',
              color: 'background.default',
              minHeight: '36px',
            }}
          >
            My account
          </MenuItem>
        </Box>
      ) : null}

      {screenName === 'valve-selector' && history?.slice(1)?.length ? (
        <Box>
          {history?.slice(1)?.map((x: GetSessionCommandOutput, index) => {
            return (
              <MenuItem
                key={`${index + 1}. ${x?.messages?.[0]?.imageResponseCard?.title || x?.messages?.[0]?.content}`}
                onClick={() => {
                  handleGoBackInHistory(index + 1);
                  handleClose();
                }}
                sx={{ '&:hover': { color: 'background.default' }, minHeight: '36px' }}
              >
                {index + 1}. {x?.messages?.[0]?.imageResponseCard?.title || x?.messages?.[0]?.content}
              </MenuItem>
            );
          })}
        </Box>
      ) : null}
    </Menu>
  );
};
