import { Components } from '@mui/material';

export const MuiTypography: Components['MuiTypography'] = {
  defaultProps: {
    variantMapping: {
      headline_large: 'h1',
      headline_medium: 'h2',
      headline_small: 'h3',
      h1: 'h1',
      h2: 'h2',
      h3: 'h3',
      h4: 'h4',
      h5: 'h5',
      h6: 'h6',
      subtitle1: 'h3',
      subtitle2: 'h3',
      body1: 'p',
      body2: 'p',
      caption: 'p',
      overline: 'p',
      button: 'p',
      h1_condensed: 'h1',
      h2_condensed: 'h2',
      h3_condensed: 'h3',
      h4_condensed: 'h4',
      h5_condensed: 'h5',
      h6_condensed: 'h6',
      subtitle1_condensed: 'h6',
      subtitle2_condensed: 'h6',
      body1_condensed: 'p',
      body2_condensed: 'p',
      caption_condensed: 'p',
      overline_condensed: 'p',
      button_condensed: 'p',
    },
  },
};
