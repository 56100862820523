import { AboutButterflyValves } from './about-butterfly-valves';
import { AboutSingleSeatValves } from './about-single-seat-valves';
import { ComparisonGrid } from './comparison-grid';

export const InfoValveType = () => {
  return (
    <ComparisonGrid>
      <AboutSingleSeatValves />
      <AboutButterflyValves />
      {/* <AboutDoubleSeatValves /> */}
    </ComparisonGrid>
  );
};
