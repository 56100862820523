import { Box, BoxProps, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { usePriceFormatter, usePricing } from './pricing-provider';

type PriceProps = BoxProps & {
  price?: number | null;
  discountPrice?: number | null;
  showTaxingInfo?: boolean;
  showPerPiece?: boolean;
  showNet?: boolean;
};

/**
 * Utility component used to show or hide pricing for a user
 */
export const Price: React.FC<PriceProps> = ({
  price,
  discountPrice = null,
  showTaxingInfo = true,
  showPerPiece,
  showNet,
  ...props
}) => {
  const { t } = useTranslation();
  const { isUserAllowedToSeePrices } = usePricing();
  const formatPrice = usePriceFormatter();

  // If the pricing is disabled, we don't show the price
  if (!isUserAllowedToSeePrices) return null;

  const isDiscounted = Boolean(discountPrice) || discountPrice === 0;
  const isOnRequest = price === null || price === undefined;

  return (
    <>
      <Box
        color={isDiscounted ? 'text.disabled' : undefined}
        component="span"
        {...props}
        sx={{
          textDecoration: isDiscounted ? 'line-through' : undefined,
          ...props?.sx,
        }}
      >
        {formatPrice(price)}
        {!isOnRequest && showPerPiece && <span> {t('ecommerce:price.perPiece')}</span>}
        {!isOnRequest && showNet && <span> {t('ecommerce:price.net')}</span>}
      </Box>

      {isDiscounted && (
        <Box component="span" {...props}>
          {formatPrice(discountPrice)}
        </Box>
      )}
      <br />

      {!isOnRequest && showTaxingInfo && (
        <Typography variant="body2" component={'span'}>
          {t('ecommerce:price.withoutTaxesAndFees')}
        </Typography>
      )}
    </>
  );
};
