import { GraphQLResult } from '@aws-amplify/api';
import gql from 'graphql-tag';
import { EcommerceConfigurationQuery, EcommerceConfigurationQueryVariables } from '../../../../types/operations';
import { ecommerceApi } from '../../../api/api';
import { makeApiHook } from '../../../api/helpers/make-api-hook';
import { emailSettingsFragment } from './fragments';

const query = gql`
  ${emailSettingsFragment}
  query ecommerceConfiguration {
    ecommerceConfiguration {
      id
      salesforceAppId
      emailConfiguration {
        default {
          ...EmailSettingsFragment
        }
        pricingConfirmation {
          ...EmailSettingsFragment
        }
        pricingApprovalRequest {
          ...EmailSettingsFragment
        }
        quotationConfirmation {
          ...EmailSettingsFragment
        }
      }
      pricingConfiguration {
        pricingEnabled
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const fetchEcommerceConfig = async (
  variables?: EcommerceConfigurationQueryVariables
): Promise<GraphQLResult<EcommerceConfigurationQuery>> => {
  try {
    return await ecommerceApi.query<EcommerceConfigurationQuery>(query, variables);
  } catch (err: any) {
    throw err;
  }
};

export const useFetchEcommerceConfig = makeApiHook(fetchEcommerceConfig, {});
