import { TextField, TextFieldProps } from '@mui/material';
import { ReactNode } from 'react';
import InfoLabel from './info-label';

export const PentairTextField: React.FC<TextFieldProps & { label?: ReactNode; description?: string }> = ({
  label,
  description,
  ...props
}) => {
  return (
    <TextField
      aria-label={typeof label === 'string' ? label : ''}
      label={
        label && <InfoLabel title={label} description={description} error={props.error} disabled={props.disabled} />
      }
      {...props}
    />
  );
};
