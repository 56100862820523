import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogHeader, DialogHeaderProps } from '../../theme/components/dialog-header';

interface ConfirmDialogProps extends DialogHeaderProps {
  /**
   * Toggle visibility
   */
  active: boolean;
  onClose(): any;
  onConfirm(): any;
}

/**
 * Use this component to display a confirm dialog
 * @param ConfirmDialogProps
 * @returns A scroll button
 */
const ConfirmDialog: React.FC<PropsWithChildren<ConfirmDialogProps>> = (props) => {
  const { title, subtitle, children, active, onClose, onConfirm } = props;
  const { t } = useTranslation();

  return (
    <Dialog open={active} onClose={onClose} aria-labelledby="confirm-dialog">
      <DialogHeader title={title} subtitle={subtitle} onClose={onClose} />
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose} color="secondary">
          {t('cancel', { defaultValue: 'Cancel' })}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={async () => {
            await onConfirm();
            onClose();
          }}
        >
          {t('confirm', { defaultValue: 'Confirm' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ConfirmDialog;
