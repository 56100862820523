import React, { Dispatch, PropsWithChildren, SetStateAction, useState } from 'react';
import { Product, ProductCollectionItem } from '../../types/operations';
import { TopBarOptionsDialog } from './topbar-menu-options-dialog';

interface TopBarContextProps {
  topBarOptionsOpen: null | HTMLElement;
  setTopBarOptionsOpen: Dispatch<SetStateAction<null | HTMLElement>>;
  productInTopBar: Product | ProductCollectionItem | undefined;
  setProductInTopBar: Dispatch<SetStateAction<Product | ProductCollectionItem | undefined>>;
}

export const TopBarOptionsContext = React.createContext<TopBarContextProps | null>(null);

/**
 *
 * @param param0 Childen: React.FC<PropsWithChildren<unknown>>
 * @returns state setters
 * @description This component is used to toggle the account options toggle that has logout options
 */
export const TopBarOptionsProvider: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const [topBarOptionsOpen, setTopBarOptionsOpen] = useState<null | HTMLElement>(null);

  // Store products Id in here. This makes it easy to access product in any component like top bar without refetching
  const [productInTopBar, setProductInTopBar] = useState<Product | ProductCollectionItem | undefined>();

  return (
    <TopBarOptionsContext.Provider
      value={{
        topBarOptionsOpen,
        setTopBarOptionsOpen,
        productInTopBar,
        setProductInTopBar,
      }}
    >
      {children}
      <TopBarOptionsDialog active={topBarOptionsOpen} setActive={setTopBarOptionsOpen} />
    </TopBarOptionsContext.Provider>
  );
};

export default TopBarOptionsProvider;

export const useTopBarOptions = () => {
  const context = React.useContext(TopBarOptionsContext);

  if (!context) {
    throw new Error('useTopBarOptions must be used within a TopBarOptionsProvider');
  }

  return context;
};
