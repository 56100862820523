import gql from 'graphql-tag';
import { UpdateUserSettingsMutation, UpdateUserSettingsMutationVariables } from '../../../../types/operations';
import { ecommerceApi } from '../../../api/api';
import { makeApiHook } from '../../../api/helpers/make-api-hook';

import { userSettingsFragment } from './fragments';

const query = gql`
  ${userSettingsFragment}

  mutation updateUserSettings($input: InputUserSettings!) {
    updateUserSettings(input: $input) {
      ...UserSettingsFragment
    }
  }
`;

export const updateEcommerceUserSettings = async (variables: UpdateUserSettingsMutationVariables) => {
  try {
    return await ecommerceApi.query<UpdateUserSettingsMutation>(query, variables);
  } catch (err: any) {
    throw err;
  }
};

export const useUpdateEcommerceUserSettings = makeApiHook(updateEcommerceUserSettings, {});
