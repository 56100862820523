import { Typography } from '@mui/material';
import { AuthTemplate } from './auth-template';
import { ForgotPasswordForm } from './forms/forgot-password-form';
import { useTranslation } from 'react-i18next';

export const AuthStateForgotPassword = () => {
  const { t } = useTranslation();

  return (
    <AuthTemplate title="Forgot password">
      <Typography variant="body1" paragraph>
        {t('iam:forgotPasswordForm.text', {
          defaultValue: 'Please enter your email address. We will send you a code to reset your password.',
        })}
      </Typography>
      <ForgotPasswordForm />
    </AuthTemplate>
  );
};
