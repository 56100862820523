import gql from 'graphql-tag';
import { QuotationQuery, QuotationQueryVariables } from '../../../../types/operations';
import { ecommerceApi } from '../../../api/api';
import { makeApiHook } from '../../../api/helpers/make-api-hook';
import { quotationFragment } from './fragments';

const query = gql`
  ${quotationFragment}

  query quotation($id: ID!) {
    quotation(id: $id) {
      ...QuotationFragment
    }
  }
`;

export const fetchQuotationQuery = query;

export const fetchQuotation = async (variables: QuotationQueryVariables) => {
  try {
    return await ecommerceApi.query<QuotationQuery>(query, variables);
  } catch (err: any) {
    throw err;
  }
};

export const useFetchQuotation = makeApiHook(fetchQuotation, {});
