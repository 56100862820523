import { Box, BoxProps, Divider, Grid, Link, ListItem, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { DialogHeader } from '../../../theme/components/dialog-header';
import { PentairHeading } from '../../../theme/components/pentair-heading';
import { PentairBulletList, PentairOrderedList } from '../../../theme/components/unordered-list';

import hygienicClosed from './assets/hygienic-closed.png';
import hygienicClosing from './assets/hygienic-closing.png';
import hygienicOpening from './assets/hygienic-opening.png';

export const AboutHygienicValves: React.FC<BoxProps> = (props) => {
  const { t } = useTranslation();

  return (
    <Box {...props}>
      <DialogHeader
        title={t('about', { defaultValue: 'About' })}
        subtitle={t('hygienicValves', { defaultValue: 'Hygienic valves' })}
      />

      <Divider sx={{ borderBottomWidth: 3 }} />

      <Box padding={2}>
        <Trans i18nKey={'support:assistant.isHygienicOrAseptic.infoDialog.hygienic.about'}>
          <Typography variant="body2" paragraph>
            While aseptic valves aim to prevent environmental contamination, hygienic valve design focuses on
            cleanliness.
          </Typography>
          <Typography variant="body2" paragraph>
            Parts of a hygienic valve will move in and out of the process, becoming exposed to the surrounding
            environment. For instance, a rising stem on a valve will come in contact with the process and may also come
            in contact with the environment outside the process.
          </Typography>
          <Typography variant="body2">
            Hygienic valves are commonly found in{' '}
            <Link href="https://www.craneengineering.net/markets/food" target="_blank">
              food
            </Link>
            ,{' '}
            <Link href="https://www.craneengineering.net/markets/beverage" target="_blank">
              beverage
            </Link>
            , and{' '}
            <Link href={'https://www.craneengineering.net/markets/dairy'} target="_blank">
              dairy
            </Link>{' '}
            manufacturing, where cleanability (CIP) is extremely important.
          </Typography>
        </Trans>
      </Box>

      <PentairHeading contained spacing="dense">
        {t('typicalUseCases', {
          defaultValue: 'Typical use cases',
        })}
      </PentairHeading>

      <Box padding={2} display="flex" flexWrap="wrap">
        <Box flex={1} minWidth={150} marginBottom={1}>
          <PentairHeading variant="body2" gutterBottom>
            {t('bevarages', { defaultValue: 'Bevarages' })}
          </PentairHeading>
          <PentairBulletList>
            <ListItem>{t('beer', { defaultValue: 'Beer' })}</ListItem>
            <ListItem>{t('spirits', { defaultValue: 'Spirits' })}</ListItem>
            <ListItem>{t('wine', { defaultValue: 'Wine' })}</ListItem>
            <ListItem>{t('softDrinks', { defaultValue: 'Soft Drinks' })}</ListItem>
            <ListItem>{t('fruitJuices', { defaultValue: 'Fruit Juices' })}</ListItem>
            <ListItem>{t('water', { defaultValue: 'Water' })}</ListItem>
          </PentairBulletList>
        </Box>
        <Box flex={1} minWidth={150} marginBottom={1}>
          <PentairHeading variant="body2" gutterBottom>
            {t('dairyAndFood', { defaultValue: 'Dairy & Food' })}
          </PentairHeading>
          <PentairBulletList>
            <ListItem>{t('cheese', { defaultValue: 'Cheese' })}</ListItem>
            <ListItem>{t('yoghurt', { defaultValue: 'Yoghurt' })}</ListItem>
            <ListItem>{t('milk', { defaultValue: 'Milk' })}</ListItem>
            <ListItem>{t('wheyProducts', { defaultValue: 'Whey products' })}</ListItem>
          </PentairBulletList>
        </Box>
        <Box flex={1} minWidth={150}>
          <PentairHeading variant="body2" gutterBottom>
            {t('basicIngredients', { defaultValue: 'Basic ingredients' })}
          </PentairHeading>
          <PentairBulletList>
            <ListItem>{t('sirupsForSoftDrinks', { defaultValue: 'Sirups for soft drinks' })}</ListItem>
            <ListItem>{t('flavorCompounds', { defaultValue: 'Flavor compounds' })}</ListItem>
            <ListItem>{t('concentrates', { defaultValue: 'Concentrates' })}</ListItem>
          </PentairBulletList>
        </Box>
      </Box>

      <PentairHeading contained spacing="dense">
        {t('openingClosingTheValve', { defaultValue: 'Opening/closing the valve' })}
      </PentairHeading>

      <Box padding={2}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Image src={hygienicClosed} marginBottom={1} />
            <Typography variant="body2" align="center" fontWeight={600}>
              1. {t('closed', { defaultValue: 'Closed' })}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Image src={hygienicOpening} marginBottom={1} />
            <Typography variant="body2" align="center" fontWeight={600}>
              2. {t('opening', { defaultValue: 'Opening' })}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Image src={hygienicClosing} marginBottom={1} />
            <Typography variant="body2" align="center" fontWeight={600}>
              3. {t('closing', { defaultValue: 'Closing' })}
            </Typography>
          </Grid>

          <Grid item>
            <PentairOrderedList>
              <ListItem>
                {t('support:assistant.isHygienicOrAseptic.infoDialog.hygienic.descriptionValveClosed', {
                  defaultValue: 'Profile seal separates the product area and atmosphere',
                })}
              </ListItem>
              <ListItem>
                {t('support:assistant.isHygienicOrAseptic.infoDialog.hygienic.descriptionValveOpening', {
                  defaultValue:
                    'Elevator effect: When the valve is opening, product sticking onto the stem can be carried over behind the profile seal',
                })}
              </ListItem>
              <ListItem>
                {t('support:assistant.isHygienicOrAseptic.infoDialog.hygienic.descriptionValveClosing', {
                  defaultValue:
                    'Elevator effect: When the valve is closing, product contamination due to carrying over cannot be ruled out',
                })}
              </ListItem>
            </PentairOrderedList>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

const Image: React.FC<{ src: string } & BoxProps> = ({ src, ...props }) => {
  return (
    <Box
      padding={'50%'}
      {...props}
      sx={{
        backgroundImage: `url('${src}')`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        ...(props.sx || {}),
      }}
    />
  );
};
