import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, CircularProgress, Link, Typography } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { PentairTextField } from '../../../theme/components/pentair-text-field';
import { FormOptions, useFormData } from '../../form/use-formdata';
import { requiredEmail, requiredString } from '../../form/validators';
import { AuthState, useAuth } from '../auth-provider';

interface FormData {
  username: string;
  password: string;
}

/**
 * This holds the form options - Object to check for fields to validate during sign in.
 * * It will contain onsubmit function
 */
const formOptions: FormOptions<FormData> = {
  validateOnSubmit: true,
  validations: {
    username: requiredEmail,
    password: requiredString,
  },
};

/**
 * This object holds the initial state for the sign in form.
 * It will be passed to the useFormData hook as the first parameter
 */
const initialRecord: FormData = {
  username: '',
  password: '',
};

/**
 * The sign in form component. Use this component to display the sign in form.
 * This component uses the useAuth hook to sign in the user. Ensure that the AuthProvider is used in the parent component.
 * No props are required.
 *
 * @example
 * ```tsx
 * return (
 *  <AuthProvider>
 *    <SignInForm />
 *  </AuthProvider>
 * )
 * ```
 * @returns The react component that displays the sign in form.
 */
export const SignInForm: React.FC = () => {
  const { signIn, loading, setAuthState } = useAuth();
  const { t } = useTranslation();

  formOptions.onSubmit = useCallback((record: FormData) => signIn(record.username, record.password), [signIn]);
  const { change, record, validationErrors, submitForm } = useFormData<FormData>(initialRecord, formOptions);

  return (
    <form onSubmit={submitForm} style={{ display: 'flex', flexFlow: 'column' }}>
      <Box sx={{ minHeight: '161px', marginTop: 3, marginBottom: '45px' }}>
        <PentairTextField
          label={t('iam:username.label', {
            defaultValue: 'Username',
          })}
          placeholder={t('iam:username.placeholder', {
            defaultValue: 'Your email adress',
          })}
          description={t('iam:username.description', {
            defaultValue: 'This is username of your account. By default this is your email address',
          })}
          id="username"
          name="username"
          value={record.username}
          onChange={(e) => change('username', e.target.value)}
          error={!!validationErrors['username']}
          helperText={validationErrors['username']}
          disabled={loading}
          autoComplete="username"
          sx={{ marginTop: 0, marginBottom: '23px' }}
        />

        <PentairTextField
          label={t('iam:password.label', {
            defaultValue: 'Password',
          })}
          placeholder={t('iam:password.placeholder', {
            defaultValue: 'Your password',
          })}
          description={t('iam:password.description', {
            defaultValue:
              'This is the password you have received in your email. If you have forgotten your password, please contact your administrator.',
          })}
          id="password"
          name="password"
          type="password"
          value={record.password}
          onChange={(e) => change('password', e.target.value)}
          error={!!validationErrors['password']}
          helperText={validationErrors['password']}
          disabled={loading}
          autoComplete="current-password"
          sx={{ marginTop: 0, marginBottom: 0 }}
        />

        <Typography variant="body2" sx={{ marginTop: 0, marginBottom: 0 }}>
          <Link
            href="#"
            underline="hover"
            onClick={(e) => {
              e.preventDefault();
              setAuthState(AuthState.ForgotPassword);
            }}
          >
            {t('iam:buttonLabel.forgotPassword', { defaultValue: 'Forgot password?' })}
          </Link>
        </Typography>
      </Box>

      <Box display="flex" justifyContent="flex-end">
        <Button
          type="submit"
          disabled={loading}
          endIcon={loading ? <CircularProgress size={20} /> : <FontAwesomeIcon icon={faArrowRight} />}
        >
          {t('iam:buttonLabel.signIn', { defaultValue: 'Sign in' })}
        </Button>
      </Box>
    </form>
  );
};
