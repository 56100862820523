import gql from 'graphql-tag';
import {
  CreateQuotationFromCartMutation,
  CreateQuotationFromCartMutationVariables,
} from '../../../../types/operations';
import { ecommerceApi } from '../../../api/api';
import { makeApiHook } from '../../../api/helpers/make-api-hook';
import { quotationFragment } from './fragments';

const query = gql`
  ${quotationFragment}

  mutation createQuotationFromCart($input: InputQuotation!, $cartId: ID) {
    createQuotationFromCart(input: $input, cartId: $cartId) {
      ...QuotationFragment
    }
  }
`;

export const createQuotationFromCartQuery = query;

export const createQuotationFromCart = async (variables: CreateQuotationFromCartMutationVariables) => {
  try {
    return await ecommerceApi.query<CreateQuotationFromCartMutation>(query, variables);
  } catch (err: any) {
    throw err;
  }
};

export const useCreateQuotationFromCart = makeApiHook(createQuotationFromCart, {});
