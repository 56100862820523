import { errorMessages } from './error-messages';
import { FormValueTypes } from './use-validator';

export const isRequired = (value?: any) => {
  if (!value) {
    return errorMessages()['validation.required'];
  }

  return false;
};

export const isBoolean = (value?: any) => {
  if (typeof value !== 'boolean') {
    return errorMessages()['validation.invalid.boolean'];
  }
  return false;
};

export const maxCharCount = (value: FormValueTypes, max: number) => {
  if (String(value).length > max) {
    return errorMessages()['validation.max.characters'];
  }
  return false;
};

export const minCharCount = (value: FormValueTypes, min: number) => {
  if (String(value).length < min) {
    return errorMessages()['validation.min.characters'];
  }
  return false;
};

export const isIP = (value: FormValueTypes) => {
  const regex = /\b((25[0-5]|2[0-4]\d|[01]?\d\d?)(\.|$)){4}\b/;
  if (!regex.test(String(value))) {
    return errorMessages()['validation.invalid.ip'];
  }
  return false;
};

export const isSavePassword = (value: FormValueTypes) => {
  value = String(value);

  const length = value.length > 8;
  const lowercase = /[a-z]/g.test(value);
  const uppercase = /[A-Z]/g.test(value);
  const number = /\d/g.test(value);
  const char = /[~!@#$%^&*()_+?.,]/g.test(value);

  if (!(length && lowercase && uppercase && number && char)) {
    return errorMessages()['validation.invalid.password'];
  }

  return false;
};

export const isEmail = (value: FormValueTypes) => {
  const regex = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;
  if (!regex.test(String(value).toLowerCase())) {
    return errorMessages()['validation.invalid.email'];
  }

  return false;
};

export const isURL = (value: FormValueTypes) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator

  if (value && !pattern.test(value.toString())) {
    return false;
  }

  return errorMessages()['validation.invalid.url'];
};

export const isPhoneNumber = (value: FormValueTypes) => {
  const re = /^[+]\d{6,15}$/;

  if (!re.test(String(value))) {
    return errorMessages()['validation.invalid.phone_number'];
  }

  return false;
};
