import { faArrowLeft, faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, CircularProgress } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { PentairTextField } from '../../../theme/components/pentair-text-field';
import { FormOptions, useFormData } from '../../form/use-formdata';
import { requiredEmail, requiredString } from '../../form/validators';
import { AuthState, useAuth } from '../auth-provider';

interface FormData {
  username: string;
  newPassword: string;
  code: string;
  codeSent: boolean;
}

/**
 * This holds the form options - Object to check for fields to validate during forgot password actions.
 */
const formOptions: FormOptions<FormData> = {
  validateOnSubmit: true,
  validations: (record) => ({
    username: requiredEmail,
    code: record.codeSent ? requiredString : undefined,
    newPassword: record.codeSent ? requiredString : undefined,
  }),
};

/**
 * This object holds the initial state for the sign in form.
 * It will be passed to the useFormData hook as the first parameter.
 *
 */
const initialRecord: FormData = {
  username: '',
  newPassword: '',
  code: '',
  codeSent: false,
};

/**
 * The forgot password component. Use this component to display the forgot password form.
 * This component uses the useAuth hook to reset a users password.
 * No props are required.
 *
 * Essentially it works this way:
 *
 * 1. Allow user enter their registered email as their username.
 * 2. Validate the email and send a token.
 * 3. Display reset code and new password input fields
 * 4. Then reset password
 * @returns The react component that displays the forgot password form.
 */
export const ForgotPasswordForm: React.FC = () => {
  const { t } = useTranslation();
  const { locale } = useParams();
  const { loading, requestNewPassword, setAuthState, resetPassword, error } = useAuth();
  const [requestSent, setRequestSent] = useState(false);
  const [newPasswordSent, setNewPasswordSent] = useState(false);
  const requestError = !newPasswordSent && requestSent && error;
  // const resetPasswordError = newPasswordSent && error;

  formOptions.onSubmit = useCallback(
    async ({ username, code, newPassword }: FormData) => {
      if (!requestSent || requestError) {
        await requestNewPassword(username, locale);
        setRequestSent(true);
      } else {
        await resetPassword(username, code, newPassword);
        setNewPasswordSent(true);
        setAuthState(AuthState.SignedOut);
      }
    },
    [requestError, requestNewPassword, requestSent, resetPassword, setAuthState, locale]
  );

  const { change, record, validationErrors, submitForm, hasValidationErrors } = useFormData<FormData>(
    initialRecord,
    formOptions
  );

  useEffect(() => {
    change('codeSent', requestSent && !requestError);
  }, [change, requestError, requestSent]);

  return (
    <form onSubmit={submitForm} style={{ display: 'flex', flexFlow: 'column' }}>
      <PentairTextField
        label={t('iam:username.label', { defaultValue: 'Username' })}
        placeholder={t('iam:username.placeholder', { defaultValue: 'E-mail address' })}
        description={t('iam:username.description', {
          defaultValue: 'This is username of your account. By default this is your email address',
        })}
        id="username"
        name="username"
        value={record.username}
        onChange={(e) => change('username', e.target.value)}
        disabled={loading || (requestSent && !requestError)}
        error={!!validationErrors.username}
        helperText={validationErrors.username}
      />

      {requestSent && !requestError && (
        <>
          <PentairTextField
            label={t('iam:confirmationCode.label', { defaultValue: 'Code' })}
            placeholder={t('iam:confirmationCode.placeholder', { defaultValue: 'Reset code' })}
            description={t('iam:confirmationCode.description', {
              defaultValue: 'The reset code you received by email',
            })}
            id="confirmationCode"
            name="confirmationCode"
            value={record.code}
            onChange={(e) => change('code', e.target.value)}
            disabled={loading!}
            error={!!validationErrors.code}
            helperText={validationErrors.code}
          />

          <PentairTextField
            label={t('iam:newPassword.label', { defaultValue: 'New password' })}
            placeholder={t('iam:newPassword.placeholder', { defaultValue: 'Enter new password' })}
            description={t('iam:newPassword.description', {
              defaultValue: 'This is the new password you want to use',
            })}
            type="password"
            id="password"
            name="password"
            value={record.newPassword}
            onChange={(e) => change('newPassword', e.target.value)}
            disabled={loading}
            error={!!validationErrors.newPassword}
            helperText={validationErrors.newPassword}
          />
        </>
      )}

      <Box marginTop={1} display="flex">
        <Button
          variant="text"
          color="inherit"
          disabled={loading}
          sx={{ marginTop: 1 }}
          startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
          onClick={() => setAuthState(AuthState.SignedOut)}
        >
          {t('back', { defaultValue: 'Back' })}
        </Button>

        <Button
          type="submit"
          disabled={loading || hasValidationErrors}
          sx={{ marginTop: 1, marginLeft: 'auto' }}
          endIcon={loading ? <CircularProgress size={20} /> : <FontAwesomeIcon icon={faArrowRight} />}
        >
          {t('iam:buttonLabel.updatePassword', { defaultValue: 'Update password' })}
        </Button>
      </Box>
    </form>
  );
};
