import { PropsWithChildren } from 'react';
import { AuthState, useAuth } from './auth-provider';
import { AuthStateForgotPassword } from './authstate-forgot-password';
import { AuthStatePasswordResetRequired } from './authstate-password-reset';
import { AuthStateRegistering } from './authstate-register';
import { AuthStateSignedIn } from './authstate-signed-in';
import { AuthStateSignedOut } from './authstate-signed-out';
import { AuthStateUnconfirmedUser } from './authstate-unconfirmed-user';

export const AuthFlow: React.FC<PropsWithChildren<unknown>> = () => {
  const { authState } = useAuth();

  switch (authState) {
    case AuthState.SignedIn:
      return <AuthStateSignedIn />;

    case AuthState.SignedOut:
      return <AuthStateSignedOut />;

    case AuthState.ForgotPassword:
      return <AuthStateForgotPassword />;

    case AuthState.Registering:
      return <AuthStateRegistering />;

    case AuthState.UserNotConfirmed:
      return <AuthStateUnconfirmedUser />;

    case AuthState.PasswordResetRequired:
      return <AuthStatePasswordResetRequired />;

    default:
      return <div>Unknown Auth State: {authState}</div>;
  }
};
