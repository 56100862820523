import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled, Tooltip } from '@mui/material';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons';

interface OwnProps {
  title: React.ReactNode;
  description?: React.ReactNode;
  error?: boolean;
  disabled?: boolean;
}

export const InfoIcon = styled(FontAwesomeIcon, {
  shouldForwardProp(propName) {
    return !['error', 'disabled'].includes(propName.toString());
  },
})<{ error?: boolean; disabled?: boolean }>(({ theme, error, disabled }) => ({
  marginRight: theme.spacing(0.75),
  color: error ? theme.palette.error.main : disabled ? 'inherit' : theme.palette.secondary.main,
  fontSize: 14,
  marginTop: 0,
  position: 'relative',
  top: 1,
  width: 18,
  borderColor: theme.palette.secondary.main,
}));

/** Show the label icon  */
export const InfoIcons: React.FC<{ error?: boolean; disabled?: boolean; onClick?(): void }> = ({
  error,
  disabled,
  onClick,
}) => {
  return (
    <>
      {error ? (
        <InfoIcon icon={faExclamationCircle} error={Boolean(error)} disabled={Boolean(disabled)} onClick={onClick} />
      ) : (
        <InfoIcon icon={faInfoCircle} error={false} disabled={Boolean(disabled)} onClick={onClick} />
      )}
    </>
  );
};

const InfoLabelWrapper = styled('span', {
  shouldForwardProp(propName) {
    return !['error', 'disabled'].includes(propName.toString());
  },
})<{ error?: boolean; disabled?: boolean }>(({ theme, error, disabled }) => ({
  color: error ? theme.palette.error.main : 'inherit',
  pointerEvents: disabled ? 'none' : 'auto',
}));

export default function InfoLabel({ title, description, error, disabled }: OwnProps) {
  if (!description) {
    return (
      <InfoLabelWrapper error={Boolean(error)} disabled={Boolean(disabled)}>
        {title}
      </InfoLabelWrapper>
    );
  }

  return (
    <InfoLabelWrapper error={Boolean(error)} disabled={Boolean(disabled)}>
      <Tooltip title={description} arrow>
        {error ? (
          <InfoIcon icon={faExclamationCircle} error={Boolean(error)} disabled={Boolean(disabled)} />
        ) : (
          <InfoIcon icon={faInfoCircle} error={false} disabled={Boolean(disabled)} />
        )}
      </Tooltip>
      {title}
    </InfoLabelWrapper>
  );
}
