import { Auth } from '@aws-amplify/auth';
import { startTransition, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { InputQuotation } from '../../../../types/operations';
import { useAuth } from '../../../auth/auth-provider';
import { FormData, useFormData } from '../../../form/use-formdata';
import { Validators } from '../../../form/use-validator';
import { required, requiredEmail, requiredPhoneNumber, requiredString } from '../../../form/validators';
import { useValveAssistant } from '../../../valve-selector/suedmo-assistant-provider';
import { useShoppingCart } from '../../shopping-cart/hook/useShoppingcart';
import { useEcommerceSettings } from '../../user-settings/ecommerce-settings-provider';
import { useCreateQuotationFromCart } from '../api/create-from-cart';

type QuotationFormRecord = InputQuotation & { createAccount: boolean; userPassword: string; termsAccepted: boolean };
type QuotationFormData = FormData<QuotationFormRecord>;
type QuotationValidators = Validators<FormData<QuotationFormData>>;

const validations: QuotationValidators = (record) => {
  let validators: QuotationValidators = {
    projectName: requiredString,
    projectDescription: requiredString,
    'shippingInfo.organisationName': requiredString,
    'shippingInfo.firstName': requiredString,
    'shippingInfo.lastName': requiredString,
    'shippingInfo.email': requiredEmail,
    'shippingInfo.phone1': requiredPhoneNumber,
    'shippingInfo.addressLine1': requiredString,
    'shippingInfo.country': requiredString,
    'shippingInfo.postal': requiredString,
    termsAccepted: required,
  };

  if (!record.billToShippingAddress) {
    validators = {
      ...validators,
      'billingInfo.organisationName': requiredString,
      'billingInfo.firstName': requiredString,
      'billingInfo.lastName': requiredString,
      'billingInfo.email': requiredEmail,
      'billingInfo.phone1': requiredPhoneNumber,
      'billingInfo.addressLine1': requiredString,
      'billingInfo.country': requiredString,
      'billingInfo.postal': requiredString,
    };
  }

  if (record.createAccount) {
    validators = {
      ...validators,
      userPassword: requiredString,
    };
  }

  return validators;
};

const defaultFormData: QuotationFormData = {
  billToShippingAddress: true,
};

let _currentFormData: QuotationFormData = { ...defaultFormData };

export const useQuotationForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { locale } = useParams();
  const [isSubmitting, setLoading] = useState(false);

  const { resetAssistant } = useValveAssistant();
  const { settings, fetchUserSettings, updateAddress, loading: addressLoading } = useEcommerceSettings().user;
  const [currentFormData, setCurrentFormData] = useState<QuotationFormData>({ ..._currentFormData });
  const { user, isAuthenticated, updateUserAttributes, loading: userLoading } = useAuth();
  const formdata = useFormData<QuotationFormRecord>(currentFormData, { validations });
  const { record, validateRecord } = formdata;
  const [{ loading: quotationLoading }, createQuotation] = useCreateQuotationFromCart();
  const { cart, emptyCart, isLoading: cartLoading, setShoppingCartOpen } = useShoppingCart();
  const loading = isSubmitting || quotationLoading || cartLoading || addressLoading || userLoading;
  const [creationError, setCreationError] = useState<Error | null>(null);
  const [quotationCreated, setQuotationCreated] = useState(false);

  useEffect(() => {
    if (user) {
      fetchUserSettings({});
    }
  }, [user, fetchUserSettings]);

  useEffect(() => {
    const address = settings?.addresses?.[0];
    if (address) {
      setCurrentFormData((current) => {
        return {
          ...current,
          shippingInfo: {
            ...current?.shippingInfo,
            organisationName: user?.attributes['custom:organisationName'],
            postal: address.postal,
            addressLine1: address.addressLine1,
            country: address.country,
            // state: address.state,
            city: address.city,
          },
        };
      });
    }
  }, [settings?.addresses, user]);

  useEffect(() => {
    if (user) {
      setCurrentFormData((current) => {
        return {
          ...current,
          shippingInfo: {
            ...current?.shippingInfo,
            firstName: user?.attributes?.given_name,
            lastName: user?.attributes?.family_name,
            email: user?.attributes?.email,
            phone1: user?.attributes?.phone_number,
          },
          subscribeToNewsAndUpdates: user?.attributes?.['custom:newsAndUpdatesActive'] === 'true',
        };
      });
    }
  }, [user]);

  useEffect(() => {
    setCurrentFormData((current) => {
      return { ...current, shippingInfo: { ...current?.shippingInfo, locale } };
    });
  }, [locale]);

  const submitForm = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      setLoading(true);

      try {
        const cartEmpty = !cart?.items?.length;

        if (cartEmpty) {
          throw new Error(
            t('errors:not.cartEmpty', {
              defaultValue: 'Card is empty. Please add one or more products before submitting the request',
            })
          );
        }

        const _errors = validateRecord(record, true);
        const hasErrors = Object.keys(_errors).length > 0;

        if (!loading && !hasErrors) {
          const { createAccount, userPassword, ...quotationInput } = record as QuotationFormRecord;
          if (!quotationInput.termsAccepted) {
            throw new Error(
              t('errors:not.termsAccepted', { defaultValue: 'Please accept the terms in order to continue' })
            );
          }

          if (createAccount) {
            const user = await Auth.signUp({
              username: quotationInput.shippingInfo?.email,
              password: userPassword,
              attributes: {
                given_name: quotationInput.shippingInfo?.firstName,
                family_name: quotationInput.shippingInfo?.lastName,
                email: quotationInput.shippingInfo?.email,
                phone_number: quotationInput.shippingInfo?.phone1,
                locale: quotationInput.shippingInfo?.locale,
                'custom:organisationName': quotationInput.shippingInfo?.organisationName,
                'custom:newsAndUpdatesActive': quotationInput.subscribeToNewsAndUpdates ? 'true' : 'false',
                'custom:country': quotationInput.shippingInfo?.country,
              },
            });

            quotationInput.createdBy = user.userSub;
            quotationInput.updatedBy = user.userSub;
          }

          if (isAuthenticated) {
            await Promise.all([
              updateUserAttributes({
                phone: quotationInput.shippingInfo?.phone1,
                firstName: quotationInput.shippingInfo?.firstName,
                lastName: quotationInput.shippingInfo?.lastName,
                organisationName: quotationInput.shippingInfo?.organisationName,
                subscribedToNewsAndUpdates: quotationInput.subscribeToNewsAndUpdates || false,
                country: quotationInput.shippingInfo?.country,
                locale: quotationInput.shippingInfo?.locale,
              }),

              updateAddress(
                {
                  id: settings?.addresses?.[0]?.id || '',
                  organisationName: quotationInput.shippingInfo?.organisationName,
                  addressLine1: quotationInput.shippingInfo?.addressLine1,
                  postal: quotationInput.shippingInfo?.postal,
                  // state: quotationInput.shippingInfo?.state,
                  city: quotationInput.shippingInfo?.city,
                  country: quotationInput.shippingInfo?.country,
                },
                { local: true }
              ),
            ]);
          }

          const res = await createQuotation({ input: quotationInput as InputQuotation });

          if (!res.errors) {
            const id = res.data?.createQuotationFromCart?.id;
            emptyCart({ withoutConfirmation: true });
            resetAssistant().catch(console.warn);
            setQuotationCreated(true);
            setShoppingCartOpen(false);

            startTransition(() => {
              navigate(`/${locale || 'en'}/quotations/${id}/quotation-submitted`);
            });
          }
        }
      } catch (e: any) {
        setCreationError(e);
      } finally {
        setLoading(false);
      }
    },
    [
      cart?.items?.length,
      validateRecord,
      record,
      loading,
      t,
      isAuthenticated,
      createQuotation,
      setShoppingCartOpen,
      emptyCart,
      resetAssistant,
      navigate,
      locale,
      updateUserAttributes,
      updateAddress,
      settings?.addresses,
    ]
  );

  return { ...formdata, submitForm, loading, isCompleted: quotationCreated, error: creationError };
};
