import { faInfoCircle, faList, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemProps,
  ListItemText,
  ListProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { CartItemDescription } from './components/cart-item-description';

import { PentairIconDelete, TableCellDelete, TableCellEdit, TableCellInfo } from '@pentair/react-mui-5';
import { FocusEvent, useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { PentairHeading } from '../../../theme/components/pentair-heading';
import { Cart, CartItem } from '../../../types/operations';
import { usePricing } from '../../pricing/pricing-provider';
import { AddLineItemButton } from '../products/components/missing-product-button';
import { TablePriceSummaries } from '../quotations/quotation-table';
import { useShoppingCart } from './hook/useShoppingcart';
import { CartAmount, ShoppingCartItem } from './shopping-cart-item';

export const ShoppingCartItems = () => {
  const { cart, emptyCart, isEmpty, isLoading } = useShoppingCart();
  const { t } = useTranslation();

  const { isUserAllowedToSeePrices } = usePricing();

  return (
    <>
      <PentairHeading variant="h5" spacing="dense" marginTop={['0px !important', '24px !important']} icon={faList}>
        {t('ecommerce:heading.cartItems', { defaultValue: 'Cart items' })}
      </PentairHeading>
      <TableContainer
        sx={{
          display: ['none', 'none', 'flex'],
          flexDirection: 'column',
        }}
      >
        <AddLineItemButton size="small" color="primary" sx={{ alignSelf: 'end' }} />

        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t('ecommerce:tableHead.quantity', { defaultValue: 'Quantity' })}</TableCell>
              <TableCell>{t('ecommerce:tableHead.product', { defaultValue: 'Product' })}</TableCell>
              {isUserAllowedToSeePrices && (
                <TableCell sx={{ whiteSpace: 'nowrap' }}>
                  {t('ecommerce:tableHead.price', { defaultValue: 'Total NET price' })}{' '}
                </TableCell>
              )}
              <TableCellInfo disabled />
              <TableCellEdit disabled />
              <TableCellDelete disabled />
            </TableRow>
          </TableHead>
          <TableBody>{cart?.items.map((item: any) => <ShoppingCartItem key={item.id} cartItem={item} />)}</TableBody>
        </Table>
      </TableContainer>

      {/* Show list for small screens */}
      <ShoppingCartList
        sx={{
          display: ['initial', 'initial', 'none'],
        }}
        cart={cart}
      />
      {isEmpty && (
        <Box sx={{ backgroundColor: '#eeeeee', p: 2, marginTop: 1, textAlign: 'center' }}>
          <Typography>
            {t('ecommerce:emptyTable.cart', {
              defaultValue: 'The cart is empty. Select a valve to request a quotation',
            })}
          </Typography>
        </Box>
      )}

      {!isEmpty && (
        <>
          <Box sx={{ marginTop: 1.5, display: 'flex', justifyContent: 'space-between' }}>
            <Button
              onClick={() => emptyCart()}
              startIcon={<PentairIconDelete />}
              variant={'contained'}
              color={'primary'}
              size={'small'}
              disabled={isEmpty || isLoading}
            >
              {t('ecommerce:buttonLabel.emptyCart', { defaultValue: 'Empty cart' })}
            </Button>

            <TablePriceSummaries cart={cart} />
          </Box>
          <Typography variant="body2" marginTop={2}>
            {t('ecommerce:taxingDisclaimer')}
          </Typography>
        </>
      )}
    </>
  );
};

type ShoppingCartListProps = ListProps & {
  cart: Cart | undefined;
};

/**
 *
 * This is the list for mobile screens
 */
const ShoppingCartList = ({ cart, ...props }: ShoppingCartListProps) => {
  return (
    <List {...props}>
      {cart?.items.map((item: any) => <ShoppingCartListItem key={item.id} cartItem={item} />)}
      <Divider />
    </List>
  );
};

type ShoppingCartListItemType = ListItemProps & {
  cartItem: CartItem;
};

const ShoppingCartListItem = ({ cartItem }: ShoppingCartListItemType) => {
  const { id, sku } = cartItem;
  const navigate = useNavigate();
  const { locale } = useParams();

  const { removeCartItems, updateCartItem, isLoading, setShoppingCartOpen } = useShoppingCart();
  const [quantity, setQuantity] = useState(cartItem.quantity);

  const updateQuantity = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      const value = Number(e.target?.value) || 1;
      setQuantity(value);
      updateCartItem(id, { id: sku }, value);
    },
    [id, sku, updateCartItem]
  );

  return (
    <>
      <Divider sx={{ borderColor: '#DADADA' }} />
      <ListItem alignItems="flex-start" sx={{ paddingBottom: 1 }}>
        <ListItemText
          primary={
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <CartAmount
                disabled={isLoading}
                value={quantity || ''}
                onBlur={updateQuantity}
                onChange={(e) => setQuantity(Number(e.target.value))}
              />

              <Box>
                <IconButton
                  onClick={() => {
                    navigate(`/${locale}/valve/${cartItem.product?.sku}`);
                    setShoppingCartOpen(false);
                  }}
                >
                  <FontAwesomeIcon icon={faInfoCircle} />
                </IconButton>
                <IconButton onClick={() => removeCartItems([cartItem.id])}>
                  <FontAwesomeIcon icon={faTrashAlt} />
                </IconButton>
              </Box>
            </Box>
          }
          secondary={<CartItemDescription cartItem={cartItem} title={cartItem.product?.sku + ' - ' + cartItem.title} />}
        />
      </ListItem>
    </>
  );
};
