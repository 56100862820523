import { Components, lighten } from '@mui/material';
import { PentairBlue, PentairLightGray, PentairSilverGray } from '../colors';
// import { typography } from '../typography';

export const MuiMenu: Components['MuiMenu'] = {
  defaultProps: {
    elevation: 0,
  },
  styleOverrides: {
    root: {
      maxHeight: 400,
    },
    paper: {
      '&.MuiPaper-root': {
        border: '3px solid #e8e8e8',
        padding: 0,
        minWidth: '275px',
      },
    },
    list: {
      padding: 0,
      backgroundColor: '#e8e8e8',
    },
  },
};

export const MuiMenuItem: Components['MuiMenuItem'] = {
  styleOverrides: {
    root: {
      // fontSize: typography('body2').fontSize,
      fontSize: '0.875rem',
      color: PentairSilverGray[500],
      backgroundColor: '#fff',
      margin: '1px 0',
      padding: '9px 16px',

      // '&:first-child': {
      '&:first-of-type': {
        marginTop: 0,
      },
      '&:last-child': {
        marginBottom: 0,
      },

      '&:hover': {
        backgroundColor: PentairLightGray[100],
        // color: 'white',
        // '*': {
        //   color: 'white !important',
        //   fill: 'white !important',
        // },
      },

      '&.Mui-selected': {
        backgroundColor: lighten(PentairBlue[100], 0.75),
        // color: 'white',
        // '&:hover': {
        //   backgroundColor: PentairBrightBlue[700],
        // },
      },
    },
  },
};
