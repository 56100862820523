import { Button, ButtonProps } from '@mui/material';
import { PentairIconCreate, PentairIconInfo } from '@pentair/react-mui-5';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductRequestDialog } from './missing-product-request';

export const MissingProductButton: React.FC<ButtonProps> = (props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button {...props} startIcon={<PentairIconInfo />} onClick={() => setOpen(true)}>
        {t('cantFindProduct')}
      </Button>
      <ProductRequestDialog open={open} onClose={() => setOpen(false)} />
    </>
  );
};

export const AddLineItemButton: React.FC<ButtonProps> = (props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button {...props} startIcon={<PentairIconCreate />} onClick={() => setOpen(true)}>
        {t('addLineItem')}
      </Button>
      <ProductRequestDialog open={open} onClose={() => setOpen(false)} />
    </>
  );
};
