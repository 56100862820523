import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';

import { PentairHeading } from './pentair-heading';

export const SectionTitle: React.FC<{ title: string; icon?: IconProp }> = ({ title, icon }) => {
  return <PentairHeading icon={faInfoCircle}>{title}</PentairHeading>;
  // return (
  //   <Box display="flex" marginBottom={3} marginTop={3}>
  //     <Typography variant="h4">
  //       {icon && <FontAwesomeIcon icon={icon} style={{ marginRight: 16 }} fontSize={22} />}
  //       {title}
  //     </Typography>
  //   </Box>
  // );
};
