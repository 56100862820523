import { camelCase } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Product, ProductCollectionItem } from '../../../../types/operations';

export const useProductContent = () => {
  const { t } = useTranslation();

  const productAttributes: Partial<
    Record<
      keyof Product,
      {
        label: string;
        description: string;
      }
    >
  > = useMemo(
    () => ({
      class: {
        label: t('ecommerce:product.class.label'),
        description: t('ecommerce:product.class.description'),
      },
      series: {
        label: t('ecommerce:product.series.label'),
        description: t('ecommerce:product.series.description'),
      },
      family: {
        label: t('ecommerce:product.family.label'),
        description: t('ecommerce:product.family.description'),
      },
      housingType: {
        label: t('ecommerce:product.housingType.label'),
        description: t('ecommerce:product.housingType.description'),
      },
      portConfiguration: {
        label: t('ecommerce:product.portConfiguration.label'),
        description: t('ecommerce:product.portConfiguration.description'),
      },
      sealMaterial: {
        label: t('ecommerce:product.sealMaterial.label'),
        description: t('ecommerce:product.sealMaterial.description'),
      },
      sealMaterialORing: {
        label: t('ecommerce:product.sealMaterialORing.label'),
        description: t('ecommerce:product.sealMaterialORing.description'),
      },
      operationPressureLabelLaser: {
        label: t('ecommerce:product.operationPressureLabelLaser.label'),
        description: t('ecommerce:product.operationPressureLabelLaser.description'),
      },
      sizeLower: {
        label: t('ecommerce:product.sizeLower.label'),
        description: t('ecommerce:product.sizeLower.description'),
      },
      sizeUpper: {
        label: t('ecommerce:product.sizeUpper.label'),
        description: t('ecommerce:product.sizeUpper.description', {
          defaultValue: 'The pipe diameter to which the valve will be connected.',
        }),
      },
      materialInsert: {
        label: t('ecommerce:product.materialInsert.label'),
        description: t('ecommerce:product.materialInsert.description', {
          defaultValue: 'The material the valve is made of.',
        }),
      },
      actuation: {
        label: t('ecommerce:product.actuation.label'),
        description: t('ecommerce:product.actuation.description', {
          defaultValue: 'The mechanism for opening and closing a valve',
        }),
      },
      apzLb: {
        label: t('ecommerce:product.apzLb.label'),
        description: t('ecommerce:product.apzLb.description'),
      },
      amountOfSolenoids: {
        label: t('ecommerce:product.amountOfSolenoids.label'),
        description: t('ecommerce:product.amountOfSolenoids.description'),
      },
      communicationProtocol: {
        label: t('ecommerce:product.communicationProtocol.label'),
        description: t('ecommerce:product.communicationProtocol.description'),
      },
      connection: {
        label: t('ecommerce:product.connection.label'),
        description: t('ecommerce:product.connection.description'),
      },
      materialKey: {
        label: t('ecommerce:product.materialKey.label'),
        description: t('ecommerce:product.materialKey.description'),
      },
      descriptionValveInsertHousing: {
        label: t('ecommerce:product.descriptionValveInsertHousing.label'),
        description: t('ecommerce:product.descriptionValveInsertHousing.description'),
      },
      typeForLaser: {
        label: t('ecommerce:product.typeForLaser.label'),
        description: t('ecommerce:product.typeForLaser.description'),
      },
      skuInactiveStatus: {
        label: t('ecommerce:product.skuInactiveStatus.label'),
        description: t('ecommerce:product.skuInactiveStatus.description'),
      },
      mountingKitSku: {
        label: t('ecommerce:product.mountingKitSku.label'),
        description: t('ecommerce:product.mountingKitSku.description'),
      },
      mountingKitType: {
        label: t('ecommerce:product.mountingKitType.label'),
        description: t('ecommerce:product.mountingKitType.description'),
      },
      mountingKitSize: {
        label: t('ecommerce:product.mountingKitSize.label'),
        description: t('ecommerce:product.mountingKitSize.description'),
      },
      mountingKitFamilyDn25: {
        label: t('ecommerce:product.mountingKitFamilyDn25.label'),
        description: t('ecommerce:product.mountingKitFamilyDn25.description'),
      },
      mountingKitFamilyDn125: {
        label: t('ecommerce:product.mountingKitFamilyDn125.label'),
        description: t('ecommerce:product.mountingKitFamilyDn125.description'),
      },
      controlUnitSku: {
        label: t('ecommerce:product.controlUnitSku.label'),
        description: t('ecommerce:product.controlUnitSku.description'),
      },
      controlUnitClass: {
        label: t('ecommerce:product.controlUnitClass.label'),
        description: t('ecommerce:product.controlUnitClass.description'),
      },
      controlUnitSeries: {
        label: t('ecommerce:product.controlUnitSeries.label'),
        description: t('ecommerce:product.controlUnitSeries.description'),
      },
      controlUnitCommunicationProtocol: {
        label: t('ecommerce:product.controlUnitCommunicationProtocol.label'),
        description: t('ecommerce:product.controlUnitCommunicationProtocol.description'),
      },
      controlUnitConnection: {
        label: t('ecommerce:product.controlUnitConnection.label'),
        description: t('ecommerce:product.controlUnitConnection.description'),
      },
      id: {
        label: t('ecommerce:product.id.label'),
        description: t('ecommerce:product.id.description'),
      },
      productType: {
        label: t('ecommerce:product.productType.label'),
        description: '',
      },
      typeDSV: { label: t('ecommerce:product.typeDsv.label'), description: '' },
      sku: { label: t('ecommerce:product.sku.label'), description: '' },
      conform3A: { label: t('ecommerce:product.conform3A.label'), description: '' },
      actuationType: {
        label: t('ecommerce:product.actuationType.label'),
        description: '',
      },
      actuatorPressureLabelLaser: {
        label: t('ecommerce:product.actuatorPressureLabelLaser.label'),
        description: '',
      },
      additionalMarking: {
        label: t('ecommerce:product.additionalMarking.label'),
        description: '',
      },
      atexLb: { label: t('ecommerce:product.atexLb.label'), description: '' },
      atex2Lb: { label: t('ecommerce:product.atex2Lb.label'), description: '' },
      ceLabeling: { label: t('ecommerce:product.ceLabeling.label'), description: '' },
      crnLabeling: {
        label: t('ecommerce:product.crnLabeling.label'),
        description: '',
      },
      deliveryTimeKey: {
        label: t('ecommerce:product.deliveryTimeKey.label'),
        description: '',
      },

      entryDate: { label: t('ecommerce:product.entryDate.label'), description: '' },
      gostR: { label: t('ecommerce:product.gostR.label'), description: '' },

      materialNumberInsert: {
        label: t('ecommerce:product.materialNumberInsert.label'),
        description: '',
      },

      measurementProjectEMEA: {
        label: t('ecommerce:product.measurementProjectEMEA.label'),
        description: '',
      },

      positionFileActuator: {
        label: t('ecommerce:product.positionFileActuator.label'),
        description: '',
      },
      qualityClass: {
        label: t('ecommerce:product.qualityClass.label'),
        description: '',
      },
      setOfGasketsAs1Label: {
        label: t('ecommerce:product.setOfGasketsAs1Label.label'),
        description: '',
      },
      setOfGasketsPs1Label: {
        label: t('ecommerce:product.setOfGasketsPs1Label.label'),
        description: '',
      },
      surface: { label: t('ecommerce:product.surface.label'), description: '' },
      tempFileActuator: {
        label: t('ecommerce:product.tempFileActuator.label'),
        description: '',
      },
      windchillNumber1: {
        label: t('ecommerce:product.windchillNumber1.label'),
        description: '',
      },
      suffixMounting: {
        label: t('ecommerce:product.suffixMounting.label'),
        description: '',
      },
      dateOfManufacture: {
        label: t('ecommerce:product.dateOfManufacture.label'),
        description: '',
      },
      salesOrderNumber: {
        label: t('ecommerce:product.salesOrderNumber.label'),
        description: '',
      },
      serialNumber: {
        label: t('ecommerce:product.serialNumber.label'),
        description: '',
      },
      productId: {
        label: t('ecommerce:product.productId.label'),
        description: '',
      },
      productSeries: {
        label: t('ecommerce:product.productSeries.label'),
        description: '',
      },
      controlAirPressure: {
        label: t('ecommerce:product.controlAirPressure.label'),
        description: '',
      },
      material: {
        label: t('ecommerce:product.material.label'),
        description: '',
      },
      operatingPressure: {
        label: t('ecommerce:product.operatingPressure.label'),
        description: '',
      },
      sealKitActuatorSide1: {
        label: t('ecommerce:product.sealKitActuatorSide1.label'),
        description: '',
      },
      sealKitActuatorSide2: {
        label: t('ecommerce:product.sealKitActuatorSide2.label'),
        description: '',
      },
      sealKitActuatorSide3: {
        label: t('ecommerce:product.sealKitActuatorSide3.label'),
        description: '',
      },
      sealKitProductSide1: {
        label: t('ecommerce:product.sealKitProductSide1.label'),
        description: '',
      },
      sealKitProductSide2: {
        label: t('ecommerce:product.sealKitProductSide2.label'),
        description: '',
      },
      sealKitProductSide3: {
        label: t('ecommerce:product.sealKitProductSide3.label'),
        description: '',
      },
      sealKitProductSide4: {
        label: t('ecommerce:product.sealKitProductSide4.label'),
        description: '',
      },
      sealMaterial1: {
        label: t('ecommerce:product.sealMaterial1.label'),
        description: '',
      },
      sealMaterial2: {
        label: t('ecommerce:product.sealMaterial2.label'),
        description: '',
      },
      sealMaterial3: {
        label: t('ecommerce:product.exArea.label'),
        description: '',
      },
      settingsRangeOverflowValve: {
        label: t('ecommerce:product.settingsRangeOverflowValve.label'),
        description: '',
      },
      surfaceFinish: {
        label: t('ecommerce:product.surfaceFinish.label'),
        description: '',
      },
      portSizes: {
        label: t('ecommerce:product.portSizes.label'),
        description: '',
      },
      exArea: {
        label: t('ecommerce:product.exArea.label'),
        description: '',
      },
      changeNo1: { label: t('ecommerce:product.changeNo1.label'), description: '' },
      drawingNo1: { label: t('ecommerce:product.drawingNo1.label'), description: '' },
      createdAt: { label: t('ecommerce:product.createdAt.label'), description: '' },
      createdBy: { label: t('ecommerce:product.createdBy.label'), description: '' },
      updatedAt: { label: t('ecommerce:product.updatedAt.label'), description: '' },
      updatedBy: { label: t('ecommerce:product.updatedBy.label'), description: '' },
    }),
    [t]
  );

  const productDescriptionParts = useMemo(
    () => ({
      isHygienic: t('ecommerce:product.description.isHygienic'),
      isAseptic: t('ecommerce:product.description.isAseptic'),
      isTankBottom: t('ecommerce:product.description.isTankBottom'),
      isMixProof: t('ecommerce:product.description.isMixProof'),
      isManual: t('ecommerce:product.description.isManual'),
      isPneumatic: t('ecommerce:product.description.isPneumatic'),
      isChangeover: t('ecommerce:product.description.isChangeover'),
      isNC: t('ecommerce:product.description.isNC'),
      isAA: t('ecommerce:product.description.isAA'),
      isDistribution: t('ecommerce:product.description.isDistribution'),
      isMixing: t('ecommerce:product.description.isMixing'),
      isSeatliftingCleaning: t('ecommerce:product.description.isSeatliftingCleaning'),
      isExternalCleaning: t('ecommerce:product.description.isExternalCleaning'),
      isSterilization: t('ecommerce:product.description.isSterilization'),
      isPMO: t('ecommerce:product.description.isPMO'),
      isDirectConnection: t('ecommerce:product.description.isDirectConnection'),
      isSplitBody: t('ecommerce:product.description.isSplitBody'),
    }),
    [t]
  );

  const getDescriptionPart = useCallback(
    (product: Product | ProductCollectionItem, part: keyof typeof productDescriptionParts) => {
      return product[part] ? productDescriptionParts[part] : '';
    },
    [productDescriptionParts]
  );

  return {
    productDescription: useCallback(
      (product: Product | ProductCollectionItem) => {
        return [
          getDescriptionPart(product, 'isHygienic'),
          getDescriptionPart(product, 'isAseptic'),
          getDescriptionPart(product, 'isTankBottom'),
          getDescriptionPart(product, 'isMixProof'),
          getDescriptionPart(product, 'isManual'),
          getDescriptionPart(product, 'isPneumatic'),
          getDescriptionPart(product, 'isChangeover'),
          getDescriptionPart(product, 'isNC'),
          getDescriptionPart(product, 'isAA'),
          getDescriptionPart(product, 'isDistribution'),
          getDescriptionPart(product, 'isMixing'),
          getDescriptionPart(product, 'isSeatliftingCleaning'),
          getDescriptionPart(product, 'isExternalCleaning'),
          getDescriptionPart(product, 'isSterilization'),
          getDescriptionPart(product, 'isPMO'),
          getDescriptionPart(product, 'isDirectConnection'),
          getDescriptionPart(product, 'isSplitBody'),
        ]
          .filter(Boolean)
          .join(' ');
      },
      [getDescriptionPart]
    ),
    attributeInfo: useCallback(
      (key: keyof Product | string, _value?: any) => {
        let value = _value?.toString();

        return {
          label: key,
          description: '',
          value: value
            ? t(`ecommerce:productAttributesOptions.${camelCase(value)}`, {
                defaultValue: value,
              })
            : undefined,
          ...(productAttributes[key as keyof Product] || {}),
        };
      },
      [productAttributes, t]
    ),
  };
};
