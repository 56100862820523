import gql from 'graphql-tag';
import { DeleteCartItemsMutation, DeleteCartItemsMutationVariables } from '../../../../types/operations';
import { ecommerceApi } from '../../../api/api';
import { makeApiHook } from '../../../api/helpers/make-api-hook';
import { cartFragment } from './fragments';

const query = gql`
  ${cartFragment}

  mutation deleteCartItems($ids: [ID!]!, $cartId: ID) {
    deleteCartItems(ids: $ids, cartId: $cartId) {
      ...CartFragment
    }
  }
`;

export const deleteCartQuery = query;

export const deleteCartItems = async (variables: DeleteCartItemsMutationVariables) => {
  try {
    return await ecommerceApi.query<DeleteCartItemsMutation>(query, variables);
  } catch (err: any) {
    if (err instanceof Error) throw err;
    else throw new Error(err.toString());
  }
};

export const useDeleteCartItems = makeApiHook(deleteCartItems, {});
