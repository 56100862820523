import { Box, BoxProps, SxProps, Theme, Typography, TypographyProps } from '@mui/material';

interface OwnProps extends BoxProps {
  sx?: SxProps<Theme>;
  primary: string | null | undefined;
  secondary: string | null | undefined;
  titleStyle?: SxProps<Theme>;
  subTitleStyle?: SxProps<Theme>;
  variant?: TypographyProps['variant'];
}

export const PageTitles: React.FC<OwnProps> = ({
  primary,
  secondary,
  sx,
  titleStyle,
  subTitleStyle,
  variant = 'h1',
}) => {
  return (
    <Box sx={sx}>
      <Typography variant={variant} color="primary" sx={titleStyle}>
        {primary}
      </Typography>
      <Typography variant={variant} color="secondary" fontWeight={300} sx={subTitleStyle}>
        {secondary}
      </Typography>
    </Box>
  );
};
