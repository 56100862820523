import gql from 'graphql-tag';
import { ProductQuery, ProductQueryVariables } from '../../../../types/operations';
import { ecommerceApi } from '../../../api/api';
import { makeApiHook } from '../../../api/helpers/make-api-hook';
import { productCache } from './cache';
import { productControlUnitFragment, productFragment, productMountingKitFragment } from './fragments';

const query = gql`
  ${productFragment}
  ${productControlUnitFragment}
  ${productMountingKitFragment}

  query product($id: ID!, $configuration: InputProduct) {
    product(id: $id, configuration: $configuration) {
      ...ProductFragment
      ...ProductControlUnitFragment
      ...ProductMountingKitFragment
    }
  }
`;

export const fetchProductQuery = query;

export const fetchProduct = async (variables: ProductQueryVariables) => {
  if (!variables.id) {
    throw new Error('Product ID is required');
  }

  const cachedObject = productCache.get(variables.id);

  try {
    if (cachedObject && !cachedObject.__cacheState__?.isPartial) {
      return { data: { product: cachedObject.data } as ProductQuery };
    }

    const response = await ecommerceApi.query<ProductQuery>(query, variables);
    productCache.insert(response.data?.product);
    return response;
  } catch (err: any) {
    throw err;
  }
};

export const useFetchProduct = makeApiHook(fetchProduct, {});
