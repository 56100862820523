import gql from 'graphql-tag';

export const cartItemProductFragment = gql`
  fragment ProductFragmentCartItem on Product {
    id
    name
    sku
  }
`;

export const cartItemFragment = gql`
  ${cartItemProductFragment}

  fragment CartItemFragment on CartItem {
    id
    sku
    title
    description
    note
    quantity
    isMissingProductRequest
    pricing {
      unitPrice
      discountPrice
      discountPercentage
      subTotal
      total
      vat
      shippingCost
    }
    createdAt
    updatedAt
    product {
      ...ProductFragmentCartItem
      mountingKit {
        ...ProductFragmentCartItem
      }
      controlUnit {
        ...ProductFragmentCartItem
      }
    }
    configuration {
      id
      class
      series
      family
      housingType
      sizeUpper
      sealMaterial
      operationPressureLabelLaser
      materialInsert
      actuation
      apzLb
      controlUnitSeries
      amountOfSolenoids
      controlUnitCommunicationProtocol
      controlUnitConnection
    }
    details {
      key
      value
    }
  }
`;

export const cartFragment = gql`
  ${cartItemFragment}

  fragment CartFragment on Cart {
    id
    items {
      ...CartItemFragment
    }
    totalItemCount
    createdAt
    updatedAt
    pricing {
      unitPrice
      discountPrice
      discountPercentage
      subTotal
      total
      vat
      shippingCost
    }
  }
`;
