import { I18nextProvider } from 'react-i18next';
import i18next from './i18next';

import { createRoot, hydrateRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { AnalyticsProvider } from './feature/analytics/analytics-provider';

const container = document.getElementById('app');

if (container) {
  if (container?.innerHTML === '') {
    console.log('rendering');
    const root = createRoot(container!);

    root.render(
      <AnalyticsProvider initialState={{ disabled: process.env.REACT_APP_STAGE !== 'production' }}>
        <I18nextProvider i18n={i18next}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </I18nextProvider>
      </AnalyticsProvider>
    );
  } else {
    console.log('hydrating');
    hydrateRoot(
      container,
      <AnalyticsProvider initialState={{ disabled: process.env.REACT_APP_STAGE !== 'production' }}>
        <I18nextProvider i18n={i18next}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </I18nextProvider>
      </AnalyticsProvider>
    );
  }
}
