import gql from 'graphql-tag';
import { CartQuery, CartQueryVariables } from '../../../../types/operations';
import { ecommerceApi } from '../../../api/api';
import { makeApiHook } from '../../../api/helpers/make-api-hook';
import { cartFragment } from './fragments';

const query = gql`
  ${cartFragment}

  query cart($sessionId: ID) {
    cart(sessionId: $sessionId) {
      ...CartFragment
    }
  }
`;

export const fetchCartQuery = query;

export const fetchCart = async (variables: CartQueryVariables) => {
  try {
    return await ecommerceApi.query<CartQuery>(query, variables);
  } catch (err: any) {
    if (err instanceof Error) throw err;
    else throw new Error(err.toString());
  }
};

export const useFetchCart = makeApiHook(fetchCart, {});
