import { faTriangleExclamation } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Link, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { PageTitles } from '../feature/page-layout/header/page-titles';

export const PageNotFoundContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'fixed',
  flexDirection: 'column',
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  maxWidth: '600px',
  margin: 'auto',
}));

export const PageNotFound: React.FC = () => {
  const { t } = useTranslation();
  // const { locale } = useParams();

  return (
    <PageNotFoundContainer>
      <Box
        sx={{
          display: 'flex',
          flexDirection: ['column', 'row'],
          gap: [4, 3],
          justifyContent: ['center', 'left'],
          alignItems: ['center'],
          marginBottom: ['28px', '40px'],
          width: '100%',
        }}
      >
        <Box sx={{ color: 'error.main' }}>
          <FontAwesomeIcon
            icon={faTriangleExclamation}
            color="error.main"
            style={{ height: '100px', width: '100px' }}
          />
        </Box>
        <PageTitles
          primary={t('app:page404.headerOne', { defaultValue: 'Error 404' })}
          secondary={t('app:page404.headerTwo', { defaultValue: 'Resource not found' })}
          titleStyle={{ fontSize: '43px !important', textAlign: ['center', 'left'], textTransform: 'capitalize' }}
          subTitleStyle={{ fontSize: '43px !important', textAlign: ['center', 'left'], textTransform: 'capitalize' }}
          variant={'h1_condensed'}
        />
      </Box>
      <Typography sx={{ textAlign: ['center', 'left'], marginBottom: ['30px', '36px'] }}>
        {t('app:page404.infoText', {
          defaultValue:
            "We can't find the page you are looking for. It may have moved or the link could be incorrect. Please contact our support team for further assistance.",
        })}
      </Typography>
      <Typography color={'secondary'} gutterBottom sx={{ alignSelf: 'flex-start' }}>
        <strong>
          <Link href={t('support:dialog.contact.phoneNumber.link', { defaultValue: 'tel:+17635451730' })}>
            {t('support:dialog.contact.team.phoneNumber.value', { defaultValue: '+1 (763) 545-1730' })}
          </Link>
        </strong>
      </Typography>
    </PageNotFoundContainer>
  );
};

export default PageNotFound;
