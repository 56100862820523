import { faCartPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, CircularProgress, IconButton, TextField } from '@mui/material';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Product, ProductCollectionItem } from '../../../types/operations';
import { useShoppingCart } from './hook/useShoppingcart';

interface buttonProps {
  product: Product | ProductCollectionItem;
  note?: string;
  isMissingProductRequest?: boolean;
  showText?: boolean;
  disabled?: boolean;
  showQuantity?: boolean;
  onError?(error: Error | null): void;
  onComplete?(): void;
  onClick?(): void;
}

export const AddToCartButton: React.FC<buttonProps> = ({
  product,
  note,
  isMissingProductRequest,
  showText = true,
  showQuantity = true,
  disabled,
  onError,
  onClick: informParent,
}) => {
  const { isLoading, addCartItem } = useShoppingCart();
  const { t } = useTranslation();

  const [quantity, setQuantity] = useState(1);

  const onClick = useCallback(() => {
    if (disabled) return;
    onError?.(null);
    informParent?.();

    try {
      addCartItem(product, quantity, note, isMissingProductRequest);
    } catch (err: any) {
      console.error(err);
      onError?.(err);
    }
  }, [disabled, product, onError, addCartItem, informParent, quantity, note, isMissingProductRequest]);

  const icon = isLoading ? (
    <CircularProgress size={24} style={{ color: '#fff' }} />
  ) : (
    <FontAwesomeIcon icon={faCartPlus} style={{ fontSize: 18 }} color="inherit" />
  );

  const quantityField = showQuantity ? (
    <TextField
      variant="outlined"
      value={quantity}
      onChange={(e) => setQuantity(parseInt(e.target.value, 10))}
      type="number"
      defaultValue={1}
      size="small"
      margin="none"
      InputProps={{
        inputProps: {
          min: 1,
        },
        sx: {
          height: 48,
        },
      }}
      sx={{
        background: '#fff',
        flex: 1,
        maxWidth: 75,
        marginRight: '-1px',
      }}
    />
  ) : null;

  if (!showText) {
    return (
      <Box display="flex">
        {quantityField}
        <IconButton
          sx={{ marginRight: 0, backgroundColor: '#64A70B', color: 'background.default' }}
          disabled={isLoading || disabled}
          onClick={onClick}
        >
          {icon}
        </IconButton>
      </Box>
    );
  }

  return (
    <Box display="flex">
      {quantityField}
      <Button
        sx={{ marginRight: 0, backgroundColor: '#64A70B', whiteSpace: 'nowrap' }}
        disabled={isLoading || disabled}
        onClick={onClick}
        startIcon={icon}
      >
        {t('ecommerce:buttonLabel.addToCart', { defaultValue: 'Add to cart' })}
      </Button>
    </Box>
  );
};
