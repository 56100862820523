import { Box, BoxProps, Divider, ListItem, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DialogHeader } from '../../../theme/components/dialog-header';
import { PentairHeading } from '../../../theme/components/pentair-heading';
import { PentairBulletList } from '../../../theme/components/unordered-list';

const items = [
  {
    t: 'support:assistant.isMixProof.infoDialog.characteristic1',
    defaultValue: 'Mix proof separation between two different media (e.g. product and CIP-cleaning)',
  },
  {
    t: 'support:assistant.isMixProof.infoDialog.characteristic2',
    defaultValue: 'Two separate working sealing elements',
  },
  {
    t: 'support:assistant.isMixProof.infoDialog.characteristic3',
    defaultValue: 'Leakage chamber open to atmosphere',
  },
  {
    t: 'support:assistant.isMixProof.infoDialog.characteristic4',
    defaultValue: 'Pressure build-up in the valve avoided',
  },
  {
    t: 'support:assistant.isMixProof.infoDialog.characteristic5',
    defaultValue: 'Visual detection of valve leaks',
  },
  {
    t: 'support:assistant.isMixProof.infoDialog.characteristic6',
    defaultValue: 'Cleanability of all product related parts',
  },
  {
    t: 'support:assistant.isMixProof.infoDialog.characteristic7',
    defaultValue: 'CIP-distribution valve manifolds',
  },
  {
    t: 'support:assistant.isMixProof.infoDialog.characteristic8',
    defaultValue: 'Product reception manifolds for diverting to storage or buffer tanks',
  },
  {
    t: 'support:assistant.isMixProof.infoDialog.characteristic9',
    defaultValue: 'Separation between cleaning media and product',
  },
];

export const AboutMixProofValves: React.FC<BoxProps> = (props) => {
  const { t } = useTranslation();

  return (
    <Box {...props}>
      <DialogHeader
        title={t('about', { defaultValue: 'About' })}
        subtitle={t('mixProof', { defaultValue: 'Mix proof' })}
      />

      <Divider sx={{ borderBottomWidth: 3 }} />

      <Box padding={2}>
        <Typography variant="body2">
          {t('support:assistant.isMixProof.infoDialog.about', {
            defaultValue:
              "As operators want to optimize efficiency and minimize downtime, specific production zones can be in cleaning while simultaneously new product batches are in production. Because of this, it's integral to prevent unintentional mixing between two media (e.g., CIP-detergent and the product).",
          })}
        </Typography>
      </Box>

      <PentairHeading contained spacing="dense">
        {t('charactaristics', { defaultValue: 'Charactaristics' })}
      </PentairHeading>

      <Box padding={2}>
        <PentairBulletList>
          {items.map((item) => (
            <ListItem key={item.t}>{t(item.t, { defaultValue: item.defaultValue })}</ListItem>
          ))}
        </PentairBulletList>
      </Box>

      <PentairHeading contained spacing="dense">
        {t('typicalUseCases', { defaultValue: 'Typical use cases' })}
      </PentairHeading>

      <Box padding={2}>
        <Typography variant="body2">
          {t('support:assistant.isMixProof.infoDialog.useCase1', {
            defaultValue:
              'Valve manifolds for distributing products (e.g., milk, yoghurt, beer, soft drinks) to several filler lines',
          })}
        </Typography>
      </Box>

      <Divider />
    </Box>
  );
};
