import { t } from 'i18next';

export const errorMessages = () => ({
  'validation.required': t('errors:required.default', { defaultValue: 'Required' }),
  'validation.min.characters': t('errors:min.characters', {
    defaultValue: 'Minimum amount of characters not reached',
  }),
  'validation.max.characters': t('errors:max.characters', {
    defaultValue: 'Amount of characters exceded',
  }),
  'validation.invalid.ip': t('errors:invalid.ip', { defaultValue: 'Invalid IP Address' }),
  'validation.invalid.password': t('errors:invalid.password', {
    defaultValue: 'Invalid password',
  }),
  'validation.invalid.boolean': t('errors:invalid.boolean', {
    defaultValue: 'Invalid boolean value',
  }),
  'validation.invalid.email': t('errors:invalid.email', { defaultValue: 'Invalid email address' }),
  'validation.invalid.phone_number': t('errors:invalid.phone_number', {
    defaultValue: 'Invalid phone number',
  }),
  'validation.invalid.url': t('errors:invalid.url', { defaultValue: 'Invalid url' }),
});
