import gql from 'graphql-tag';
import { ProductQrInfoQuery, ProductQrInfoQueryVariables } from '../../../../types/operations';
import { ecommerceApi } from '../../../api/api';
import { makeApiHook } from '../../../api/helpers/make-api-hook';
import { productQRFragment } from './fragments';

const query = gql`
  ${productQRFragment}

  query productQRInfo($id: ID!) {
    productQRInfo(id: $id) {
      ...ProductQRFragment
    }
  }
`;

export const fetchQRInfo = async (variables: ProductQrInfoQueryVariables) => {
  if (!variables.id) {
    throw new Error('Serial Number is required');
  }

  try {
    return await ecommerceApi.query<ProductQrInfoQuery>(query, variables);
  } catch (err: any) {
    throw err;
  }
};

export const useFetchQRInfo = makeApiHook(fetchQRInfo, {});
