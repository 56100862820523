import { Box, styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { LoadingIcon } from '../../theme/components/loading-icon';

const LoadingContainer = styled(Box)({
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  inset: 0,
});

export const Loading: React.FC = () => {
  const { t } = useTranslation();
  return (
    <LoadingContainer>
      <LoadingIcon scale={1} text={t('loading')} />
    </LoadingContainer>
  );
};
