import gql from 'graphql-tag';
import { ListQuotationsQuery, ListQuotationsQueryVariables } from '../../../../types/operations';
import { ecommerceApi } from '../../../api/api';
import { makeApiHook } from '../../../api/helpers/make-api-hook';
import { listQuotationsFragment } from './fragments';
import { GraphQLResult } from '@aws-amplify/api';

const query = gql`
  ${listQuotationsFragment}
  query listQuotations($filters: [InputFilter!], $pagination: inputPaginationOptions) {
    listQuotations(filters: $filters, pagination: $pagination) {
      ...QuotationCollectionFragment
    }
  }
`;

export const listQuotationsQuery = query;

const defaultCacheOptions = {
  enabled: true,
  cacheTime: 1000 * 60 * 10, // 10 minutes
};

const cachedPromises: Record<
  string,
  {
    promise: Promise<GraphQLResult<ListQuotationsQuery>>;
    createdAt: number;
  }
> = {};

export const listQuotations = async (variables: ListQuotationsQueryVariables, cache = defaultCacheOptions) => {
  try {
    const key = JSON.stringify(variables);
    const cacheExpired = Date.now() - cachedPromises[key]?.createdAt > cache.cacheTime;

    cachedPromises[key] =
      cache.enabled && cachedPromises[key] && !cacheExpired
        ? cachedPromises[key]
        : { promise: ecommerceApi.query<ListQuotationsQuery>(query, variables), createdAt: Date.now() };

    return await cachedPromises[key].promise;
  } catch (err: any) {
    throw err;
  }
};

export const useFetchQuotationList = makeApiHook(listQuotations, {});
