import { Components, Theme } from '@mui/material';

export const MuiStepIcon: Components<Theme>['MuiStepIcon'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      width: '1.5rem',
      height: '1.5rem',
      '&.Mui-completed': {
        fill: theme.palette.secondary.main,
      },
      '&.Mui-active': {
        fill: theme.palette.green.main,
      },
    }),
  },
};
