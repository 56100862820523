import { Components, checkboxClasses, radioClasses } from '@mui/material';
import { PentairBlue, PentairBrightBlue, PentairDarkGray, PentairSilverGray } from '../colors';

export const MuiInputBase: Components['MuiInputBase'] = {
  styleOverrides: {
    root: {
      '& .MuiIconButton-root': {
        width: 'auto',
        height: 'auto',
      },
    },
  },
  //   input: {
  //     paddingTop: 8,
  //   },
  //   root: {
  //     color: PentairDarkGray[500],
  //     [`&.${inputClasses.disabled}`]: {
  //       color: PentairLightGray[500],
  //       '&::placeholder': {
  //         color: PentairDarkGray[300],
  //       },
  //     },
  //     [`&.${inputClasses.input}`]: {
  //       paddingTop: 8,
  //       '&::placeholder': {
  //         color: PentairDarkGray[300],
  //       },
  //     },
  //     [`&.${inputClasses.error}:not()`]: {
  //       color: PentairRed[500],
  //       '&::placeholder': {
  //         color: PentairRed[500],
  //       },
  //     },
  //   },
  // },
};

export const MuiTextField: Components['MuiTextField'] = {
  defaultProps: {
    variant: 'standard',
    fullWidth: true,
    margin: 'dense',
  },
  // styleOverrides: {
  //   root: {
  //     color: PentairDarkGray[500],
  //     borderStyle: 'solid',
  //   },
  // },
};

export const MuiAutocomplete: Components['MuiAutocomplete'] = {
  defaultProps: {
    ChipProps: {
      color: 'secondary',
    },
  },
  styleOverrides: {
    paper: {},

    listbox: {
      maxHeight: 320,
      border: '3px solid #e8e8e8',
      padding: 0,
      minWidth: '275px',
      '& > *': {
        padding: '19px 16px',
        fontSize: '0.875rem',
        borderBottom: '1px solid #e8e8e8',
        color: PentairSilverGray[500],
      },
    },
  },
};

export const MuiInput: Components['MuiInput'] = {
  defaultProps: {
    color: 'primary',
  },
  styleOverrides: {
    root: {
      // [`&:before, &:after`]: {
      //   borderBottomColor: '#DADADA',
      // },
      // '&:hover:not(.Mui-disabled):before': {
      //   [`&:before, &:after`]: {
      //     borderBottomColor: 'red',
      //   },
      // },
    },
    underline: {
      borderBottomColor: '#DADADA',
      '&:before': {
        borderBottomColor: '#DADADA',
      },

      '&:hover:not(.Mui-disabled, .Mui-error):before': {
        borderBottomColor: PentairBlue[500],
      },
      '&:hover:not(.Mui-disabled, .Mui-error):after': {
        borderBottomColor: PentairBlue[500],
      },
    },
  },
};

export const MuiFormLabel: Components['MuiFormLabel'] = {
  styleOverrides: {
    root: {
      textTransform: 'uppercase',
      fontWeight: 600,
      color: PentairSilverGray[500],
      fontSize: '0.75rem',
    },
  },
};

export const MuiFormControlLabel: Components['MuiFormControlLabel'] = {
  styleOverrides: {
    root: {
      fontWeight: 600,
      color: PentairSilverGray[500],
      fontSize: '0.75rem',
    },
  },
};

// export const MuiSelect: any = {
//   styleOverrides: {
//     root: {},
//   },
// };

// export const MuiFormHelperText: any = {
//   styleOverrides: {
//     root: {
//       textTransform: 'uppercase',
//       fontWeight: 600,
//       color: PentairDarkGray[500],
//       fontSize: '12px',
//     },
//   },
// };

export const MuiInputLabel: Components['MuiInputLabel'] = {
  defaultProps: {
    shrink: true,
  },
  styleOverrides: {
    root: {},
    shrink: {
      textTransform: 'uppercase',
      fontWeight: 600,
      color: PentairDarkGray[600],
      fontSize: '0.75rem',
      transform: 'translate(0, 1.5px) scale(1)',
    },
  },
};

export const MuiCheckbox: Components['MuiCheckbox'] = {
  styleOverrides: {
    root: {
      color: PentairDarkGray[200],

      [`&.${checkboxClasses.checked} svg`]: {
        fill: PentairBrightBlue[500],
      },
      '& svg': {
        fontSize: '24px',
      },
    },
  },
};

export const MuiRadio: any = {
  styleOverrides: {
    root: {
      color: PentairDarkGray[200],
      padding: '4px',
      marginLeft: '3px',
      marginRight: '5px',
      [`&.${radioClasses.checked}`]: {
        color: PentairBrightBlue[500],
      },
      '& svg': {
        fontSize: '24px',
      },
    },
  },
};
