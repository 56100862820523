import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, BoxProps, Grid, IconButton, styled, SxProps, Theme } from '@mui/material';
import { PropsWithChildren, ReactNode } from 'react';
import { useNavigate } from 'react-router';
import { PageTitles } from './page-titles';

interface PageHeaderProps extends BoxProps {
  primaryText: string | null | undefined;
  secondaryText: string | null | undefined;
  actions?: ReactNode;
  showBackButton?: boolean;
  onBackButtonClick?(): void;

  titleStyle?: SxProps<Theme>;
  subTitleStyle?: SxProps<Theme>;
}

const BackButton = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(4),
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.secondary.contrastText,
}));

BackButton.defaultProps = {
  children: <FontAwesomeIcon icon={faArrowLeft} />,
};

export const PageHeader: React.FC<PropsWithChildren<PageHeaderProps>> = ({
  primaryText,
  secondaryText,
  children,
  actions,
  showBackButton,
  onBackButtonClick,
  titleStyle,
  subTitleStyle,
  ...props
}) => {
  const navigate = useNavigate();

  return (
    <Box {...props}>
      <Grid container justifyContent={'space-between'} sx={{ paddingBottom: 2 }}>
        <Grid item display="flex" alignItems="top">
          {showBackButton && (
            <BackButton
              onClick={onBackButtonClick || (() => navigate(-1))}
              sx={{ top: [8], display: ['none', 'initial'] }}
              aria-label="back button"
            />
          )}
          <PageTitles
            primary={primaryText}
            secondary={secondaryText}
            titleStyle={titleStyle}
            subTitleStyle={subTitleStyle}
          />
        </Grid>
        <Grid item>{actions}</Grid>
      </Grid>

      {children}
    </Box>
  );
};
