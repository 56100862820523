import gql from 'graphql-tag';
import { UpdateCartItemsMutation, UpdateCartItemsMutationVariables } from '../../../../types/operations';
import { ecommerceApi } from '../../../api/api';
import { makeApiHook } from '../../../api/helpers/make-api-hook';
import { cartFragment } from './fragments';

const query = gql`
  ${cartFragment}

  mutation updateCartItems($input: [InputCartItem!]!, $cartId: ID) {
    updateCartItems(input: $input, cartId: $cartId) {
      ...CartFragment
    }
  }
`;

export const modifyCartQuery = query;

export const updateCartItems = async (variables: UpdateCartItemsMutationVariables) => {
  try {
    return await ecommerceApi.query<UpdateCartItemsMutation>(query, variables);
  } catch (err: any) {
    if (err instanceof Error) throw err;
    else throw new Error(err.toString());
  }
};

export const useUpdateCartItems = makeApiHook(updateCartItems, {});
