import { Box, BoxProps, Divider, ListItem, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DialogHeader } from '../../../theme/components/dialog-header';
import { PentairHeading } from '../../../theme/components/pentair-heading';
import { PentairBulletList, PentairMinusIconList, PentairPlusIconList } from '../../../theme/components/unordered-list';

import splitBodyHousing from './assets/split-body-housing-letters.png';

export const AboutSplitBodyHousing: React.FC<BoxProps> = ({ ...props }) => {
  const { t } = useTranslation();

  return (
    <Box maxWidth={'100px'} {...props}>
      <DialogHeader
        title={t('about', { defaultValue: 'About' })}
        subtitle={t('splitBodyHousing', { defaultValue: 'Split body housing' })}
      />
      <Divider sx={{ borderBottomWidth: 3 }} />
      <Box paddingX={2} paddingTop={2}>
        <Box display="flex" gap={2}>
          <Image src={splitBodyHousing} width="40%" alt="Split body housing" />
          <Box>
            <Typography variant="body2" paragraph>
              {t('support:aboutSplitHousing.about.paragraph1', {
                defaultValue: 'Split housing body',
              })}
            </Typography>

            <PentairBulletList>
              <ListItem>
                {t('support:aboutSplitHousing.about.bullet1', {
                  defaultValue: 'Two body parts, connected by a clamp',
                })}
              </ListItem>
              <ListItem>
                {t('support:aboutSplitHousing.about.bullet2', {
                  defaultValue: 'Optional execution',
                })}
              </ListItem>
            </PentairBulletList>
          </Box>
        </Box>
      </Box>

      <Box padding={2}>
        <PentairHeading variant="body2" paragraph>
          {t('prosAndCons', { defaultValue: "Pro's and con's" })}
        </PentairHeading>

        <PentairPlusIconList>
          <ListItem>
            {t('support:aboutSplitHousing.pros.bullet1', {
              defaultValue:
                'Flexible installation, other port configurations can be realized by turning the two body parts.',
            })}
          </ListItem>
        </PentairPlusIconList>
        <PentairMinusIconList>
          <ListItem>
            {t('support:aboutSplitHousing.cons.bullet1', {
              defaultValue:
                'Additional sealings in between the two body parts required (needs to be changed on a regular basis)',
            })}
          </ListItem>
          <ListItem>
            {t('support:aboutSplitHousing.cons.bullet2', {
              defaultValue:
                'Removable housing port connection required to change the O-Rings between the two body parts. Weld ends not recommended.',
            })}
          </ListItem>
        </PentairMinusIconList>
      </Box>
    </Box>
  );
};

const Image = styled(Box)<BoxProps<'img'>>({});
Image.defaultProps = {
  component: 'img',
};
