import { DialogContent, Alert, Box, SxProps, Theme } from '@mui/material';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogHeader } from '../../theme/components/dialog-header';
import { useAuth } from './auth-provider';

export const AuthTemplate: React.FC<PropsWithChildren<{ title?: string; containerSize?: number; dialogTitle?: string; dialogSubTitle?: string; subTitleExtraStyles?: SxProps<Theme>; contentContainerStyles?: SxProps<Theme>; containerStyles?: SxProps<Theme>; showError?: boolean; }>> = ({
  children,
  title,
  containerSize,
  dialogTitle,
  dialogSubTitle,
  subTitleExtraStyles,
  contentContainerStyles,
  containerStyles,
  showError = true

}) => {
  const { t } = useTranslation();

  const { error, setSigninDialogOpen } = useAuth();

  return (
    <Box width={'100vw'} maxWidth={containerSize || 420} sx={{ paddingBottom: 0, ...containerStyles }}>
      <DialogHeader title={dialogTitle || t('iam:buttonLabel.account', { defaultValue: "account" })} subtitle={dialogSubTitle || title || ''} onClose={() => setSigninDialogOpen(false)} subTitleExtraStyles={subTitleExtraStyles} />
      <DialogContent sx={contentContainerStyles}>
        {children}

        {showError && error && (
          <Alert severity="error" sx={{ marginTop: 2 }}>
            {error?.message}
          </Alert>
        )}
      </DialogContent>
    </Box>
  );
};
