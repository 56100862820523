import { Box, BoxProps, Divider, ListItem, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DialogHeader } from '../../../theme/components/dialog-header';
import { PentairHeading } from '../../../theme/components/pentair-heading';
import { PentairBulletList } from '../../../theme/components/unordered-list';

import seatLiftingLower from './assets/seat-lifting-lower.png';
import seatLiftingUpper from './assets/seat-lifting-upper.png';

export const AboutSeatLifting: React.FC<BoxProps> = (props) => {
  const { t } = useTranslation();

  return (
    <Box {...props}>
      <DialogHeader
        title={t('about', { defaultValue: 'About' })}
        subtitle={t('seatLifting', { defaultValue: 'Seat lifting' })}
      />
      <Divider sx={{ borderBottomWidth: 3 }} />
      <Box>
        <Box padding={2} paddingBottom={'15px'} display="flex" alignItems="flex-start">
          <Image src={seatLiftingLower} width="22%" alt="Butterfly valve" marginRight={2} />
          <Box flex={1} marginBottom={1}>
            <PentairHeading variant="body2" gutterBottom>
              {t('support:aboutSeatLifting.lower.about', { defaultValue: 'Cleaning via lower valve seat' })}
            </PentairHeading>
            <PentairBulletList>
              <ListItem>
                {t('support:aboutSeatLifting.lower.descriptionBullet1', {
                  defaultValue:
                    'Lower valve disc is raised while cleaning the lower rails (cyclic lift via predefined ring gap)',
                })}
              </ListItem>
              <ListItem>
                {t('support:aboutSeatLifting.lower.descriptionBullet2', {
                  defaultValue: 'Lower valve seat, disc seal, leakage chamber and leakage drain are cleaned',
                })}
              </ListItem>
            </PentairBulletList>
          </Box>
        </Box>
        <Box padding={2} paddingTop={'15px'} display="flex" alignItems="flex-start">
          <Image src={seatLiftingUpper} width="22%" alt="Butterfly valve" marginRight={2} />
          <Box flex={1} marginBottom={1}>
            <PentairHeading variant="body2" gutterBottom>
              {t('support:aboutSeatLifting.upper.about', { defaultValue: 'Cleaning via upper valve seat' })}
            </PentairHeading>
            <PentairBulletList>
              <ListItem>
                {t('support:aboutSeatLifting.upper.descriptionBullet1', {
                  defaultValue:
                    'Upper valve disc is raised while cleaning the upper rails (cyclic lift via predefined ring gap)',
                })}
              </ListItem>
              <ListItem>
                {t('support:aboutSeatLifting.upper.descriptionBullet2', {
                  defaultValue: 'Upper valve seat, disc seal, leakage chamber and leakage drain are cleaned',
                })}
              </ListItem>
            </PentairBulletList>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const Image = styled(Box)<BoxProps<'img'>>({});
Image.defaultProps = {
  component: 'img',
};
