import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, BoxProps, Typography, TypographyProps } from '@mui/material';

type PentairHeadingProps = {
  contained?: boolean;
  fullWidth?: boolean;
  icon?: IconProp;
  spacing?: 'none' | 'dense' | 'normal';
} & BoxProps &
  TypographyProps;

export const PentairHeading: React.FC<PentairHeadingProps> = ({
  contained,
  spacing,
  fullWidth,
  children,
  icon,
  ...props
}) => {
  if (props.paragraph || props.gutterBottom) {
    spacing = 'none';
  }

  if (spacing === 'dense') {
    if (contained) {
      props.paddingX = props.paddingX ?? 2;
      props.paddingY = props.paddingY ?? 0.66;
    } else {
      props.marginTop = props.marginTop ?? 2;
      props.marginBottom = props.marginBottom ?? 1;
    }
  }

  if (spacing === 'normal') {
    if (contained) {
      props.paddingX = props.paddingX ?? 2;
      props.paddingY = props.paddingY ?? 1;
    } else {
      props.marginTop = props.marginTop ?? 3;
      props.marginBottom = props.marginBottom ?? 2;
    }
  }

  if (contained) {
    return (
      <Box
        component={Typography}
        bgcolor={'#eee'}
        marginX={fullWidth ? -2 : undefined}
        align="center"
        color={'silverGray.main'}
        display={props.display}
        {...props}
      >
        {icon && <FontAwesomeIcon icon={icon} style={{ marginRight: 16 }} fontSize={22} />}
        {children}
      </Box>
    );
  }

  return (
    <Box
      component={Typography}
      fontWeight={600}
      color={'silverGray.main'}
      {...props}
      alignItems="center"
      display="flex"
    >
      {icon && <FontAwesomeIcon icon={icon} style={{ marginRight: '1em' }} fontSize={22} />}
      {children}
    </Box>
  );
};

PentairHeading.defaultProps = {
  variant: 'h4',
  spacing: 'normal',
};
