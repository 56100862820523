// import '@fontsource/barlow-condensed/300.css';
// import '@fontsource/barlow-condensed/400.css';
// import '@fontsource/barlow-condensed/500.css';
// import '@fontsource/barlow-condensed/600.css';
// import '@fontsource/barlow-condensed/700.css';
// import '@fontsource/barlow/300.css';
// import '@fontsource/barlow/400.css';
// import '@fontsource/barlow/500.css';
// import '@fontsource/barlow/600.css';
// import '@fontsource/barlow/700.css';

import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import * as pentairColor from './colors';
import * as MuiButtonComponents from './overrides/button';
import * as MuiCardComponents from './overrides/card';
import * as MuiDialogComponents from './overrides/dialog';
import * as MuiFormComponents from './overrides/form';
import * as MuiGenericComponents from './overrides/generic';
import * as MuiListComponents from './overrides/list';
import * as MuiMenuComponents from './overrides/menu';
import * as MuiStepperComponents from './overrides/stepper';
import * as MuiTableComponents from './overrides/table';
import * as MuiTypographyComponents from './overrides/typography';

// Create a theme instance.
const theme = createTheme({
  spacing: 12,

  palette: {
    background: {
      default: '#fff',
    },
    primary: {
      ...pentairColor.PentairBlue,
      main: pentairColor.PentairBlue['500'],
      contrastText: pentairColor.PentairWhite['500'],
    },
    secondary: {
      ...pentairColor.PentairBrightBlue,
      main: pentairColor.PentairBrightBlue['500'],
      contrastText: pentairColor.PentairWhite['500'],
    },
    cta: {
      ...pentairColor.PentairGreen,
      main: pentairColor.PentairGreen['500'],
      contrastText: pentairColor.PentairWhite['500'],
    },
    white: {
      ...pentairColor.PentairWhite,
      main: pentairColor.PentairWhite['500'],
      contrastText: pentairColor.PentairDarkGray['500'],
    },
    darkGray: {
      ...pentairColor.PentairDarkGray,
      main: pentairColor.PentairDarkGray['500'],
      contrastText: pentairColor.PentairWhite['500'],
    },
    silverGray: {
      ...pentairColor.PentairSilverGray,
      main: pentairColor.PentairSilverGray['500'],
      contrastText: pentairColor.PentairWhite['500'],
    },
    softGray: {
      ...pentairColor.PentairSoftGray,
      main: pentairColor.PentairSoftGray['500'],
      contrastText: pentairColor.PentairSilverGray['500'],
    },
    helperTextGray: {
      ...pentairColor.PentairSoftGray,
      main: pentairColor.PentairSoftGray['700'],
      contrastText: pentairColor.PentairSilverGray['500'],
    },
    lightGray: {
      ...pentairColor.PentairLightGray,
      main: pentairColor.PentairLightGray['200'],
      contrastText: pentairColor.PentairDarkGray['500'],
    },
    darkGold: {
      ...pentairColor.PentairDarkGold,
      main: pentairColor.PentairDarkGold['500'],
      contrastText: pentairColor.PentairWhite['500'],
    },
    error: {
      ...pentairColor.PentairRed,
      main: pentairColor.PentairRed['500'],
      contrastText: pentairColor.PentairWhite['500'],
    },
    impactGreen: {
      ...pentairColor.PentairImpactGreen,
      main: pentairColor.PentairImpactGreen['500'],
      contrastText: pentairColor.PentairBlue['500'],
    },
    seafoamGreen: {
      ...pentairColor.PentairSeafoamGreen,
      main: pentairColor.PentairSeafoamGreen['500'],
      contrastText: pentairColor.PentairWhite['500'],
    },
    green: {
      ...pentairColor.PentairGreen,
      main: pentairColor.PentairGreen['500'],
      contrastText: pentairColor.PentairWhite['500'],
    },
    brightBlue: {
      ...pentairColor.PentairBrightBlue,
      main: pentairColor.PentairBrightBlue['500'],
      contrastText: pentairColor.PentairWhite['500'],
    },
    blue: {
      ...pentairColor.PentairBlue,
      main: pentairColor.PentairBlue['500'],
      contrastText: pentairColor.PentairWhite['500'],
    },
    khaki: {
      ...pentairColor.PentairKhaki,
      main: pentairColor.PentairKhaki['500'],
      contrastText: pentairColor.PentairWhite['500'],
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1700,
    },
  },
  typography: {
    fontFamily: 'Barlow',
    fontSize: 16,
    fontWeightBold: 600,
    htmlFontSize: 16,

    allVariants: {},

    body1: {
      fontSize: 16,
      color: pentairColor.PentairDarkGray[500],
      lineHeight: 1.3,
    },
    body2: {
      fontSize: 14,
      color: pentairColor.PentairDarkGray[500],
      lineHeight: 1.3,
    },
    headline_large: {
      fontFamily: "'Barlow Condensed', sans-serif",
      fontWeight: 500,
      fontSize: 70,
      lineHeight: 1,
      color: pentairColor.PentairBlue['500'],
      textTransform: 'uppercase',
    },

    headline_medium: {
      fontFamily: "'Barlow Condensed', sans-serif",
      fontWeight: 500,
      fontSize: 48,
      lineHeight: 1,
      color: pentairColor.PentairBlue['500'],
    },

    headline_small: {
      fontFamily: "'Barlow Condensed', sans-serif",
      fontWeight: 500,
      fontSize: 30,
      lineHeight: 1.2,
      color: pentairColor.PentairBlue['500'],
    },

    h1: {
      fontFamily: "'Barlow', sans-serif",
      fontWeight: 700,
      fontSize: 30,
      lineHeight: 1.2,
      textTransform: 'uppercase',
      color: pentairColor.PentairBlue['500'],
    },
    h2: {
      fontFamily: "'Barlow', sans-serif",
      textTransform: 'uppercase',
      fontWeight: 600,
      fontSize: 25,
      lineHeight: 1,
      color: pentairColor.PentairSilverGray['500'],
    },
    h3: {
      fontFamily: "'Barlow', sans-serif",
      fontWeight: 600,
      textTransform: 'uppercase',
      fontSize: 20,
      lineHeight: 1,
      color: pentairColor.PentairSilverGray['500'],
    },
    h4: {
      fontFamily: "'Barlow', sans-serif",
      fontWeight: 600,
      textTransform: 'uppercase',
      fontSize: 16,
      lineHeight: 1,
      color: pentairColor.PentairSilverGray['500'],
    },
    h5: {
      fontWeight: 600,
      fontFamily: "'Barlow', sans-serif",
      textTransform: 'uppercase',
      fontSize: 14,
      lineHeight: 1,
      color: pentairColor.PentairSilverGray['500'],
    },
    h6: {
      fontFamily: "'Barlow', sans-serif",
      textTransform: 'uppercase',
      fontWeight: 600,
      fontSize: 12,
      lineHeight: 1,
      color: pentairColor.PentairSilverGray['500'],
    },
    subtitle1: {
      fontSize: 22,
      lineHeight: 1.2,
      fontWeight: 400,
    },
    subtitle2: {
      fontSize: 18,
      lineHeight: 1.2,
      fontWeight: 400,
    },
    caption: {},
    overline: {},

    button: { fontWeight: 600, fontSize: 14 },

    // Condensed variants
    body1_condensed: {
      fontSize: 18,
      fontFamily: "'Barlow Condensed', sans-serif",
      lineHeight: 1.3,
    },
    body2_condensed: {
      fontSize: 16,
      fontFamily: "'Barlow Condensed', sans-serif",
      lineHeight: 1.3,
    },
    h1_condensed: {
      fontFamily: "'Barlow Condensed', sans-serif",
      fontWeight: 500,
      fontSize: 34,
      lineHeight: 1.2,
      color: pentairColor.PentairBlue['500'],
    },
    h2_condensed: {
      fontFamily: "'Barlow Condensed', sans-serif",
      fontWeight: 500,
      fontSize: 28,
      lineHeight: 1.2,
      color: pentairColor.PentairSilverGray['500'],
    },
    h3_condensed: {
      fontFamily: "'Barlow Condensed', sans-serif",
      fontWeight: 500,
      fontSize: 24,
      lineHeight: 1.2,
      color: pentairColor.PentairSilverGray['500'],
    },
    h4_condensed: {
      fontFamily: "'Barlow Condensed', sans-serif",
      fontWeight: 500,
      fontSize: 20,
      lineHeight: 1.2,
      color: pentairColor.PentairSilverGray['500'],
    },
    h5_condensed: {
      fontFamily: "'Barlow Condensed', sans-serif",
      fontWeight: 500,
      fontSize: 18,
      lineHeight: 1.2,
      color: pentairColor.PentairSilverGray['500'],
    },
    h6_condensed: {
      fontFamily: "'Barlow Condensed', sans-serif",
      fontWeight: 500,
      fontSize: 16,
      lineHeight: 1.2,
      color: pentairColor.PentairSilverGray['500'],
    },
    subtitle1_condensed: {
      fontFamily: "'Barlow Condensed', sans-serif",
      fontSize: 24,
      lineHeight: 1.2,
    },
    subtitle2_condensed: {
      fontFamily: "'Barlow Condensed', sans-serif",
      fontSize: 20,
      lineHeight: 1.2,
    },
    caption_condensed: {
      fontFamily: "'Barlow Condensed', sans-serif",
    },
    overline_condensed: {
      fontFamily: "'Barlow Condensed', sans-serif",
    },
    button_condensed: { fontFamily: "'Barlow Condensed', sans-serif" },
  },

  shape: {
    borderRadius: 0,
  },

  components: {
    ...MuiTypographyComponents,
    ...MuiButtonComponents,
    ...MuiGenericComponents,
    ...MuiFormComponents,
    ...MuiTableComponents,
    ...MuiListComponents,
    ...MuiDialogComponents,
    ...MuiMenuComponents,
    ...MuiStepperComponents,
    ...MuiCardComponents,
  },
});

export const pentairTheme = responsiveFontSizes(theme, {
  variants: [
    'headline_large',
    'headline_medium',
    'headline_small',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'subtitle1',
    'subtitle2',
    'h1_condensed',
    'h2_condensed',
    'h3_condensed',
    'h4_condensed',
    'h5_condensed',
    'h6_condensed',
    'subtitle1_condensed',
    'subtitle2_condensed',
  ],
});
