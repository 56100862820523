import React, { Dispatch, PropsWithChildren, SetStateAction, useState } from 'react';
import { SupportDialog } from './support-dialog';

interface SupportContextProps {
  supportDialogOpen: boolean;
  setSupportDialogOpen: Dispatch<SetStateAction<boolean>>;
}

export const SupportContext = React.createContext<SupportContextProps | null>(null);

export const SupportProvider: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const [supportDialogOpen, setSupportDialogOpen] = useState(false);

  return (
    <SupportContext.Provider
      value={{
        supportDialogOpen,
        setSupportDialogOpen,
      }}
    >
      {children}
      <SupportDialog active={supportDialogOpen} setActive={setSupportDialogOpen} />
    </SupportContext.Provider>
  );
};

export default SupportProvider;

export const useSupport = () => {
  const context = React.useContext(SupportContext);

  if (!context) {
    throw new Error('useSupport must be used within a SupportProvider');
  }

  return context;
};
