import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonProps, Divider } from '@mui/material';
import { Box } from '@mui/system';

interface ThemeDemopageButtonsProps {
  variant: ButtonProps['variant'];
}

const ButtonSet = ({
  variant,
  color,
  size,
  disabled,
  startIcon,
  endIcon,
}: {
  variant?: ButtonProps['variant'];
  color?: ButtonProps['color'];
  size?: ButtonProps['size'];
  disabled?: boolean;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
}) => {
  return (
    <Box>
      <Box sx={{ marginBottom: 2 }}>
        <Button variant={variant} color={color} size={size} sx={{ marginRight: 1 }} disabled={disabled}>
          Button {variant} primary
        </Button>

        <Button
          variant={variant}
          color={color}
          size={size}
          sx={{ marginRight: 1 }}
          disabled={disabled}
          startIcon={<FontAwesomeIcon icon={faQuestionCircle} />}
        >
          Button {variant} primary
        </Button>

        <Button
          variant={variant}
          color={color}
          size={size}
          sx={{ marginRight: 1 }}
          disabled={disabled}
          endIcon={<FontAwesomeIcon icon={faQuestionCircle} />}
        >
          Button {variant} primary
        </Button>
      </Box>
    </Box>
  );
};

export const ThemeDemopageButtons: React.FC<ThemeDemopageButtonsProps> = ({ variant }) => {
  return (
    <Box>
      <ButtonSet size={'large'} variant={variant} color="cta" />
      <ButtonSet size={'medium'} variant={variant} color="cta" />
      <ButtonSet size={'small'} variant={variant} color="cta" />

      <Divider sx={{ marginY: 3 }} />

      <ButtonSet size={'large'} variant={variant} color="primary" />
      <ButtonSet size={'medium'} variant={variant} color="primary" />
      <ButtonSet size={'small'} variant={variant} color="primary" />

      <Divider sx={{ marginY: 3 }} />

      <ButtonSet size={'large'} variant={variant} color="secondary" />
      <ButtonSet size={'medium'} variant={variant} color="secondary" />
      <ButtonSet size={'small'} variant={variant} color="secondary" />

      <Divider sx={{ marginY: 3 }} />

      <ButtonSet size={'large'} variant={variant} color="error" />
      <ButtonSet size={'medium'} variant={variant} color="error" />
      <ButtonSet size={'small'} variant={variant} color="error" />

      {/* <Divider sx={{ marginY: 3 }} /> */}

      {/* <ButtonSet size={'large'} variant={variant} color="info" />
      <ButtonSet size={'medium'} variant={variant} color="info" />
      <ButtonSet size={'small'} variant={variant} color="info" /> */}

      {/* <Divider sx={{ marginY: 3 }} /> */}

      {/* <ButtonSet size={'large'} variant={variant} color="success" />
      <ButtonSet size={'medium'} variant={variant} color="success" />
      <ButtonSet size={'small'} variant={variant} color="success" /> */}

      {/* <Divider sx={{ marginY: 3 }} /> */}

      {/* <ButtonSet size={'large'} variant={variant} color="warning" />
      <ButtonSet size={'medium'} variant={variant} color="warning" />
      <ButtonSet size={'small'} variant={variant} color="warning" /> */}

      <Divider sx={{ marginY: 3 }} />

      <ButtonSet size={'large'} variant={variant} disabled />
      <ButtonSet size={'medium'} variant={variant} disabled />
      <ButtonSet size={'small'} variant={variant} disabled />
    </Box>
  );
};
