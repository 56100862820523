import { Box, BoxProps, Divider, ListItem, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DialogHeader } from '../../../theme/components/dialog-header';
import { PentairHeading } from '../../../theme/components/pentair-heading';
import { PentairBulletList } from '../../../theme/components/unordered-list';

import butterflyValve from './assets/butterfly-valve.png';

export const AboutButterflyValves: React.FC<BoxProps> = (props) => {
  const { t } = useTranslation();

  return (
    <Box {...props}>
      <DialogHeader
        title={t('about', { defaultValue: 'About' })}
        subtitle={t('butterflyValves', { defaultValue: 'Butterfly valves' })}
      />

      <Divider sx={{ borderBottomWidth: 3 }} />

      <Box padding={2} display="flex" alignItems="flex-start">
        <Image src={butterflyValve} width="22%" alt="Butterfly valve" marginRight={2} />
        <PentairBulletList flex={3}>
          <ListItem>
            {t('support:aboutDoubleSeatValves.descriptionBullet1', {
              defaultValue: 'Cost-effective valve solution',
            })}
          </ListItem>
          <ListItem>
            {t('support:aboutDoubleSeatValves.descriptionBullet2', {
              defaultValue: 'Space- saving shut-off valve',
            })}
          </ListItem>
          <ListItem>
            {t('support:aboutDoubleSeatValves.descriptionBullet3', {
              defaultValue: 'Various housing connections available',
            })}
          </ListItem>
          <ListItem>
            {t('support:aboutDoubleSeatValves.descriptionBullet4', {
              defaultValue: 'Wide range of different seal materials',
            })}
          </ListItem>
          <ListItem>
            {t('support:aboutDoubleSeatValves.descriptionBullet5', {
              defaultValue: 'Common executions are available from stock',
            })}
          </ListItem>
        </PentairBulletList>
      </Box>

      <PentairHeading contained spacing="dense">
        {t('workingMode', {
          defaultValue: 'Working mode',
        })}
      </PentairHeading>

      <Box padding={2} display="flex" flexWrap="wrap">
        <PentairBulletList>
          <ListItem>
            {t('support:aboutDoubleSeatValves.workingMode1', {
              defaultValue: 'Simple shut-off valve',
            })}
          </ListItem>
          <ListItem>
            {t('support:aboutDoubleSeatValves.workingMode2', {
              defaultValue: 'Sampling from circuit loops',
            })}
          </ListItem>
          <ListItem>
            {t('support:aboutDoubleSeatValves.workingMode3', {
              defaultValue: 'Bottom seat valves to empty or filling the tank',
            })}
          </ListItem>
          <ListItem>
            {t('support:aboutDoubleSeatValves.workingMode4', {
              defaultValue: 'Routing or 2/3-way valves (Change over valves)',
            })}
          </ListItem>
        </PentairBulletList>
      </Box>

      <PentairHeading contained spacing="dense">
        {t('typicalUseCases', { defaultValue: 'Typical use cases' })}
      </PentairHeading>

      <Box padding={2} flexWrap="wrap">
        <Typography paragraph variant="body2">
          {t('support:aboutDoubleSeatValves.typicalUseCaseParagraph1', {
            defaultValue: 'Shutting off product flows at for example:',
          })}
        </Typography>
        <PentairBulletList>
          <ListItem>
            {t('support:aboutDoubleSeatValves.typicalUseCase1', {
              defaultValue: 'Distribution panels',
            })}
          </ListItem>
          <ListItem>
            {t('support:aboutDoubleSeatValves.typicalUseCase2', {
              defaultValue: 'Receiving stations',
            })}
          </ListItem>
          <ListItem>
            {t('support:aboutDoubleSeatValves.typicalUseCase3', {
              defaultValue: 'Containers',
            })}
          </ListItem>
        </PentairBulletList>
      </Box>
    </Box>
  );
};

const Image = styled(Box)<BoxProps<'img'>>({});
Image.defaultProps = {
  component: 'img',
};
