import gql from 'graphql-tag';
import { UserSettingsQuery, UserSettingsQueryVariables } from '../../../../types/operations';
import { ecommerceApi } from '../../../api/api';
import { makeApiHook } from '../../../api/helpers/make-api-hook';
import { userSettingsFragment } from './fragments';

const query = gql`
  ${userSettingsFragment}

  query userSettings {
    userSettings {
      ...UserSettingsFragment
    }
  }
`;

export const userSettingsQuery = query;

export const fetchEcommerceUserSettings = async (variables: UserSettingsQueryVariables) => {
  try {
    return await ecommerceApi.query<UserSettingsQuery>(query, variables);
  } catch (err: any) {
    throw err;
  }
};

export const useFetchEcommerceUserSettings = makeApiHook(fetchEcommerceUserSettings, {});
