import { cloneDeep, set } from 'lodash';
import { useCallback, useState } from 'react';

export const useChangeManager = <TRecord extends Record<string, any>>() => {
  const [changes, setChanges] = useState<Partial<TRecord>>({});

  const change = useCallback((keyOrObject: keyof TRecord | string | Partial<TRecord>, value?: any) => {
    if (typeof keyOrObject === 'object') {
      setChanges((_changes) => ({ ..._changes, ...keyOrObject }));
    } else {
      setChanges((_changes) => {
        const newChanges = cloneDeep(_changes);
        set(newChanges, keyOrObject, value);
        return newChanges;
      });
    }
  }, []);

  const resetChanges = () => {
    setChanges({});
  };

  return { changes, change, resetChanges };
};
