import { faClipboardListCheck } from '@fortawesome/pro-light-svg-icons';
import { Box, styled } from '@mui/material';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PentairHeading } from '../../../theme/components/pentair-heading';
import { useAuth } from '../../auth/auth-provider';
import { SignInForm } from '../../auth/forms/sign-in-form';
import { LoginOptions } from './login-options';
import { QuotationForm } from './quotation-form';

interface OwnProps {}

const AsideContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingLeft: theme.spacing(2),
  flex: 1,
  transition: 'max-width 0.3s',

  [theme.breakpoints.down('sm')]: {
    paddingLeft: 0,
  },
}));

export const RequestQuotation: React.FC<OwnProps> = () => {
  const { t } = useTranslation();
  const { isAuthenticated } = useAuth();
  const [asGuest, setAsGuest] = useState<boolean | undefined>(undefined);

  const onLoginClick = useCallback(() => {
    setAsGuest(false);
  }, []);

  const onGuestClick = useCallback(() => {
    setAsGuest(true);
  }, []);

  return (
    <AsideContainer maxWidth={asGuest || isAuthenticated ? '550px' : '340px'}>
      <PentairHeading variant="h5" spacing="dense" icon={faClipboardListCheck}>
        {t('ecommerce:heading.requestQuotation', { defaultValue: 'Request quotation' })}
      </PentairHeading>

      {asGuest || isAuthenticated ? (
        <QuotationForm />
      ) : !isAuthenticated && asGuest === false ? (
        <SignInForm />
      ) : (
        <LoginOptions onLoginClick={onLoginClick} onGuestClick={onGuestClick} />
      )}
    </AsideContainer>
  );
};
