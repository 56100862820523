import gql from 'graphql-tag';
import { ListProductsQuery, ListProductsQueryVariables } from '../../../../types/operations';
import { ecommerceApi } from '../../../api/api';
import { makeApiHook } from '../../../api/helpers/make-api-hook';
import { productCache } from './cache';
import { listProductsFragment } from './fragments';

const query = gql`
  ${listProductsFragment}

  query listProducts($filters: [InputFilter!], $pagination: inputPaginationOptions) {
    listProducts(filters: $filters, pagination: $pagination) {
      ...ProductCollectionFragment
    }
  }
`;

export const listProductAssetsQuery = query;

export const listProducts = async (variables: ListProductsQueryVariables) => {
  try {
    if (productCache.getList(variables)) {
      return { data: { listProducts: { products: productCache.getList(variables) } } as ListProductsQuery };
    }

    const response = await ecommerceApi.query<ListProductsQuery>(query, variables);
    productCache.insertList(response.data?.listProducts?.products, variables, { partialObject: true });

    return response;
  } catch (err: any) {
    throw err;
  }
};

export const useFetchProductList = makeApiHook(listProducts, {});
