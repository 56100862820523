import { Box, styled } from '@mui/material';

export const PentairBulletList = styled(Box)(({ theme }) => ({
  padding: 0,
  listStyle: 'none',
  marginTop: 6,
  marginBottom: 0,

  '& li': {
    paddingLeft: '1.5em',
    marginBottom: '0.25em',
    position: 'relative',
    ...theme.typography.body2,
  },

  '& li:before': {
    content: "''",
    height: '0.4em',
    width: '0.4em',
    background: theme.palette.brightBlue.main,
    display: 'block',
    position: 'absolute',
    transform: 'rotate(45deg)',
    top: '0.55em',
    left: '0.33em',
  },
}));

PentairBulletList.defaultProps = {
  component: 'ul',
};

export const PentairOrderedList = styled(Box)(({ theme }) => ({
  padding: 0,
  marginTop: 6,
  marginBottom: 0,
  listStyle: 'decimal',

  '& li': {
    display: 'list-item',
    listStylePosition: 'outside',
    marginLeft: '1em',
    paddingLeft: '0.5em',
    marginBottom: '0.25em',
    position: 'relative',
    ...theme.typography.body2,
  },
}));

PentairOrderedList.defaultProps = {
  component: 'ol',
};

export const PentairPlusIconList = styled(Box)(({ theme }) => ({
  padding: 0,
  listStyle: 'none',
  marginTop: 6,
  marginBottom: 0,

  '& li': {
    paddingLeft: '2.2em',
    marginBottom: '12px',
    position: 'relative',
    ...theme.typography.body2,
  },

  // '& li:first-letter': {
  //   textTransform: 'uppercase',
  //   color: 'red',
  // },

  '& li:before': {
    content: "''",
    height: '18px',
    width: '18px',
    position: 'absolute',
    top: '0em',
    left: '0.33em',
    display: 'block',
    background: `linear-gradient(#fff 0 0),
      linear-gradient(#fff 0 0),
      #64A70B`,
    backgroundPosition: 'center',
    backgroundSize: '50% 1px, 1px 50%',
    backgroundRepeat: 'no-repeat',
    borderRadius: '50%',
  },
}));

PentairPlusIconList.defaultProps = {
  component: 'ul',
};

export const PentairMinusIconList = styled(Box)(({ theme }) => ({
  padding: 0,
  listStyle: 'none',
  marginTop: 6,
  marginBottom: 0,

  '& li': {
    paddingLeft: '2.2em',
    marginBottom: '12px',
    position: 'relative',
    ...theme.typography.body2,
  },
  '& li::first-letter': {
    textTransform: 'uppercase',
    border: '2px solid red',
  },
  '& li:before': {
    content: "''",
    height: '18px',
    width: '18px',
    position: 'absolute',
    top: '0em',
    left: '0.33em',
    display: 'block',
    background: `linear-gradient(#fff 0 0),
      #D31E1E`,
    backgroundPosition: 'center',
    backgroundSize: '50% 1px, 1px 50%',
    backgroundRepeat: 'no-repeat',
    borderRadius: '50%',
  },
}));

PentairMinusIconList.defaultProps = {
  component: 'ul',
};
