import { Typography } from '@mui/material';
import { PentairTextField } from '../../../../theme/components/pentair-text-field';

interface CartItemNoteProps {
  value: string | undefined | null;
  editable?: boolean;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
}

export const CartItemNote: React.FC<CartItemNoteProps> = ({ value, editable, onChange, onBlur }) => {
  if (!value && !editable) return null;

  return editable ? (
    <PentairTextField
      variant="outlined"
      multiline
      defaultValue={value}
      onBlur={(e) => onBlur?.(e.target.value)}
      onChange={(e) => onChange?.(e.target.value)}
    />
  ) : (
    <Typography fontSize={'0.95em'} fontStyle={'italic'} component={'small'}>
      <strong>Note: </strong>
      {value}
    </Typography>
  );
};
