import { Grid, Typography, Box, TypographyProps } from '@mui/material';
import {} from '@mui/system';

interface ThemeDemopageTypographyProps {
  variants: {
    headline_large: TypographyProps['variant'];
    headline_medium: TypographyProps['variant'];
    headline_small: TypographyProps['variant'];
    h1: TypographyProps['variant'];
    h2: TypographyProps['variant'];
    h3: TypographyProps['variant'];
    h4: TypographyProps['variant'];
    h5: TypographyProps['variant'];
    h6: TypographyProps['variant'];
    subtitle1: TypographyProps['variant'];
    subtitle2: TypographyProps['variant'];
    body1: TypographyProps['variant'];
    body2: TypographyProps['variant'];
    button: TypographyProps['variant'];
    caption: TypographyProps['variant'];
    overline: TypographyProps['variant'];
  };
}

export const ThemeDemopageTypography: React.FC<ThemeDemopageTypographyProps> = ({ variants }) => {
  return (
    <Box>
      <Grid container>
        <Grid item xs={12} md={4}>
          <Typography variant={variants.headline_large} paragraph>
            Headline large
          </Typography>
          <Typography variant={variants.headline_medium} paragraph>
            Headline medium
          </Typography>
          <Typography variant={variants.subtitle1} paragraph>
            Subtitle1
          </Typography>
          <Typography variant={variants.subtitle2} paragraph>
            Subtitle2
          </Typography>
          <Typography variant={variants.h1} paragraph>
            Heading 1
          </Typography>
          <Typography variant={variants.h2} paragraph>
            Heading 2
          </Typography>
          <Typography variant={variants.h3} paragraph>
            Heading 3
          </Typography>
          <Typography variant={variants.h4} paragraph>
            Heading 4
          </Typography>
          <Typography variant={variants.h5} paragraph>
            Heading 5
          </Typography>
          <Typography variant={variants.h6} paragraph>
            Heading 6
          </Typography>
          <Typography variant={variants.body1} paragraph>
            Body1
          </Typography>
          <Typography variant={variants.body2} paragraph>
            Body2
          </Typography>
          <Typography variant={variants.button} paragraph>
            Button
          </Typography>
          <Typography variant={variants.caption} paragraph>
            Caption
          </Typography>
          <Typography variant={variants.overline} paragraph>
            Overline
          </Typography>
        </Grid>

        <Grid item xs={8}>
          <Typography variant={variants.headline_large} sx={{ fontWeight: 300 }}>
            Lorem ipsum dolor sit amet,
          </Typography>
          <Typography variant={variants.headline_large} gutterBottom>
            Lorem ipsum dolor sit amet,
          </Typography>

          <Typography variant={variants.subtitle1} paragraph>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id justo a tellus tempor efficitur.
            Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Cras nec metus
            vitae risus laoreet pellentesque et at arcu.
          </Typography>

          <br />

          <Typography variant={variants.headline_small} gutterBottom>
            Lorem ipsum dolor sit amet,
          </Typography>

          <Typography variant={variants.subtitle2} paragraph>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id justo a tellus tempor efficitur.
            Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Cras nec metus
            vitae risus laoreet pellentesque et at arcu.
          </Typography>

          <br />

          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography variant={variants.h3} gutterBottom>
                  Heading 3
                </Typography>

                <Typography variant={variants.body1} paragraph>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id justo a tellus tempor efficitur.
                  Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Cras nec
                  metus vitae risus laoreet pellentesque et at arcu. Aliquam feugiat eu lorem non varius. In eget nisi
                  in velit dictum iaculis. Nulla dignissim nulla in rhoncus interdum. Integer interdum libero elit, ut
                  sagittis est pulvinar sit amet. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas
                  vitae venenatis risus. Donec porta, nisi nec finibus tempor, libero nisl mattis libero, quis laoreet
                  purus metus ut metus. Duis ornare sodales mi cursus ultricies.
                </Typography>

                <Typography variant={variants.h4} gutterBottom>
                  Heading 4
                </Typography>

                <Typography variant={variants.body1}>
                  Pellentesque quis porta lectus. Nam eu ipsum sed odio dignissim tempor. Nunc euismod facilisis diam at
                  tempor. Phasellus nec gravida elit. Maecenas lobortis turpis vitae elit ultricies, at ultricies libero
                  aliquam. Cras non ex a metus scelerisque porta vel sed sapien. Fusce egestas vitae enim eget posuere.
                </Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant={variants.h5} gutterBottom>
                  Heading 5
                </Typography>

                <Typography variant={variants.body2} paragraph>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id justo a tellus tempor efficitur.
                  Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Cras nec
                  metus vitae risus laoreet pellentesque et at arcu. Aliquam feugiat eu lorem non varius. In eget nisi
                  in velit dictum iaculis. Nulla dignissim nulla in rhoncus interdum. Integer interdum libero elit, ut
                  sagittis est pulvinar sit amet. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas
                  vitae venenatis risus. Donec porta, nisi nec finibus tempor, libero nisl mattis libero, quis laoreet
                  purus metus ut metus. Duis ornare sodales mi cursus ultricies.
                </Typography>

                <Typography variant={variants.h6} gutterBottom>
                  Heading 6
                </Typography>

                <Typography variant={variants.body2}>
                  Pellentesque quis porta lectus. Nam eu ipsum sed odio dignissim tempor. Nunc euismod facilisis diam at
                  tempor. Phasellus nec gravida elit. Maecenas lobortis turpis vitae elit ultricies, at ultricies libero
                  aliquam. Cras non ex a metus scelerisque porta vel sed sapien. Fusce egestas vitae enim eget posuere.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
