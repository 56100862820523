import { AuthTemplate } from './auth-template';
import { UserConfirmationForm } from './forms/user-confirmation-form';

export const AuthStateUnconfirmedUser = () => {
  return (
    <AuthTemplate title="Confirm registration">
      <UserConfirmationForm />
    </AuthTemplate>
  );
};
