import { Box, CircularProgress, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAuth } from './auth-provider';
import { AuthTemplate } from './auth-template';
import CreateAccountPrompt from './create-account-prompt';
import { SignInForm } from './forms/sign-in-form';

/**
 * @returns {React.ReactNode}
 * @description Implements sign in form and useAuth
 */
export const AuthStateSignedOut = () => {
  const { loading, awaitingSignedInUser } = useAuth();
  const { t } = useTranslation();

  if (awaitingSignedInUser) {
    return (
      <AuthTemplate>
        <Box display="flex" justifyContent="center" alignItems="center" height={150}>
          <CircularProgress />
        </Box>
      </AuthTemplate>
    );
  }

  return (
    <Box sx={{ paddingTop: 1, marginBottom: 0, width: '100%', maxWidth: ['420px', '420px', 'initial'] }}>
      <Box sx={{ display: 'flex', flexDirection: ['column', 'column', 'row'] }}>
        <AuthTemplate
          title={t('iam:buttonLabel.signIn', { defaultValue: 'Sign In' })}
          containerSize={420}
          subTitleExtraStyles={{ marginBottom: 0 }}
          contentContainerStyles={{}}
          containerStyles={{ borderRight: ['', '', '2px solid #f2f2f2'] }}
        >
          <SignInForm />
        </AuthTemplate>
        <CreateAccountPrompt loading={loading} />
      </Box>
      <Typography
        variant="body2"
        sx={{
          display: 'inline-block',
          textAlign: 'center',
          paddingTop: '20px',
          paddingBottom: '23px',
          width: '100%',
          borderTop: ['2px solid #f2f2f2'],
          paddingLeft: 2,
          paddingRight: 2,
        }}
      >
        {t('iam:signupPrompt.supportOne', { defaultValue: 'Please contact' })}{' '}
        <Typography
          component={Link}
          variant="body2"
          color={'secondary'}
          fontWeight={600}
          href={t('support:dialog.contact.email.link', { defaultValue: 'mailto:mysudmo@pentair.com' })}
        >
          {t('support:dialog.contact.email.value', { defaultValue: 'mysudmo@pentair.com' })}{' '}
        </Typography>{' '}
        {t('iam:signupPrompt.supportTwo', { defaultValue: 'in case you need support.' })}
      </Typography>
    </Box>
  );
};
