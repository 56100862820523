import { Box, BoxProps } from '@mui/material';
import { Helmet } from 'react-helmet';
import { useLocation, useParams } from 'react-router';

import defaultImage from './images/meta-image.webp';

interface PageProps extends BoxProps {
  title: string;
  description: string;
  image?: string;
  robots?: string;
}

// const hostName = 'https://mysudmo.pentair.com/';
const hostName = 'https://d3eatbe9f9cntn.cloudfront.net';

export const Page: React.FC<PageProps> = ({ children, title, description, image, robots, ...boxProps }) => {
  const { locale } = useParams();

  image = image || defaultImage;

  const location = useLocation();
  const url = `${hostName}${location.pathname}`;

  return (
    <Box className="page" display="flex" flex={'1 0 0'} position="relative" {...boxProps}>
      <Helmet htmlAttributes={{ lang: locale }}>
        <title>{`Pentair mySüdmo - ${title}`}</title>
        <meta name="description" content={description} />
        <meta property="og:site_name" content="Pentair mySüdmo"></meta>
        <meta property="og:title" content={`Pentair mySüdmo - ${title}`} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={url} />
        <meta property="og:image" content={hostName + image} />
        <meta property="og:description" content={description} />
        <meta property="twitter:card" content="summary_large_image" />

        <link rel="alternate" href={`${hostName}`} hrefLang="en" />
        <link rel="alternate" href={`${hostName}/en`} hrefLang="en" />
        <link rel="alternate" href={`${hostName}/de`} hrefLang="de" />

        {robots && <meta name="robots" content={robots} />}
      </Helmet>
      {children}
    </Box>
  );
};
