import { Box, Dialog, DialogContent, Link, Typography, styled } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { DialogHeader } from '../../theme/components/dialog-header';
import { PentairHeading } from '../../theme/components/pentair-heading';
import supportImage from './support_header.png';

const SupportImage = styled(Box)({
  width: 'calc(100% + 48px)',
  height: 120,
  backgroundImage: `url('${supportImage}')`,
  backgroundSize: 'cover',
  marginLeft: -24,
  marginRight: -24,
  marginBottom: 24,
});

interface SupportDialogProps {
  active: boolean;
  setActive: any;
}

export const SupportDialog: React.FC<SupportDialogProps> = ({ active, setActive }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={active}
      fullWidth={true}
      PaperProps={{ style: { padding: 0, maxWidth: 420 } }}
      BackdropProps={{ style: { backdropFilter: 'blur(8px)' } }}
      onClose={() => setActive(false)}
    >
      <DialogHeader
        title={t('support:dialog.contact.title', { defaultValue: 'Support' })}
        subtitle={t('support:dialog.contact.subtitle', { defaultValue: 'Get in touch' })}
        onClose={() => setActive(false)}
      />
      <DialogContent>
        <SupportImage />
        <PentairHeading paragraph>
          {t('support:dialog.contact.heading.contactUs', { defaultValue: 'Contact us' })}
        </PentairHeading>

        <Typography paragraph>
          {t('support:dialog.contact.text', {
            defaultValue:
              'Do you have questions or would you like to get more information? Contact our customer service representatives within your specific industry.',
          })}
        </Typography>

        <Box display="flex" flexDirection={['column', 'column', 'row']} gap={[1, 2]}>
          <Box>
            <Typography style={{ fontWeight: 600 }}>
              {t('support:dialog.contact.phoneNumber.label', { defaultValue: 'Phone number' })}
            </Typography>

            <Typography color={'secondary'} gutterBottom>
              <strong>
                <Link href={t('support:dialog.contact.phoneNumber.link', { defaultValue: 'tel:+17635451730' })}>
                  {t('support:dialog.contact.team.phoneNumber.value', { defaultValue: '+1 (763) 545-1730' })}
                </Link>
              </strong>
            </Typography>
          </Box>

          <Box>
            <Typography style={{ fontWeight: 600 }}>
              {t('support:dialog.contact.email.label', { defaultValue: 'Email address' })}
            </Typography>

            <Typography color={'secondary'}>
              <strong>
                <Link href={t('support:dialog.contact.email.link', { defaultValue: 'mailto:mysudmo@pentair.com' })}>
                  {t('support:dialog.contact.email.value', { defaultValue: 'mysudmo@pentair.com' })}{' '}
                </Link>
              </strong>
            </Typography>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
