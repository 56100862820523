import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AuthState, useAuth } from './auth-provider';
import { AuthTemplate } from './auth-template';

type Props = {
  loading: boolean;
};

const CreateAccountPrompt = ({ loading }: Props) => {
  const { t } = useTranslation();
  const { setAuthState } = useAuth();

  return (
    <AuthTemplate
      showError={false}
      dialogTitle={t('iam:signupPrompt.titleOne', { defaultValue: 'Create' })}
      dialogSubTitle={t('iam:signupPrompt.titleTwo', { defaultValue: 'New account' })}
      subTitleExtraStyles={{ marginBottom: 0 }}
      containerStyles={{
        borderTop: ['2px solid #f2f2f2', '', 'none'],
      }}
    >
      <Box sx={{ width: '100%' }}>
        <Box sx={{ minHeight: '161px', marginTop: 3, marginBottom: '45px' }}>
          <Typography variant="body2">
            {t('iam:signupPrompt.textTwo', {
              defaultValue:
                "If you're new to mySüdmo, take a moment to create your Pentair account using the button below. It's quick and easy, and once you're signed up, you can start enjoying the benefits of our product configurator right away!",
            })}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="flex-end">
          <Button
            type="submit"
            disabled={loading}
            endIcon={<FontAwesomeIcon icon={faArrowRight} />}
            onClick={() => setAuthState(AuthState.Registering)}
          >
            {t('iam:buttonLabel.createAccountPrompt', { defaultValue: 'Create Account' })}
          </Button>
        </Box>
      </Box>
    </AuthTemplate>
  );
};

export default CreateAccountPrompt;
