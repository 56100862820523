import { faArrowLeft, faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, CircularProgress } from '@mui/material';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { PentairTextField } from '../../../theme/components/pentair-text-field';
import { InputLegalAgreement } from '../../ecommerce/quotations/quotation-form';
import { FormOptions, useFormData } from '../../form/use-formdata';
import { required, requiredEmail, requiredString } from '../../form/validators';
import { AuthState, useAuth } from '../auth-provider';

interface FormData {
  username: string;
  password: string;
  givenName: string;
  familyName: string;
  termsAccepted: boolean;
}

/**
 * This holds the form options - Object to check for fields to validate during registration.
 * It will contain onsubmit function
 */
const formOptions: FormOptions<FormData> = {
  validateOnSubmit: true,
  validations: {
    username: requiredEmail,
    password: requiredString,
    givenName: requiredString,
    familyName: requiredString,
    termsAccepted: required,
  },
};

/**
 * This object holds the initial state for the sign in form.
 * It will be passed to the useFormData hook as the first parameter
 */
const initialRecord: FormData = {
  username: '',
  password: '',
  givenName: '',
  familyName: '',
  termsAccepted: false,
};

/**
 * The registration form component. Use this component to display the registration form.
 * This component uses the useAuth hook to registration the user. Ensure that the AuthProvider is used in the parent component.
 * No props are required.
 *
 * @returns The react component that displays the registration form.
 */
export const RegistrationForm = () => {
  const { setAuthState, registerUser, loading } = useAuth();
  const { locale } = useParams();

  const { t } = useTranslation();

  formOptions.onSubmit = useCallback(
    ({ username, password, givenName, familyName }: FormData) => {
      registerUser(username, password, { given_name: givenName, family_name: familyName, email: username, locale: locale || 'en' });
    },
    [registerUser, locale]
  );

  const { change, record, validationErrors, submitForm, hasValidationErrors } = useFormData<FormData>(
    initialRecord,
    formOptions
  );

  return (
    <form onSubmit={submitForm} style={{ display: 'flex', flexFlow: 'column' }}>
      <PentairTextField
        label={t('iam:givenName.label', { defaultValue: 'First name' })}
        placeholder={t('iam:givenName.placeholder', { defaultValue: 'Enter your first name' })}
        description={t('iam:givenName.description', {
          defaultValue: 'Your first name',
        })}
        id="givenName"
        name="givenName"
        value={record.givenName}
        onChange={(e) => change('givenName', e.target.value)}
        disabled={loading}
        error={!!validationErrors['givenName']}
        helperText={validationErrors['givenName']}
      />

      <PentairTextField
        label={t('iam:familyName.label', { defaultValue: 'Last name' })}
        placeholder={t('iam:familyName.placeholder', { defaultValue: 'Enter your last name' })}
        description={t('iam:familyName.description', {
          defaultValue: 'Your last name',
        })}
        id="familyName"
        name="familyName"
        value={record.familyName}
        onChange={(e) => change('familyName', e.target.value)}
        disabled={loading}
        error={!!validationErrors['familyName']}
        helperText={validationErrors['familyName']}
      />

      <PentairTextField
        label={t('iam:username.label', { defaultValue: 'Username (E-mail address)' })}
        placeholder={t('iam:username.placeholder', { defaultValue: 'Enter an e-mail address' })}
        description={t('iam:username.description', {
          defaultValue: 'This is username of your account. By default this is your email address',
        })}
        id="username"
        name="username"
        value={record.username}
        onChange={(e) => change('username', e.target.value)}
        disabled={loading}
        error={!!validationErrors['username']}
        helperText={validationErrors['username']}
      />

      <PentairTextField
        label={t('iam:password.label', { defaultValue: 'Password' })}
        placeholder={t('iam:password.placeholder', { defaultValue: 'Enter a password' })}
        description={t('iam:password.description', {
          defaultValue:
            'This is the password you have received in your email. If you have forgotten your password, please contact your administrator.',
        })}
        id="password"
        name="password"
        type="password"
        value={record.password}
        onChange={(e) => change('password', e.target.value)}
        disabled={loading}
        error={!!validationErrors['password']}
        helperText={validationErrors['password']}
      />

      <InputLegalAgreement
        value={record.termsAccepted}
        onChange={(val) => change('termsAccepted', val)}
        error={!!validationErrors['termsAccepted']}
      />

      <Box display="flex" justifyContent="space-between" marginTop={1.5}>
        <Button
          disabled={loading}
          variant="text"
          color="inherit"
          startIcon={loading ? <CircularProgress size={20} /> : <FontAwesomeIcon icon={faArrowLeft} />}
          onClick={() => {
            setAuthState(AuthState.SignedOut);
          }}
        >
          {t('back', { defaultValue: 'Back' })}
        </Button>

        <Button
          type="submit"
          disabled={loading || hasValidationErrors}
          endIcon={loading ? <CircularProgress size={20} /> : <FontAwesomeIcon icon={faArrowRight} />}
        >
          {t('iam:buttonLabel.createAccount', { defaultValue: 'Create account' })}
        </Button>
      </Box>
    </form>
  );
};
