import { TFunction } from 'i18next';
import isMixingSubstancesImage from './images/is-mixing-substances.webp';
import whichAreaImage from './images/which-area.webp';
import whichCleaningMethodImage from './images/which-cleaning-method.webp';
import whichIndustryImage from './images/which-industry.webp';

import { ListItem } from '@mui/material';
import { ReactNode, useMemo } from 'react';
import { PentairBulletList } from '../../theme/components/unordered-list';
import whichValveTypeImage from './images/is-bv-or-svp.webp';
import isChangeoverImage from './images/is-changeover.webp';
import isDirectConnectionImage from './images/is-direct-connection.webp';
import isHygienicOrAsapticImage from './images/is-hygienic-or-asaptic.webp';
import isManualOrPneumaticImage from './images/is-manual-or-pneumatic.webp';
import isMixProofImage from './images/is-mix-proof.webp';
import isMixingOrDistributionImage from './images/is-mixing-or-distribution.webp';
import isPMOImage from './images/is-pmo.webp';
import isSterilizationImage from './images/is-sterilization.webp';
import isTankBottomImage from './images/is-tank-bottom.webp';
import whichActuatorModeImage from './images/which-actuator-mode.webp';
import { InfoCleaningMethod } from './info/info-cleaning-method';
import { InfoHousingBodyExec } from './info/info-housing-body-exec';
import { InfoHygienicAseptic } from './info/info-hygienic-aseptic';
import { InfoMixProof } from './info/info-mix-proof';
import { InfoValveType } from './info/info-valve-type';

import solidBodyHousing from './info/assets/solid-body-housing.png';
import splitBodyHousing from './info/assets/split-body-housing.png';

export interface ValveSelectorEnrichtmentData {
  question?: string;
  featuredImage: string;
  description: string;
  category: string;
  buttons?: Record<
    string,
    {
      variant?: 'button' | 'descriptive' | 'image';
      label: string;
      title?: string;
      imageSrc?: string;
      description?: ReactNode;
      cta?: string;
    }
  >;
  components?: {
    infoDialog?: {
      content: React.FC;
    };
  };
}

export type SlotName =
  | 'isChangeover'
  | 'isDirectConnection'
  | 'isHygienicOrAseptic'
  | 'isManualOrPneumatic'
  | 'isMixProof'
  | 'isMixSubstances'
  | 'isMixingOrDistribution'
  | 'isPMO'
  | 'isSterilization'
  | 'isTankBottom'
  | 'whichActuatorMode'
  | 'whichCleaningMethod'
  | 'whichIndustry'
  | 'whichProductionArea'
  | 'whichValveType'
  | 'whichHousingBodyExec';

export const useEnrichmentData = (t: TFunction): Record<SlotName, ValveSelectorEnrichtmentData> => {
  return useMemo(
    () => ({
      isChangeover: {
        question: t('support:assistant.isChangeover.question', { defaultValue: 'Do you need a changeover valve?' }),
        category: t('support:assistant.isChangeover.category', { defaultValue: 'Valve Function' }),
        featuredImage: isChangeoverImage,
        description: t('support:assistant.isChangeover.description', {
          defaultValue: `Changeover valves are switchover valves, switching flow from one pipeline to another.`,
        }),
        buttons: {
          yes: {
            label: t('support:assistant.isChangeover.answer.yes', { defaultValue: 'Yes' }),
          },
          no: {
            label: t('support:assistant.isChangeover.answer.no', { defaultValue: 'No' }),
          },
        },
      },

      isDirectConnection: {
        question: t('support:assistant.isDirectConnection.question', {
          defaultValue: 'How do you want to connect the valve to the tank?',
        }),
        category: t('support:assistant.isDirectConnection.category', { defaultValue: 'Valve Function' }),
        featuredImage: isDirectConnectionImage,
        description: t('support:assistant.isDirectConnection.description', {
          defaultValue: `If directly connected to the tank via block flange or indirectly via welding end to the downpipe.`,
        }),

        buttons: {
          yes: {
            label: t('support:assistant.isDirectConnection.answer.yes', { defaultValue: 'Block flange' }),
          },
          no: {
            label: t('support:assistant.isDirectConnection.answer.no', { defaultValue: 'Welding end' }),
          },
        },
      },

      isHygienicOrAseptic: {
        question: t('support:assistant.isHygienicOrAseptic.question', {
          defaultValue: 'Do you require a hygienic or aseptic valve?',
        }),
        category: t('support:assistant.isHygienicOrAseptic.category', { defaultValue: 'Valve Location' }),
        featuredImage: isHygienicOrAsapticImage,
        description: t('support:assistant.isHygienicOrAseptic.description', {
          defaultValue: `Hygienic and aseptic valves have a lot in common. They share similar materials with smooth surface finishes. Their design ensures bacteria cannot accumulate and grow within the valve.<br /><br />Depending on the required level of purity in the process area of your plant, either hygienic or aseptic valves will be required.`,
        }),
        buttons: {
          aseptic: {
            label: t('support:assistant.isHygienicOrAseptic.answer.aseptic', { defaultValue: 'Aseptic' }),
          },
          hygienic: {
            label: t('support:assistant.isHygienicOrAseptic.answer.hygienic', { defaultValue: 'Hygienic' }),
          },
        },
        components: {
          infoDialog: {
            content: InfoHygienicAseptic,
          },
        },
      },

      isManualOrPneumatic: {
        question: t('support:assistant.isManualOrPneumatic.question', {
          defaultValue: 'How do you want to activate your valve?',
        }),

        category: t('support:assistant.isManualOrPneumatic.category', { defaultValue: 'Valve Function' }),
        featuredImage: isManualOrPneumaticImage,
        description: t('support:assistant.isManualOrPneumatic.description', {
          defaultValue: `Depending on the level of automation of the plant, manual actuation via a handle or pneumatic operation of the valve is available.`,
        }),
        buttons: {
          manual: {
            label: t('support:assistant.isManualOrPneumatic.answer.manual', { defaultValue: 'Manual' }),
          },
          pneumatic: {
            label: t('support:assistant.isManualOrPneumatic.answer.pneumatic', { defaultValue: 'Pneumatic' }),
          },
        },
      },

      isMixProof: {
        question: t('support:assistant.isMixProof.question', {
          defaultValue: 'Are you looking for a mix-proof valve?',
        }),
        category: t('support:assistant.isMixProof.category', { defaultValue: 'Valve Function' }),
        featuredImage: isMixProofImage,
        description: t('support:assistant.isMixProof.description', {
          defaultValue: `As operators want to optimize efficiency and minimize downtime, specific production zones can be in cleaning while simultaneously new product batches are in production. Because of this, it's integral to prevent unintentional mixing between two media (e.g., CIP-detergent and the product).`,
        }),
        buttons: {
          yes: {
            label: t('support:assistant.isMixProof.answer.yes', { defaultValue: 'Yes' }),
          },
          no: {
            label: t('support:assistant.isMixProof.answer.no', { defaultValue: 'No' }),
          },
        },
        components: {
          infoDialog: {
            content: InfoMixProof,
          },
        },
      },

      isMixSubstances: {
        question: t('support:assistant.isMixSubstances.question', {
          defaultValue: 'Will the valve be used to mix two substances?',
        }),
        category: t('support:assistant.isMixSubstances.category', { defaultValue: 'Valve Function' }),
        featuredImage: isMixingSubstancesImage,
        description: t('support:assistant.isMixSubstances.description', {
          defaultValue: `A mixing valve influences the ratio of two volume flows. It's a variant of a control valve with up to two input lines and at least one output line.`,
        }),
        buttons: {
          yes: {
            label: t('support:assistant.isMixSubstances.answer.yes', { defaultValue: 'Yes' }),
          },
          no: {
            label: t('support:assistant.isMixSubstances.answer.no', { defaultValue: 'No' }),
          },
        },
      },

      isMixingOrDistribution: {
        question: t('support:assistant.isMixingOrDistribution.question', {
          defaultValue: 'Select the function of the valve.',
        }),

        category: t('support:assistant.isMixingOrDistribution.category', { defaultValue: 'Valve Function' }),
        featuredImage: isMixingOrDistributionImage,
        description: t('support:assistant.isMixingOrDistribution.description', {
          defaultValue: `Depending on your needs, choose from distribution or mixing valves.<br /><br />If you're looking for a valve that offers the ability to run from one line (1) into either another (2) or a third line (3), select a distribution valve.<br /><br />If you want to run from either one line (2) or a second line (3) into the same line (1), you would choose a mixing valve.`,
        }),
        buttons: {
          distribution: {
            label: t('support:assistant.isMixingOrDistribution.answer.distribution', {
              defaultValue: 'Distribution',
            }),
          },
          mixing: {
            label: t('support:assistant.isMixingOrDistribution.answer.mixing', { defaultValue: 'Mixing' }),
          },
        },
      },

      isPMO: {
        question: t('support:assistant.isPMO.question', {
          defaultValue: 'Does the valve need to be PMO-compliant?',
        }),

        category: t('support:assistant.isPMO.category', { defaultValue: 'Valve Function' }),
        featuredImage: isPMOImage,
        description: t('support:assistant.isPMO.description', {
          defaultValue: `If your valve is installed and used within the dairy industry in the United States, you must comply with the PMO (<strong>P</strong>asteurized <strong>M</strong>ilk <strong>O</strong>rdinance).<br /><br />Outside of the US, PMO compliance is not required. Please contact the Pentair Südmo US office for more information.`,
        }),

        buttons: {
          yes: {
            label: t('support:assistant.isPMO.answer.yes', { defaultValue: 'Yes' }),
          },
          no: {
            label: t('support:assistant.isPMO.answer.no', { defaultValue: 'No' }),
          },
        },
      },
      isSterilization: {
        question: t('support:assistant.isSterilization.question', {
          defaultValue: 'Is additional sterilization of the valve shafts required?',
        }),

        category: t('support:assistant.isSterilization.category', { defaultValue: 'Valve Function' }),
        featuredImage: isSterilizationImage,
        description: t('support:assistant.isSterilization.description', {
          defaultValue: `Depending on the required levels of purity in a particular process area of your plant or an intensive production process (e.g., handling of coffee extract), additional sterilization of the shaft areas of the valve is recommended, in addition to seat cleaning via lift functions.`,
        }),
        buttons: {
          yes: {
            label: t('support:assistant.isSterilization.answer.yes', { defaultValue: 'Yes' }),
          },
          no: {
            label: t('support:assistant.isSterilization.answer.no', { defaultValue: 'No' }),
          },
        },
      },

      isTankBottom: {
        question: t('support:assistant.isTankBottom.question', {
          defaultValue: 'Are you looking for a tank bottom valve?',
        }),
        category: t('support:assistant.isTankBottom.category', { defaultValue: 'Valve Location' }),
        featuredImage: isTankBottomImage,
        description: t('support:assistant.isTankBottom.description', {
          defaultValue: `If directly connected to the tank via block flange or indirectly connected via welding end to the downpipe, both is possible.`,
        }),
        buttons: {
          yes: {
            label: t('support:assistant.isTankBottom.answer.yes', { defaultValue: 'Yes' }),
          },
          no: {
            label: t('support:assistant.isTankBottom.answer.no', { defaultValue: 'No' }),
          },
        },
      },

      whichActuatorMode: {
        question: t('support:assistant.whichActuatorMode.question', {
          defaultValue: 'Which mode of operation should the actuator have?',
        }),

        category: t('support:assistant.whichActuatorMode.category', { defaultValue: 'Valve Function' }),
        featuredImage: whichActuatorModeImage,
        description: t('support:assistant.whichActuatorMode.description', {
          defaultValue: `Whether you are looking for an actuator equipped with or without a spring, we have a suitable solution. Even if you are looking for an actuator with a spring whose mode of operation will be Spring to open–Air to close (NO), please order the NC version, and you can subsequently change the NC actuator into the NO version without needing other parts.`,
        }),

        buttons: {
          NC: {
            label: t('support:assistant.whichActuatorMode.answer.NC', {
              defaultValue: 'Air to open - Spring to close (NC)',
            }),
          },
          AA: {
            label: t('support:assistant.whichActuatorMode.answer.AA', {
              defaultValue: 'Air to open - Air to close (AA)',
            }),
          },
          NO: {
            label: t('support:assistant.whichActuatorMode.answer.NO', {
              defaultValue: 'Normally closed (NO)',
            }),
          },
        },
      },

      whichCleaningMethod: {
        question: t('support:assistant.whichCleaningMethod.question', {
          defaultValue: 'Which cleaning method should the valve have?',
        }),

        category: t('support:assistant.whichCleaningMethod.category', { defaultValue: 'Valve Function' }),
        featuredImage: whichCleaningMethodImage,
        description: t('support:assistant.whichCleaningMethod.description', {
          defaultValue: `As necessities for product safety, microbiological durability, and longer product shelf-life increase, exceptional cleaning of process equipment is of utmost importance. We offer different cleaning methods to suit your needs.`,
        }),

        components: {
          infoDialog: {
            content: InfoCleaningMethod,
          },
        },

        buttons: {
          'external-flushing-connection': {
            label: t('support:assistant.whichCleaningMethod.answer.external-flushing-connection', {
              defaultValue: 'External flushing connection',
            }),
          },
          'seat-lifting': {
            label: t('support:assistant.whichCleaningMethod.answer.seat-lifting', { defaultValue: 'Seat lifting' }),
          },
          'several-cleaning-methods': {
            label: t('support:assistant.whichCleaningMethod.answer.several-cleaning-methods', {
              defaultValue: 'Several cleaning methods',
            }),
          },

          'no-cleaning': {
            label: t('support:assistant.whichCleaningMethod.answer.no-cleaning', {
              defaultValue: 'No cleaning',
            }),
          },
        },
      },

      whichIndustry: {
        question: t('support:assistant.whichIndustry.question', {
          defaultValue: 'In which industry will the valve be installed?',
        }),

        category: t('support:assistant.whichIndustry.category', { defaultValue: 'Valve Location' }),
        featuredImage: whichIndustryImage,
        description: t('support:assistant.whichIndustry.description', {
          defaultValue: `We engineer and produce the highest standard of hygienic and aseptic valve technology for the beverage, dairy, and food industries and other industries. Rely on Pentair Südmo Valve Technology for reliable operations through easy maintenance and optimized cleaning capabilities.`,
        }),

        buttons: {
          brewery: {
            label: t('support:assistant.whichIndustry.answer.brewery', { defaultValue: 'Brewery' }),
          },
          dairy: {
            label: t('support:assistant.whichIndustry.answer.dairy', { defaultValue: 'Dairy' }),
          },
          'soft-drinks': {
            label: t('support:assistant.whichIndustry.answer.soft-drinks', { defaultValue: 'Soft drinks' }),
          },
          other: {
            label: t('support:assistant.whichIndustry.answer.other', { defaultValue: 'Other' }),
          },
        },
      },

      whichProductionArea: {
        question: t('support:assistant.whichProductionArea.question', {
          defaultValue: 'In which area of your production process will the valve be used?',
        }),
        category: t('support:assistant.whichProductionArea.category', { defaultValue: 'Valve Location' }),
        featuredImage: whichAreaImage,
        description: t('support:assistant.whichProductionArea.description', {
          defaultValue: `Wherever you intend to use valves in your production process, such as for cleaning distribution, we have the solution that sets the standard in process technology.`,
        }),
        buttons: {
          production: {
            label: t('support:assistant.whichProductionArea.answer.production', { defaultValue: 'Production' }),
          },
          'cleaning-cip': {
            label: t('support:assistant.whichProductionArea.answer.cleaning-cip', { defaultValue: 'Cleaning (CIP)' }),
          },
          other: {
            label: t('support:assistant.whichProductionArea.answer.other', { defaultValue: 'Other' }),
          },
        },
      },

      whichHousingBodyExec: {
        question: t('support:assistant.whichHousingBodyExec.question', {
          defaultValue: 'Which housing body execution do you prefer?',
        }),
        category: t('support:assistant.whichHousingBodyExec.category', { defaultValue: 'Valve type' }),
        featuredImage: whichAreaImage,
        description: t('support:assistant.whichHousingBodyExec.description', {
          defaultValue: `We have the ability to provide you with either solid steel material or a split body housing with clamp connection. `,
        }),
        components: {
          infoDialog: {
            content: InfoHousingBodyExec,
          },
        },

        buttons: {
          solid: {
            label: t('support:assistant.whichHousingBodyExec.answer.solid', {
              defaultValue: 'Solid body housing',
            }),
            variant: 'image',
            imageSrc: solidBodyHousing,
          },
          split: {
            label: t('support:assistant.whichHousingBodyExec.answer.split', {
              defaultValue: 'Split body housing',
            }),
            variant: 'image',
            imageSrc: splitBodyHousing,
          },
        },
      },

      whichValveType: {
        question: t('support:assistant.whichValveType.question', {
          defaultValue: 'Please choose your preferred valve type.',
        }),
        category: t('support:assistant.whichValveType.category', { defaultValue: 'Valve Function' }),
        featuredImage: whichValveTypeImage,
        description: t('support:assistant.whichValveType.description', {
          defaultValue: ``,
        }),

        components: {
          infoDialog: {
            content: InfoValveType,
          },
        },

        buttons: {
          SVP: {
            label: t('support:assistant.whichValveType.answer.SVP', { defaultValue: 'We require SVP Select' }),
            description: (
              <PentairBulletList>
                <ListItem>
                  {t('support:aboutSingleSeatValves.descriptionBullet1', {
                    defaultValue: 'Robust housing design from solid steel',
                  })}
                </ListItem>
                <ListItem>
                  {t('support:aboutSingleSeatValves.descriptionBullet2', {
                    defaultValue: 'Complete range of variants (hygienic & aseptic) ',
                  })}
                </ListItem>
                <ListItem>
                  {t('support:aboutSingleSeatValves.descriptionBullet3', {
                    defaultValue: 'Simple Kit-System',
                  })}
                </ListItem>
                <ListItem>
                  {t('support:aboutSingleSeatValves.descriptionBullet4', {
                    defaultValue: 'Long-lasting sealing technology  ',
                  })}
                </ListItem>
                <ListItem>
                  {t('support:aboutSingleSeatValves.descriptionBullet5', {
                    defaultValue: 'Ease of maintenance',
                  })}
                </ListItem>
              </PentairBulletList>
            ),
            variant: 'descriptive',
            title: t('support:assistant.whichValveType.answer.SVP', {
              defaultValue: 'Single seat valve - SVP Select',
            }),
          },
          BV: {
            label: t('support:assistant.whichValveType.answer.BV', { defaultValue: 'We require BV Perform' }),
            description: (
              <PentairBulletList flex={3}>
                <ListItem>
                  {t('support:aboutDoubleSeatValves.descriptionBullet1', {
                    defaultValue: 'Cost-effective valve solution',
                  })}
                </ListItem>
                <ListItem>
                  {t('support:aboutDoubleSeatValves.descriptionBullet2', {
                    defaultValue: 'Space- saving shut-off valve',
                  })}
                </ListItem>
                <ListItem>
                  {t('support:aboutDoubleSeatValves.descriptionBullet3', {
                    defaultValue: 'Various housing connections available',
                  })}
                </ListItem>
                <ListItem>
                  {t('support:aboutDoubleSeatValves.descriptionBullet4', {
                    defaultValue: 'Wide range of different seal materials',
                  })}
                </ListItem>
                <ListItem>
                  {t('support:aboutDoubleSeatValves.descriptionBullet5', {
                    defaultValue: 'Common executions are available from stock',
                  })}
                </ListItem>
              </PentairBulletList>
            ),
            variant: 'descriptive',
            title: t('support:assistant.whichValveType.answer.BV', {
              defaultValue: 'Butterfly valve - BV Perform',
            }),
          },
        },
      },
    }),
    [t]
  );
};

export default useEnrichmentData;
