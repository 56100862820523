import { makeApiHook } from '@pentair/react-mui-5';
import gql from 'graphql-tag';
import { UpdatePricingMutation, UpdatePricingMutationVariables } from '../../../types/operations';
import { ecommerceApi } from '../../api/api';
import { refreshToken } from '../../auth/auth-provider';
import { pricingFragment } from '../../ecommerce/user-settings/api/fragments';

const activateMutation = gql`
  ${pricingFragment}
  mutation updatePricing($customerId: String) {
    updatePricing(customerId: $customerId) {
      ...PricingFragment
    }
  }
`;

export enum PricingActivationAction {
  Activate = 'activate',
  Deactivate = 'deactivate',
}

export enum PricingActivationStatus {
  approved = 'Approved',
  rejected = 'Rejected',
  awaitingApproval = 'Awaiting Approval',
  awaitingCustomerID = 'Awaiting Customer ID',
  cancelled = 'Cancelled',
  inactive = '',
}

export const updatePricing = async ({ ...variables }: UpdatePricingMutationVariables) => {
  try {
    const response = await ecommerceApi.query<UpdatePricingMutation>(activateMutation, variables);
    await refreshToken();
    return response;
  } catch (err: any) {
    throw err;
  }
};

export const useUpdatePricing = makeApiHook(updatePricing, {});
