// import { OverridesStyleRules } from '@mui/styles/overrides';

export const MuiList: any = {
  styleOverrides: {
    root: {},
    paddingTop: 0,
    paddingBottom: 0,
  },
};

export const MuiListItemIcon: any = {
  styleOverrides: {
    root: {
      color: '#1289BB',
      minWidth: 36,
      marginTop: 2,
    },
  },
};

export const MuiListItemText: any = {
  styleOverrides: {
    primary: {
      fontSize: '1em',
    },
  },
};

export const MuiListItem: any = {
  styleOverrides: {
    root: {
      padding: 0,
    },
  },
};
