import { Button, CircularProgress } from '@mui/material';
import { useCallback } from 'react';
import { useAuth } from '../auth-provider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PentairTextField } from '../../../theme/components/pentair-text-field';
import { FormOptions, useFormData } from '../../form/use-formdata';
import { requiredString } from '../../form/validators';
import { Typography } from '@mui/material';

interface FormData {
  code: string;
}

/**
 * This holds the form options - Object to check for fields to validate during registration account confirmation.
 * It will contain onsubmit function
 */

const formOptions: FormOptions<FormData> = {
  validateOnSubmit: true,
  validations: {
    code: requiredString,
  },
};

/**
 * This object holds the initial state for the sign in form.
 * It will be passed to the useFormData hook as the first parameter
 */
const initialRecord: FormData = {
  code: '',
};

/**
 * The user confirmation form component. Use this component to display the user confirmation form.
 * This component uses the useAuth hook to confirmat the user. Ensure that the AuthProvider is used in the parent component.
 * No props are required.
 *
 * @returns The react component that displays the user confirmation form.
 */
export const UserConfirmationForm = () => {
  const { confirmUserRegistration, loading, resendConfirmationCode } = useAuth();
  const { t } = useTranslation();

  formOptions.onSubmit = useCallback(
    ({ code }: FormData) => {
      confirmUserRegistration(code);
    },
    [confirmUserRegistration]
  );
  const { change, record, validationErrors, submitForm, hasValidationErrors } = useFormData<FormData>(
    initialRecord,
    formOptions
  );

  return (
    <form onSubmit={submitForm} style={{ display: 'flex', flexFlow: 'column' }}>
      <Typography variant="body1" paragraph>
        {t('iam:confirmationForm.text', {
          defaultValue: 'Please enter the confirmation code you received in your email.',
        })}
      </Typography>

      <PentairTextField
        label={t('iam:confirmationCode.label', { defaultValue: 'Confirmation code' })}
        placeholder={t('iam:confirmationCode.placeholder', { defaultValue: 'Confirmation code' })}
        description={t('iam:confirmationCode.description', {
          defaultValue: 'The confirmation code you received in your email',
        })}
        id="confirmationCode"
        name="confirmationCode"
        value={record.code}
        onChange={(e) => change('code', e.target.value)}
        disabled={loading}
        error={!!validationErrors['code']}
        helperText={validationErrors['code']}
      />

      <Box display="flex" justifyContent="space-between" marginTop={1.5}>
        <Button
          variant="text"
          color="inherit"
          disabled={loading}
          sx={{ marginRight: 1 }}
          onClick={() => resendConfirmationCode()}
        >
          {t('iam:buttonLabel.resend', { defaultValue: 'Resend code' })}
        </Button>
        <Button
          type="submit"
          disabled={loading || hasValidationErrors}
          endIcon={loading ? <CircularProgress size={20} /> : <FontAwesomeIcon icon={faArrowRight} />}
        >
          {t('iam:buttonLabel.confirmAccount', { defaultValue: 'Confirm account' })}
        </Button>
      </Box>
    </form>
  );
};
