import { alpha, Components, Theme } from '@mui/material';

export const MuiDialog: Components['MuiDialog'] = {
  defaultProps: {
    PaperProps: {
      elevation: 0,
    },
  },
  styleOverrides: {
    paper: {
      boxShadow: 'none',
    },
  },
};

export const MuiDialogTitle: Components['MuiDialogTitle'] = {
  styleOverrides: {
    root: {
      padding: 24,
      margin: 0,
    },
  },
};

export const MuiDialogContent: Components['MuiDialogContent'] = {
  styleOverrides: {
    root: {
      padding: '0 24px',
      margin: 0,
      // '&:first-child': {
      '&:first-of-type': {
        paddingTop: 24,
      },
      '&:last-child': {
        paddingBottom: 24,
      },
    },
  },
};

export const MuiDialogActions: Components['MuiDialogActions'] = {
  styleOverrides: {
    root: {
      padding: 24,
      margin: 0,
    },
  },
};

export const MuiBackdrop: Components<Theme>['MuiBackdrop'] = {
  defaultProps: {},
  styleOverrides: {
    root: ({ theme }) => ({
      background: alpha(theme.palette.silverGray.main, 0.5),
      backdropFilter: 'blur(4px) brightness(60%)',
    }),

    invisible: {
      background: 'transparent',
      backdropFilter: 'none',
    },
  },
};
