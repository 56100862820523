import { AboutExternalFlushingConnection } from './about-external-flushing-connection';
import { AboutSeatLifting } from './about-seat-lifting';
import { AboutSterilization } from './about-sterilization';
import { ComparisonGrid } from './comparison-grid';

type Props = {};

export const InfoCleaningMethod = (props: Props) => {
  return (
    <ComparisonGrid>
      <AboutSeatLifting />
      <AboutExternalFlushingConnection />
      <AboutSterilization />
    </ComparisonGrid>
  );
};
