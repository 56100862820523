import { useCallback } from 'react';
import { Address, InputAddress } from '../../../../types/operations';
import { useFetchEcommerceUserSettings } from '../api/fetch';
import { useUpdateEcommerceUserSettings } from '../api/update';

export const useUserEcommerceSettings = () => {
  const [{ response, loading: isFetching, error: fetchError }, fetchUserSettings] = useFetchEcommerceUserSettings();
  const [{ response: updateResponse, loading: isUpdating, error: updateError }, updateUserSettings] =
    useUpdateEcommerceUserSettings();

  const settings = updateResponse?.data?.updateUserSettings || response.data?.userSettings;

  const loading = isFetching || isUpdating;

  return {
    fetchUserSettings,
    settings,
    loading,
    error: fetchError || updateError,
    updateAddress: useCallback(
      async (address: InputAddress | Address, options?: any) => {
        const _address = {
          id: address.id,
          addressLine1: address.addressLine1 || '',
          addressLine2: address.addressLine2 || '',
          addressLine3: address.addressLine3 || '',
          city: address.city || '',
          postal: address.postal || '',
          country: address.country || '',
          organisationName: address.organisationName || '',
        };

        if (options?.local && settings) {
          //TODO: replace mutation with local state update.
          //This is a temporary solution which works because we do not require
          //a refresh of the user settings after updating the address.
          settings.addresses = [_address];
        } else {
          await updateUserSettings({
            input: {
              addresses: [_address],
            },
          });
        }
      },
      [settings, updateUserSettings]
    ),
  };
};
