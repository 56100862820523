import { Box, Divider, Tab, Tabs } from '@mui/material';
import { Route, useNavigate, useParams } from 'react-router';
import { ThemeDemopageButtons } from './buttons';
import { ThemeDemoPageForms } from './forms';
import { ThemeDemoPageTable } from './table';
import { ThemeDemopageTypography } from './typography';

export const ThemeDemoPage = () => {
  const naviate = useNavigate();

  return (
    <Box padding="5vw" bgcolor="#fff" flex={1}>
      <Tabs sx={{ marginBottom: 3 }}>
        <Tab label="Typography" onClick={() => naviate('typography')} />
        <Tab label="Buttons" onClick={() => naviate('buttons')} />
        <Tab label="Tables" onClick={() => naviate('tables')} />
        <Tab label="Forms" onClick={() => naviate('forms')} />
      </Tabs>
      <Route
        path={'*/:currentTab'}
        element={
          <Box>
            <TabContent />
          </Box>
        }
      />
      <Route
        index
        element={
          <Box>
            <TabContent />
          </Box>
        }
      />
    </Box>
  );
};

export default ThemeDemoPage;

const TabContent = () => {
  const currentTab = useParams<{ currentTab?: string }>().currentTab;

  switch (currentTab) {
    case 'typography':
      return <TypographyTab />;
    case 'buttons':
      return <ButtonsTab />;
    case 'tables':
      return <TablesTab />;
    case 'forms':
      return <FormsTab />;
    default:
      return <TypographyTab />;
  }
};

const TypographyTab = () => {
  return (
    <>
      <ThemeDemopageTypography
        variants={{
          h1: 'h1',
          h2: 'h2',
          h3: 'h3',
          h4: 'h4',
          h5: 'h5',
          h6: 'h6',
          subtitle1: 'subtitle1',
          subtitle2: 'subtitle2',
          body1: 'body1',
          body2: 'body2',
          button: 'button',
          caption: 'caption',
          overline: 'overline',
          headline_large: 'headline_large',
          headline_medium: 'headline_medium',
          headline_small: 'headline_small',
        }}
      />

      <Divider sx={{ marginY: 3 }} />

      <ThemeDemopageTypography
        variants={{
          h1: 'h1_condensed',
          h2: 'h2_condensed',
          h3: 'h3_condensed',
          h4: 'h4_condensed',
          h5: 'h5_condensed',
          h6: 'h6_condensed',
          subtitle1: 'subtitle1',
          subtitle2: 'subtitle2',
          body1: 'body1',
          body2: 'body2',
          button: 'button_condensed',
          caption: 'caption_condensed',
          overline: 'overline_condensed',
          headline_large: 'headline_large',
          headline_medium: 'headline_medium',
          headline_small: 'headline_small',
        }}
      />
    </>
  );
};

const ButtonsTab = () => {
  return (
    <>
      <ThemeDemopageButtons variant="contained" />

      <Divider sx={{ marginY: 3 }} />

      <ThemeDemopageButtons variant="outlined" />

      <Divider sx={{ marginY: 3 }} />

      <ThemeDemopageButtons variant="text" />
    </>
  );
};
const TablesTab = () => {
  return (
    <>
      <ThemeDemoPageTable />
    </>
  );
};

const FormsTab = () => {
  return (
    <>
      <ThemeDemoPageForms />
    </>
  );
};
