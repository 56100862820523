import { Box, BoxProps, Divider, ListItem, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DialogHeader } from '../../../theme/components/dialog-header';
import { PentairHeading } from '../../../theme/components/pentair-heading';
import { PentairBulletList } from '../../../theme/components/unordered-list';

import singleSeatValve from './assets/single-seat-valve.png';

export const AboutSingleSeatValves: React.FC<BoxProps> = (props) => {
  const { t } = useTranslation();

  return (
    <Box {...props}>
      <DialogHeader
        title={t('about', { defaultValue: 'About' })}
        subtitle={t('singleSeatValves', { defaultValue: 'Single seat valves' })}
      />

      <Divider sx={{ borderBottomWidth: 3 }} />

      <Box padding={2} display="flex" alignItems="flex-start">
        <Image src={singleSeatValve} width="22%" alt="Butterfly valve" marginRight={2} />

        <PentairBulletList>
          <ListItem>
            {t('support:aboutSingleSeatValves.descriptionBullet1', {
              defaultValue: 'Robust housing design from solid steel',
            })}
          </ListItem>
          <ListItem>
            {t('support:aboutSingleSeatValves.descriptionBullet2', {
              defaultValue: 'Complete range of variants (hygienic & aseptic) ',
            })}
          </ListItem>
          <ListItem>
            {t('support:aboutSingleSeatValves.descriptionBullet3', {
              defaultValue: 'Simple Kit-System',
            })}
          </ListItem>
          <ListItem>
            {t('support:aboutSingleSeatValves.descriptionBullet4', {
              defaultValue: 'Long-lasting sealing technology  ',
            })}
          </ListItem>
          <ListItem>
            {t('support:aboutSingleSeatValves.descriptionBullet5', {
              defaultValue: 'Ease of maintenance',
            })}
          </ListItem>
        </PentairBulletList>
      </Box>

      <PentairHeading contained spacing="dense">
        {t('workingMode', {
          defaultValue: 'Working mode',
        })}
      </PentairHeading>

      <Box padding={2} display="flex" flexWrap="wrap">
        <PentairBulletList>
          <ListItem>
            {t('support:aboutSingleSeatValves.workingMode1', {
              defaultValue: 'Hygienic shut-off function',
            })}
          </ListItem>
          <ListItem>
            {t('support:aboutSingleSeatValves.workingMode2', {
              defaultValue:
                'The gasket assembled on the valve disk allows product flow (open position) or prevents product flow (closed position)',
            })}
          </ListItem>
          <ListItem>
            {t('support:aboutSingleSeatValves.workingMode3', {
              defaultValue: 'For opening the valve, the disk needs to be turned by 90° out of the seal',
            })}
          </ListItem>
        </PentairBulletList>
      </Box>

      <PentairHeading contained spacing="dense">
        {t('typicalUseCases', { defaultValue: 'Typical use cases' })}
      </PentairHeading>

      <Box padding={2} display="flex" flexWrap="wrap">
        <PentairBulletList>
          <ListItem>
            {t('support:aboutSingleSeatValves.typicalUseCase1', {
              defaultValue: 'Simple shut-off valve',
            })}
          </ListItem>
          <ListItem>
            {t('support:aboutSingleSeatValves.typicalUseCase2', {
              defaultValue: 'Sampling from circuit loops',
            })}
          </ListItem>
          <ListItem>
            {t('support:aboutSingleSeatValves.typicalUseCase3', {
              defaultValue: 'Bottom seat valves to empty or filling the tank',
            })}
          </ListItem>
          <ListItem>
            {t('support:aboutSingleSeatValves.typicalUseCase4', {
              defaultValue: 'Routing or 2/3-way valves (Change over valves)',
            })}
          </ListItem>
        </PentairBulletList>
      </Box>
    </Box>
  );
};

const Image = styled(Box)<BoxProps<'img'>>({});
Image.defaultProps = {
  component: 'img',
};
