import { Components } from '@mui/material';

export const MuiTable: Components['MuiTable'] = {
  styleOverrides: {
    root: {
      // '& tbody': {
      //   whiteSpace: 'nowrap',
      // },
      // '& tbody tr:hover': {
      //   background: '#f2f2f2',
      // },
    },
  },
};

export const MuiTableRow: Components['MuiTableRow'] = {
  styleOverrides: {
    // root: {
    //   transition: 'background 0.3s',
    //   borderBottomColor: '#DADADA',
    // },
  },
};

export const MuiTableCell: Components['MuiTableCell'] = {
  styleOverrides: {
    root: {
      // borderBottom: 0,
      borderBottomColor: '#DADADA',
      // paddingTop: 12,
      // paddingBottom: 11,
    },

    head: {
      height: 48,
      fontWeight: 600,
      textTransform: 'uppercase',
      color: '#000',
      fontSize: '0.75rem',
    },
    paddingCheckbox: {
      padding: 0,
    },
  },
};
