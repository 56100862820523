import { InitOptions } from 'i18next';

let currentLanguage = typeof window !== 'undefined' ? window.location.pathname.split('/')[1] : 'en';

if (!['en', 'de'].includes(currentLanguage)) {
  currentLanguage = 'en';
}

export const I18Config: InitOptions = {
  debug: false,
  interpolation: { escapeValue: false }, // React already does escaping
  ns: ['glossary', 'errors', 'app', 'ecommerce', 'iam', 'support'],
  lng: currentLanguage,
  fallbackLng: 'en',
};
