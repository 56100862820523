import { LoadingContainer, LoadingIcon } from '@pentair/react-mui-5';

export const PageLoading: React.FC = () => {
  return (
    <LoadingContainer>
      <LoadingIcon />
    </LoadingContainer>
  );
};

export default PageLoading;
