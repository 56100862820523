import { Components, Theme } from '@mui/material';

export const MuiPaper: Components['MuiPaper'] = {
  defaultProps: {
    elevation: 0,
  },
};

export const MuiAppBar: Components['MuiAppBar'] = {
  defaultProps: {
    elevation: 0,
  },
};

export const MuiToolbar: Components<Theme>['MuiToolbar'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      [theme.breakpoints.up('sm')]: {
        paddingLeft: 24,
        paddingRight: 24,
      },
    }),
  },
};

export const MuiTooltip: Components<Theme>['MuiTooltip'] = {
  defaultProps: {
    arrow: true,
  },
  variants: [],
  styleOverrides: {
    tooltip: ({ theme }) => ({
      padding: 16,
      fontSize: '0.875rem',
      fontWeight: 500,
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,

      '& .MuiTooltip-arrow': {
        color: theme.palette.secondary.main,
      },
    }),
  },
};

export const MuiBadge: Components<Theme>['MuiBadge'] = {
  styleOverrides: {
    badge: ({ theme }) => ({
      backgroundColor: theme.palette.darkGold.main,
      width: 18,
      height: 18,
      marginTop: -2,
    }),
  },
};

export const MuiDivider: Components<Theme>['MuiDivider'] = {
  styleOverrides: {
    root: {
      borderColor: '#eee',
    },
  },
};

export const MuiLink: Components<Theme>['MuiLink'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      color: theme.palette.secondary.main,
      textDecoration: 'none',
      fontWeight: 600,
      '&:hover': {
        textDecoration: 'underline',
      },
    }),
  },
};
