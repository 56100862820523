import { InputProduct } from '../../../../types/operations';
import { FormData, FormOptions, useFormData } from '../../../form/use-formdata';
import { Validators, makeRecordValidator } from '../../../form/use-validator';
import { requiredString } from '../../../form/validators';

export type ProductFormRecord = Partial<InputProduct>;
export type ProductFormData = FormData<ProductFormRecord>;
type ProductValidators = Validators<ProductFormData>;

export const productValidations: ProductValidators = (record) => {
  const defaultValidations: ProductValidators = {
    class: requiredString,
    series: requiredString,
    family: requiredString,
    sizeUpper: requiredString,
    sealMaterial: requiredString,
    operationPressureLabelLaser: requiredString,
    materialInsert: requiredString,
    actuation: requiredString,
    apzLb: requiredString,
    controlUnitSeries: requiredString,
    amountOfSolenoids: requiredString,
    controlUnitConnection: requiredString,
    controlUnitCommunicationProtocol: requiredString,
  };

  // Validate sealMaterialORing if housingType is PXX7
  const isPXX7Regex = /K\d{2}7/; // KXX7 where X is a digit from 0 to 9
  const isPXX7 = isPXX7Regex.test(record?.housingType || '');
  if (isPXX7) {
    defaultValidations.sealMaterialORing = requiredString;
  }

  // Only validate custom products
  if (record.id === 'QU01') {
    return defaultValidations;
  }

  return {};
};

export const useProductValidator = makeRecordValidator<FormData<ProductFormRecord>>(productValidations);

export const useProductForm = (
  formOptions: FormOptions<ProductFormRecord> = {}
): ReturnType<typeof useFormData<ProductFormRecord>> => {
  return useFormData<ProductFormRecord>(undefined, { validations: productValidations, ...formOptions });
};
