import { get, set } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { Paths } from '../../types/objects';
import * as validators from './validations';

export type FormValueTypes = string | number | boolean | null | undefined;
export type Validator<TValue = FormValueTypes> = (api: typeof validators, value: TValue) => Array<string | boolean>;

export type Validators<TRecord extends Record<string, any> = Record<string, any>> =
  | Partial<Record<Paths<TRecord>, Validator>>
  | ((record: TRecord) => Partial<Record<Paths<TRecord>, Validator>>);

type ValidationErrors<TRecord> = Partial<Record<Paths<TRecord>, boolean | string>>;

export const makeRecordValidator =
  <TRecord extends Record<string, any> = Record<string, any>>(config?: Validators<TRecord>) =>
  () => {
    const [validatedUndefined, setValidatedUndefined] = useState(false);
    const [errors, setErrors] = useState<ValidationErrors<TRecord>>({});

    const validate = useCallback((value: FormValueTypes, validator?: Validator<any>) => {
      if (validator) {
        const validated = validator(validators, value);
        return validated.filter(Boolean)[0] || false;
      }
    }, []);

    const validateRecord = useCallback(
      (record: TRecord, validateUndefined: boolean = validatedUndefined) => {
        let _config: Partial<Record<string, Validator>>;

        if (typeof config === 'function') {
          _config = config(record);
        } else {
          _config = config || {};
        }

        const _errors: ValidationErrors<TRecord> = Object.entries(_config).reduce((result: any, [key, validator]) => {
          const isUndefined = get(record, key) === undefined || get(record, key) === null;

          if (isUndefined && validateUndefined) {
            set(record, key, '');
          }

          const err = (validateUndefined || !isUndefined) && validate(get(record, key), validator);

          if (err) {
            result[key] = err;
          }

          return result;
        }, {});

        setValidatedUndefined(validateUndefined);
        setErrors(_errors);
        return _errors;
      },
      [validate, validatedUndefined]
    );

    const propValidator: Record<Paths<TRecord>, (value: FormValueTypes) => (string | boolean)[]> = useMemo(() => {
      return Object.entries(config || {}).reduce((result: any, [key, validator]) => {
        result[key] = (value: FormValueTypes) => validate(value, validator);
        return result;
      }, {});
    }, [validate]);

    return { validate: propValidator, errors, validateRecord, setErrors };
  };
