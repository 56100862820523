import { Box, BoxProps, Divider, ListItem, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DialogHeader } from '../../../theme/components/dialog-header';
import { PentairHeading } from '../../../theme/components/pentair-heading';
import { PentairBulletList } from '../../../theme/components/unordered-list';

import externalFlushing from './assets/external-flushing-connection.png';

export const AboutExternalFlushingConnection: React.FC<BoxProps> = (props) => {
  const { t } = useTranslation();

  return (
    <Box {...props}>
      <DialogHeader
        title={t('about', { defaultValue: 'About' })}
        subtitle={t('externalFlushingConnection', { defaultValue: 'External flushing connection' })}
      />
      <Divider sx={{ borderBottomWidth: 3 }} />
      <Box padding={2} display="flex" alignItems="flex-start">
        <Image src={externalFlushing} width="22%" alt="Butterfly valve" marginRight={2} />
        <Box flex={1} marginBottom={1}>
          <PentairHeading variant="body2" gutterBottom>
            {t('support:aboutExternalFlusingConnection.about', {
              defaultValue: 'Cleaning of leakage chamber via external flushing connection',
            })}
          </PentairHeading>
          <PentairBulletList>
            <ListItem>
              {t('support:aboutExternalFlusingConnection.descriptionBullet1', {
                defaultValue: 'Leakage chamber and leakage drain are​ cleaned via an external connection',
              })}
            </ListItem>
          </PentairBulletList>
        </Box>
      </Box>
    </Box>
  );
};

const Image = styled(Box)<BoxProps<'img'>>({});
Image.defaultProps = {
  component: 'img',
};
