import { Components, Theme } from '@mui/material';

export const MuiCard: Components<Theme>['MuiCard'] = {};

export const MuiCardContent: Components<Theme>['MuiCardContent'] = {
  styleOverrides: {
    root: {
      padding: 24,
    },
  },
};

export const MuiCardActions: Components<Theme>['MuiCardActions'] = {
  styleOverrides: {
    root: {
      padding: '0 24px 24px 24px',
    },
  },
};
