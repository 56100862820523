import { makeApiHook } from '@pentair/react-mui-5';
import gql from 'graphql-tag';
import { UpdateUserMutation, UpdateUserMutationVariables } from '../../../types/operations';
import { ecommerceApi } from '../../api/api';
import { fetchProduct } from '../../ecommerce/products/api/fetch';

const query = gql`
  mutation updateUser($input: InputUser!) {
    updateUser(input: $input) {
      id
      firstName
      middleName
      lastName
      locale
      country
      phone
      organisationName
      subscribedToNewsAndUpdates
    }
  }
`;

export const updateUser = async (variables: UpdateUserMutationVariables) => {
  try {
    return await ecommerceApi.query<UpdateUserMutation>(query, variables);
  } catch (err: any) {
    throw err;
  }
};

export const useFetchProduct = makeApiHook(fetchProduct, {});
