import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { insertScript, removeScript } from '../html/script-tag';

export enum AnalyticsEvents {
  // Auth
  AUTH_SIGN_IN = 'auth.sign-in',
  AUTH_SIGN_UP = 'auth.sign-up',
  AUTH_SIGN_OUT = 'auth.sign-out',
  AUTH_RESET_PASSWORD = 'auth.reset-password',
  AUTH_DELETE_ACCOUNT = 'auth.delete-account',
  // Profile
  PROFILE_UPDATE = 'profile.update',
  PROFILE_DELETE = 'profile.delete',
  // App
  PAGE_VIEW = 'app.page-view',
  DIALOG_VIEW = 'app.modal-view',
  // Ecommerce
  VIEW_CART = 'cart.view',
  CART_ADD = 'cart.add',
  CART_REMOVE = 'cart.remove',
  CART_UPDATE = 'cart.update',
  CART_CLEAR = 'cart.clear',
  QUOTATION_SUBMIT = 'quotation.submit',
  // Valve Configurator
  VALVE_ASSISTANT_VIEW_QUESTION = 'valve-assistant.view-question',
  VALVE_ASSISTANT_ANSWER_QUESTION = 'valve-assistant.view-question',
  VALVE_ASSISTANT_VIEW_RESULTS = 'valve-assistant.view-results',
}

declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

// type gives error in pull-request build
// const gtag: Gtag.Gtag = function () {
const gtag: any = function () {
  if (typeof window === 'undefined') return;
  window.dataLayer.push(arguments);
};

interface AnalyticsProviderState {
  disabled: boolean;
}

interface AnalyticsProviderAPI extends AnalyticsProviderState {
  track: (event: AnalyticsEvents, properties?: any) => void;
  handleTrackUser: (value: boolean) => void;
}

/**
 * The analytics context.
 * @see https://reactjs.org/docs/context.html
 */
const AnalyticsContext = createContext<AnalyticsProviderAPI | null>(null);
if (typeof window !== 'undefined') {
  window.dataLayer = window.dataLayer || [];
}
/**
 * Provides the analytics context to the application.
 * @param children The children of the provider.
 * @returns The analytics provider.
 * @example
 * ```tsx
 * <AnalyticsProvider>
 *  <App />
 * </AnalyticsProvider>
 * ```
 * @see https://reactjs.org/docs/context.html
 */
export const AnalyticsProvider: React.FC<
  React.PropsWithChildren<{ initialState: Partial<AnalyticsProviderState> }>
> = ({ children, initialState }) => {
  const [contextState, setContextState] = useState<AnalyticsProviderState>({ disabled: false, ...initialState });

  const handleTrackUser = (value: boolean) => {
    setContextState((prev) => ({ ...prev, disabled: value }));
  };

  // Insert the Google Tag Manager script into the head of the document.
  // This is done here because the script is not needed until the user has accepted the cookie policy.
  useEffect(() => {
    if (!contextState?.disabled) {
      insertScript('https://www.googletagmanager.com/gtag/js?id=G-HXRFCWNCCP', 'google-tag-manager', document.head);
    }

    gtag('js', new Date());
    gtag('config', 'G-HXRFCWNCCP');

    return () => {
      // The script is removed when the component is unmounted or the disabled state changes.
      removeScript('google-tag-manager', document.head);
    };
  }, [contextState]);

  /**
   * Tracks an event. This is used to track events in the application.
   * @param event The event to track.
   * @param properties The properties of the event.
   * @example
   * ```tsx
   * const analytics = useAnalytics();
   * analytics.track(AnalyticsEvents.AUTH_SIGN_IN, { email: 'some-email' });
   * ```
   *
   */
  const track = useCallback(
    (event: AnalyticsEvents, properties?: any) => {
      if (contextState?.disabled || !gtag) {
        return;
      }

      switch (event) {
        case AnalyticsEvents.AUTH_SIGN_IN:
          gtag('event', 'login', properties);
          break;
        case AnalyticsEvents.PAGE_VIEW:
        case AnalyticsEvents.VALVE_ASSISTANT_VIEW_QUESTION:
          gtag('event', 'page_view', properties);

          break;
        case AnalyticsEvents.VIEW_CART:
          gtag('event', 'view_cart', properties);
          break;
        case AnalyticsEvents.CART_ADD:
          gtag('event', 'add_to_cart', properties);
          break;
        case AnalyticsEvents.CART_REMOVE:
          gtag('event', 'remove_from_cart', properties);
          break;

        case AnalyticsEvents.CART_UPDATE:
          gtag('event', 'updated_cart_item', properties);
          break;
        case AnalyticsEvents.CART_CLEAR:
          gtag('event', 'clear_cart', properties);
          break;

        case AnalyticsEvents.VALVE_ASSISTANT_VIEW_RESULTS:
          gtag('event', 'page_view', properties);
          gtag('event', 'valve-assistant-result', properties);
          break;
      }
    },
    [contextState]
  );

  const state = useMemo(() => ({ ...contextState, track, handleTrackUser }), [contextState, track]);

  return <AnalyticsContext.Provider value={state}>{children}</AnalyticsContext.Provider>;
};

/**
 * Gets the analytics context. This is used to access the analytics context from a component.
 * @returns The analytics context.
 * @example
 * ```tsx
 * const analytics = useAnalytics();
 * ```
 */
export const useAnalytics = () => {
  const context = useContext(AnalyticsContext);

  if (!context) {
    throw new Error('useAnalytics must be used within a AnalyticsProvider');
  }

  return context;
};
