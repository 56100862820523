import gql from 'graphql-tag';
import { DeleteCartMutation, DeleteCartMutationVariables } from '../../../../types/operations';
import { ecommerceApi } from '../../../api/api';
import { makeApiHook } from '../../../api/helpers/make-api-hook';

const query = gql`
  mutation deleteCart($id: ID) {
    deleteCart(id: $id)
  }
`;

export const deleteCart = async (variables: DeleteCartMutationVariables) => {
  try {
    return await ecommerceApi.query<DeleteCartMutation>(query, variables);
  } catch (err: any) {
    if (err instanceof Error) throw err;
    else throw new Error(err.toString());
  }
};

export const useDeleteCart = makeApiHook(deleteCart, {});
